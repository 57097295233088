import React from 'react';
import { Button, Row, Col, Image, Checkbox,Typography } from 'antd';
import useScreenSize from '../../utils/useScreenSize';
import { UploadOutlined} from '@ant-design/icons';
import FileIcon from '../../images/file-icon.svg';

const { Title } = Typography;
const Phototab=(props)=>{
    const {showUploadModal,handleCheckboxChange,uploadedFiles,selectedFileIndices}=props;
    const { isMobile, isTablet } = useScreenSize();

    return (
        <div>
        {uploadedFiles.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Image
              width={104}
              src={FileIcon}
              preview={false}
            />
            <Title level={4} style={{ marginBottom: '30px' }}>No images found</Title>
            <Button type="primary" size="large" icon={<UploadOutlined />} onClick={showUploadModal} style={{ fontWeight: 600 }}>Add</Button>
          </div>
        ) : (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Row gutter={ isMobile ? [0] : isTablet ? [20, 20] : [30, 30]}>
              {uploadedFiles.map((file, index) => (
                <Col key={index} span={8}>
                  <div
                    onClick={() => handleCheckboxChange(file?.id,file?.Image?.filepath)}
                    style={{
                      padding: 0,
                      border: selectedFileIndices.includes(file?.id) ? '3px solid #7F56D9' : '3px solid transparent',
                      textAlign: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    {selectedFileIndices.includes(file?.id) && 
                      <Checkbox
                        style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                        checked={selectedFileIndices.includes(file?.id)}
                      />
                    }
                    {file?.Image?.filepath &&  <img className="uploaded-img" src={`${process.env.REACT_APP_API_BASE_URL}/${file?.Image?.filepath}`} alt={file?.Image?.filepath}  style={{ width: '100%', display: 'block' }} />}
                   
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
}

export default Phototab;