import React, { useState,useEffect } from 'react';
import { Layout, Typography, Flex, Tabs,Spin } from 'antd';
import { get } from '../../../utils/api';
import CollectionView from '../../../components/Audience/MyCollection/CollectionView';
const { Title } = Typography;

const MyCollection = () => {
  const [allItems, setAllItems] = useState([]);
  const [paidAllItems, setpaidAllItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  

  useEffect(()=>{
    const init=async()=>{
      setIsLoading(true);
      try{
        const response=await get('user/myCollection');
        const response2=await get('user/paidMyCollection');
        if(response.status){
          setAllItems(response?.data);
        }
        if(response2.status){
          setpaidAllItems(response2?.LogsMedia);
        }
      }catch(error){
        console.log("error",error);
      }finally{
        setIsLoading(false);
      }
    }
    init();
  },[]);

  const items = [
    {
      key: 'free_content',
      label: 'Free content',
      children:<CollectionView allItems={allItems}/>,
    },
    {
      key: 'premium_content',
      label: 'Premium content',
      children: <CollectionView allItems={paidAllItems}/>,
    }
  ];

  return (
    <Layout style={{ padding: '20px 0px' }}>
    <Spin spinning={isLoading}>
      <Flex align="center" justify="space-between" style={{ padding: '0 20px' }}>
        <Title level={3} style={{ margin: 0 }}>My Collections</Title>
        {/* <Button type="text" size="large" style={{ fontWeight: 600, fontSize: '14px', color: '#6941C6', padding: 0, background: 'transparent' }}>Edit</Button> */}
      </Flex>
        <Tabs defaultActiveKey="free_content" items={items}  />
      </Spin>
    </Layout>
  );
};

export default MyCollection;
