import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Card, Avatar, Typography, Button, Flex, Switch, Spin, message, notification,Popconfirm} from 'antd';
import { CloseOutlined, StarOutlined, StopOutlined, DeleteOutlined ,StarFilled,StopFilled,CloseCircleFilled} from '@ant-design/icons';
import { get, post } from '../../utils/api';
import AvatarImage from '../../components/AvatarImage';
import { calculateAge } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';

const { Sider } = Layout;
const { Title, Text, Paragraph } = Typography;

const SidebarFan = ({ selectedFan, setSelectedFan, selectedUser, setIsLoading, setIsReload, selectedCommunity }) => {
  
  const [userInfo,setUserInfo]=useState(null);
  const [isFavorite,setIsFavorite]=useState(false);
  const [isBanUser,setIsBanUser]=useState(false);
  const [ishideNotifiction,setIsHideNotifiction]=useState(false);
  const [isLoader,setIsLoader]=useState(false);
  const { isMobile, isTableToDesktop } = useScreenSize();

  useEffect(()=>{
      const loadUserInfo=async()=>{
        if(!userInfo){
          return false;
        }
        const { id } = userInfo;
        setIsLoader(true);
        setIsFavorite(false);
        try {
          const response = await get(`creator/favoriteAudience/${id}`);
    
          if (response && response.status) {
            const {favoriteUser,banUser,hideNotifiction}=response.data;
            setIsFavorite(favoriteUser);
            setIsBanUser(banUser);
            setIsHideNotifiction(hideNotifiction);
          } 
        } catch (error) {
          console.error('Error fetching user info:', error);
        } finally {
          setIsLoader(false);
        }
      }
      loadUserInfo();
  },[userInfo]);
  
  const handleClose = () => {
    setSelectedFan(false);
  };
  useEffect(() => {
    const init = async () => {
      const { user_phone } = selectedUser;
      setIsLoading(true);
  
      try {
        const response = await post(`creator/getUserInfo`, {
          phone: user_phone
        });
  
        if (response && response.status) {
          setUserInfo(response.data);
        } else {
          console.error('Information not available!');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (selectedUser) {
      init();
    }
  }, [selectedUser]);

  const addRemovefavorite=async()=>{
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
    const { id } = selectedUser;
    setIsLoader(true);

    try {
    const response=await post(`creator/addRemovefavorite`, {
        favrateId: id
      });
      if(response.status){
        message.success(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const banUser=async()=>{
    setIsBanUser((prevIsFavorite) => !prevIsFavorite);
    const { id } = selectedUser;
    setIsLoader(true);

    try {
    const response=await post(`creator/banUser`, {
        favrateId: id
      });
      if(response.status){
        message.success(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const notificationHandler=async()=>{
    setIsHideNotifiction((prevIsFavorite) => !prevIsFavorite);
    const { id } = selectedUser;
    setIsLoader(true);

    try {
    const response=await post(`creator/hideAlerts`, {
        favrateId: id
      });
      if(response.status){
        message.success(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const removeUserFromCommunity=async(userId,communityId)=>{
   
      setIsLoader(true);
      try {
      const response=await post(`creator/rmUserCommunity`, {
        id: communityId,
        uId:userId
        });
        if(response.status){
         
          setSelectedFan(false);
          setIsReload(true);
          message.success(response.message);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoader(false);
      }
  }

  const handleDeleteUser = () => {
      if (selectedCommunity) {
          removeUserFromCommunity(selectedUser.id, selectedCommunity.id);
      } else {
          message.error('No community selected.');
      }
  };

  const cancel=()=>{
    console.log("cancel");
   }

   const selectedFanStyles = useMemo(() => 
    isMobile ? { 
      backgroundColor: '#F9FAFB', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '16px', 
      zIndex: 99,
    } : isTableToDesktop ? { 
      backgroundColor: '#F9FAFB', 
      position: 'absolute', 
      right: 0,
      height: '100vh',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '16px', 
      borderLeft: '1px solid #e3e3e3',
    } : { 
      height: '100vh', 
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      background: '#F9FAFB', 
      padding: '16px', 
      borderLeft: '1px solid #e3e3e3'
    }
  , [isMobile, isTableToDesktop]);

  return (
    selectedFan && userInfo && (
      <Sider width={ isMobile ? '100%' : 300} style={selectedFanStyles} collapsed={!selectedFan}>
        <Spin spinning={isLoader}>
        <Flex vertical gap="middle">
          <Button
            type="link"
            icon={<CloseOutlined style={{ color: '#101828' }}/>}
            onClick={handleClose}
            style={{ marginBottom: '20px', alignSelf: 'flex-end' }}
          >
          </Button>
          <Card className="fan-head" style={{ textAlign: 'center' }}>
            <Flex justify="center" style={{ marginTop: '-65px' }}>
              <AvatarImage size={96} title={`${userInfo?.first_name} ${userInfo?.last_name}`}/>
            </Flex>
            <Title level={4}>{userInfo?.first_name} {userInfo?.last_name}</Title>
            <Text className="phone">{selectedUser?.user_phone}</Text>
          </Card>

          <Card className="fan-details">
            <Flex horizontal justify="space-around">
              
              <Button
                type="link"
                icon={isFavorite?<StarFilled style={{ fontSize: 20,color: '#dc8b2a' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                style={{ color: '#475467' }}
                onClick={addRemovefavorite}
              >
              </Button>
              <Popconfirm
                placement="topLeft"
                  title={isBanUser?`UnBlock User`:`Block User`}
                  description={isBanUser?`Are you sure? you want to unblock this user`:`Are you sure? This user will not be able to message you again.`}
                  onConfirm={banUser}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                <Button
                  type="link"
                  icon={isBanUser?<StopFilled style={{ fontSize: 20,color: '#ec0000' }} />:<StopOutlined style={{ fontSize: 20 }} />}
                  style={{ color: '#475467' }}
                />
              </Popconfirm>
          
              <Popconfirm
                placement="topLeft"
                  title={`Remove from community`}
                  description={`Are you sure you want to remove this user from the community?`}
                  onConfirm={handleDeleteUser}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                <Button
                  type="link"
                  icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                  style={{ color: '#D92D20' }}
                >
                </Button>
              </Popconfirm>

            </Flex>
          </Card>

          <Card className="fan-details">
            <Flex horizontal align="center" gap={10}>
            <Popconfirm
                placement="topLeft"
                  title={isBanUser?`Show notifications`:`Hide notifications`}
                  description={isBanUser?`Are you sure? you want to show all notifications`:`Are you sure? you want to hide all notifications`}
                  onConfirm={notificationHandler}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                <Switch checked={ishideNotifiction} />
              </Popconfirm>
                <Paragraph style={{ marginBottom: 0 }}>Hide Alerts</Paragraph>
            </Flex>
          </Card>

          <Card className="fan-details">
            <Title level={4}>Details</Title>
            <Paragraph><strong>Age:</strong> {calculateAge(userInfo.dob)}</Paragraph>
            <Paragraph><strong>Gender:</strong> {userInfo.gender}</Paragraph>
            <Paragraph><strong>Location:</strong> {userInfo.city}</Paragraph>
          </Card>

          {selectedFan.spent || selectedFan.interactions &&
            <Card className="fan-engagement">
              <Title level={4}>Engagement</Title>
              <Flex horizontal="true" justify="space-between" style={{ marginBottom: '20px' }}><strong>Spent:</strong> ${selectedFan.spent}</Flex>
              <Flex horizontal="true" justify="space-between"><strong>Interactions:</strong> {selectedFan.interactions}</Flex>
            </Card>
          }
          {userInfo.Communities.length>0 &&
            <Card className="fan-communities">
              <Title level={4}>Communities</Title>
              <Flex gap={4}>
              {userInfo.Communities.map((community) => (
                  <Paragraph key={community.id} className="communities-name">{community.name}
                  <Popconfirm
                    placement="topLeft"
                      title={`Remove from community`}
                      description={`Are you sure you want to remove this user from the community?`}
                      onConfirm={()=>{
                        removeUserFromCommunity(userInfo?.id,community.id)
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                    <Button
                      type="link"
                      icon={<CloseCircleFilled style={{ fontSize: 14, color: '#ec0000' }}/>}
                      style={{ color: '#475467', padding: 0, height: 'auto', width: 'auto', marginLeft: 4, verticalAlign: 'middle' }}
                    />
                  </Popconfirm>
                </Paragraph> 
              ))}
              </Flex>
            </Card>
          }
        </Flex>
        </Spin>
      </Sider>
    )
  );
};

export default SidebarFan;
