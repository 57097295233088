import React, { useState ,useEffect} from 'react';
import { Card, Button, Flex, Typography } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { ArrowUpOutlined, RightOutlined } from '@ant-design/icons';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text } = Typography;

// const data = [
//   { name: 'Female: <span>50.0%</span>', value: 50 },
//   { name: 'Male: <span>30.0%</span>', value: 30 },
//   { name: 'Non-binary: <span>10.0%</span>', value: 10 },
//   { name: 'Prefer not to answer: <span>10.0%</span>', value: 10 },
// ];

const COLORS = ['#7F56D9', '#9E77ED', '#B692F6', '#EAECF0'];


const renderCustomLegend = (props) => {
  const { payload } = props;

  return (
    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ marginBottom: 10 }}>
          <span
            style={{
              display: 'inline-block',
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              marginRight: 5,
            }}
          ></span>
          <span className="value-text" dangerouslySetInnerHTML={{ __html: entry.payload.name }} />
        </li>
      ))}
    </ul>
  );
};


const GenderIdentity = ({data}) => {
    const [position, setPosition] = useState('end');
    const [chartData,setChartData]=useState([]);
    const { isMobile } = useScreenSize();
  
    useEffect(()=>{
      if(data && data?.genderDistribution){
        const ctrdata= data?.genderDistribution.map(item => {
          //const Name=(item.gender=="Other")?`Prefer not to answer : <span>${item.count}%`:`${item.gender}: <span>${item.count}%</span>`
          return {
            name: `${item?.gender}: <span>${item?.percentage}%</span>`,
            value: parseFloat(item.count)
          };
        });
        setChartData(ctrdata);
      }
      
    },[data]);

   return (
    <Card className="insight-card">
      <Flex vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
        <Title level={4} style={{ marginTop: 0, marginBottom: '30px', fontSize: '18px' }}>Gender Identity</Title>
        <PieChart className="gender-pie" width={300} height={300}>
          <Pie
            data={chartData}
            cx={140}
            cy={140}
            innerRadius={60}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={0}
            stroke={0}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend content={renderCustomLegend}  />
        </PieChart>
      </Flex>
    </Card>
  );
}

export default GenderIdentity;
