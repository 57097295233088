import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Modal, Button, Flex, Avatar, Typography, Image, Drawer, Dropdown, Space, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import Logo from "../../images/logo.svg";
import LogoIcon from "../../images/Logoicon.svg";
import HamburgerIcon from "../../images/menu-icon.svg";
import CopyIcon from "../../images/copy-icon.svg";
import ArrowDown from "../../images/chevron-down.svg";
import './sidebar.css';
import { getImageUrl, getInitials } from '../../utils/utils';
import { useMediaQuery } from 'react-responsive';
import { get } from '../../utils/api';
import { formatNumber } from '../../utils/utils';

import {
  LogoutOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { Title, Text, Paragraph } = Typography;


// const items = [
//   {
//     label: 'Developer Soto',
//     user: '@pevosimufy',
//     key: '0',
//   },
//   {
//     label: 'Test user',
//     user: '@testuser234',
//     key: '1',
//   },
//   {
//     label: 'User Test',
//     user: '@user1234',
//     key: '2',
//   },
//   {
//     label: 'John Test',
//     user: '@johntest134',
//     key: '3',
//   },
// ];


const Sidebar = ({ menuItems = [], menuItemsBottom = [], currentPath='/admin/dashboard', collapsed, setCollapsed, toggleCollapsed ,teamList=[],setSelectedCreator}) => {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const {user}  = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [totalUsers, setTotalUsers] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [selectedUser, setSelectedUser] = useState(null); // Set default to the first user


  // const [collapsed, setCollapsed] = useState(false);

  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  const loadfavoriteAudiencefanCounts=async()=>{
    try {
      const response = await get('creator/fanCounts/');
      if(response && response.status){
        const { allUsers } = response.data;
        setTotalUsers(allUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (isTabletOrMobile && !collapsed) {
      setCollapsed(true);
    }
    const init=async()=>{
      await loadfavoriteAudiencefanCounts();
    }
    init();
    // eslint-disable-next-line
  }, [isTabletOrMobile]);


  const handleLogout = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logoutHandel = () => {
    localStorage.removeItem('token');
    message.success('Logged out successfully!');
    setAuth(false);
    navigate('/');
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  
  const handleLinkClick = () => {
    onClose();
  };
  const handleCopy = () => {
    const inputValue = document.querySelector('.input-number').textContent;
    console.log(inputValue, 'inputValue');
    navigator.clipboard.writeText(inputValue).then(() => {
      message.success('Copied to clipboard!');
    }).catch(err => {
      message.error('Failed to copy!');
    });
  };

  const handleMenuClick = (e) => {
   
    const selectedItem = teamList.find(item => item.id == e.key);
    setSelectedUser(selectedItem); // Update selected user data
    setSelectedCreator(selectedItem);
    navigate('/team/dashboard');
  };

  useEffect(()=>{
    if(selectedUser==null && teamList && teamList?.length>0){
      setSelectedUser(teamList[0]);
      setSelectedCreator(teamList[0]);
    }
  },[teamList,setSelectedUser]);

  const dropdownmenu = (
    <Menu onClick={handleMenuClick}>
      {teamList.map(item => (
        <Menu.Item key={item?.id}>
          {item?.teamList?.first_name}  {item?.teamList?.last_name}
        </Menu.Item>
      ))}
    </Menu>
  );


  return (<>
    {isDesktop &&
      <Sider collapsible collapsed={collapsed} trigger={null} width={312} style={{ backgroundColor: '#ffffff', borderRight: '1px solid #EAECF0', position: 'fixed', left: 0, height: '100vh' }}>
        <Button type="link" onClick={toggleCollapsed} style={{ margin: '16px 29px 10px 29px', padding: 0 }}>
          <Image width={20} src={HamburgerIcon} preview={false} />
        </Button>
      
        {user && user.role.includes('Team') && teamList && teamList?.length ? <Flex vertical style={collapsed ? { padding: '10px' } : { padding: '10px 25px' }}>
          <Dropdown overlay={dropdownmenu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Flex horizontal="true" align="center" gap={15} style={collapsed ? { padding: '0px', justifyContent: 'center' } : { padding: '0px' }}>
                {user?.profileUrl ?
                  <Avatar size={collapsed ? 40 : 45} src={getImageUrl(user?.profileUrl)} />          
                  :
                  <Avatar size={collapsed ? 40 : 45} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
                }
                {collapsed ?
                  ''
                : 
                  <Flex vertical>
                    <Text style={{ fontWeight: 600 }}> {selectedUser?.teamList?.first_name}  {selectedUser?.teamList?.last_name}</Text>
                    <Text>{selectedUser?.teamList?.user_name}</Text>
                  </Flex>
                }
                <div style={{ marginLeft: 'auto' }}>
                  <Image 
                    width={20} 
                    src={ArrowDown} 
                    preview={false}
                  />
                </div>
              </Flex>
            </a>
          </Dropdown>
        </Flex>:  <Flex horizontal="true" align="center" gap={15} style={collapsed ? { padding: '10px', justifyContent: 'center' } : { padding: '10px 25px' }}>
        {user?.profileUrl ?
          <Avatar size={collapsed ? 40 : 45} src={getImageUrl(user?.profileUrl)} />          
          :
          <Avatar size={collapsed ? 40 : 45} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
        }
        {collapsed ?
            ''
          : 
            <Flex vertical>
              <Text style={{ fontWeight: 600 }}>{user?.first_name} {user?.last_name}</Text>
              <Text>@{user?.user_name}</Text>
            </Flex>
        }
        </Flex>}
      

       

        {collapsed ? 
          '' 
        : <>
            {user.assign_number && (
              <Flex style={{ padding: '10px 30px 0px 30px', marginBottom: 0 }}>
                <Flex className="copy-paste-sidebar-input">
                  <Text className="input-number" style={{ fontSize: 16 }}>{user.assign_number}</Text>
                  <Button type="text" onClick={handleCopy}>
                    <Image 
                      width={20} 
                      src={CopyIcon} 
                      preview={false}
                    />
                    <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Copy</span>
                  </Button>
                </Flex>
              </Flex>)}
          </>
        }
        
        {!collapsed && user && user.role.includes('Creator') &&  <Paragraph style={{ padding: '10px 30px 0px 30px', fontSize: 16 }}>{totalUsers && <strong>Audience: <span className="audience-counter">{formatNumber(totalUsers)}</span></strong> } Fans</Paragraph>}
        
        <Menu className="sidebar-menu main-menu" theme="light" selectedKeys={[`${currentPath}`]}  mode="inline">
          {menuItems.map((item) => (
            <Menu.Item key={item.path} icon={item.icon}  attr={currentPath} tr={item.path} >
              <Link to={item.path}>
                <Text className={!collapsed ? '' : 'collapsed-text'}>{item.label}</Text>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
        <Menu className="sidebar-menu bottom-menu" theme="light" mode="inline" selectedKeys={[`${currentPath}`]} >
          {menuItemsBottom.map((item) => (
            <Menu.Item key={item.path} icon={item.icon}>
              <Link to={item.path}>
                <Text className={!collapsed ? '' : 'collapsed-text'}>{item.label}</Text>
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item key="11" icon={<LogoutOutlined />} onClick={handleLogout}>
            <Text className={!collapsed ? '' : 'collapsed-text'}>Logout</Text>
          </Menu.Item>
        </Menu>

        <div className="logo" style={collapsed ? { padding: '0px 0px 10px 0px', textAlign: 'center' } : { padding: '0px 30px 10px 30px' }}>
          <Image
            width={collapsed ? 30 : 100}
            src={collapsed ? LogoIcon : Logo}
            preview={false}
          />
        </div>
      </Sider>
    }

    {isTabletOrMobile && <>
      <Flex className="head-mobile" align="center" justify="space-between" style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 15, paddingRight: 15 }}>
        <Flex>
          <div className="logo">
            <Image
              width={100}
              src={Logo}
              preview={false}
            />
          </div>
        </Flex>
        <Flex horizontal="true">
          <Button type="link" onClick={showDrawer} style={{ margin: '13px 0px 13px', padding: 0 }}>
            <Image width={20} src={HamburgerIcon} preview={false} />
          </Button>
        </Flex>
      </Flex>

      <Drawer
        placement="left"
        width={310}
        onClose={onClose}
        open={open}
        className="mobile-drawer-sidebar"
      >
        <Flex horizontal="true" align="center" gap={15}>
          <Avatar size={collapsed ? 40 : 45} src={getImageUrl(user?.profileUrl)} />
          <Flex vertical>
            <Text style={{ fontWeight: 600 }}>{user?.first_name} {user?.last_name}</Text>
            <Text>@{user?.user_name}</Text>
          </Flex>
        </Flex>

        <Paragraph style={{ padding: '0px', fontSize: 16, marginBottom: 0, marginTop: 20 }}><strong>Audience: { totalUsers && <span className="audience-counter">{formatNumber(totalUsers)}</span>}</strong> Fans</Paragraph>

        <Menu className="sidebar-menu main-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon} tr={item.path}>
              <Link to={item.path} onClick={handleLinkClick}>
                <Text>{item.label}</Text>
              </Link>
            </Menu.Item>
          ))}
        </Menu>

        <Menu className="sidebar-menu bottom-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
          {menuItemsBottom.map((item) => (
            <Menu.Item key={item.key} icon={item.icon} className={currentPath === item.path ? 'ant-menu-item-selected' : ''}>
              <Link to={item.path} onClick={handleLinkClick}>
                <Text>{item.label}</Text>
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item key="11" icon={<LogoutOutlined />} onClick={handleLogout}>
            <Text>Logout</Text>
          </Menu.Item>
        </Menu>

      </Drawer>
    </>}

    <Modal keyboard={false} maskClosable={false} className="logout-confirm" open={isModalVisible} onOk={logoutHandel} onCancel={handleCancel} width={480} footer={[
        <Flex gap={10} justify="center" style={{ marginTop: 30, marginBottom: 20 }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={logoutHandel} 
          style={{ fontWeight: 600 }}
          >Yes, log me out</Button>
        </Flex>
      ]}>
        <Title level={4} style={{ textAlign: 'center' }}>Are you sure that you want to logout?</Title>
      </Modal>

  </>);
};

export default Sidebar;
