import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Input, Form, Typography, Flex, Dropdown, Divider, Radio, Image, Slider, Checkbox, DatePicker, Calendar, TimePicker, Select, Menu, Spin,message as antdMessage,Avatar } from 'antd';
import {  EditOutlined, CloseCircleOutlined, PlusOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { post,get } from '../../utils/api';
import AddressAutocomplete from '../AddressAutoComplete';
import ChatInput from './ChatInput';
import { UserContext } from '../../contexts/UserContext';
import { getImageUrl,getInitials } from '../../utils/utils';
import dayjs from 'dayjs';
import momenttmz from 'moment-timezone';
import useScreenSize from '../../utils/useScreenSize';

import CalendarPlusIcon from '../../images/calendar-plus-icon.svg';
import CurrencyDollarIcon from '../../images/currency-dollar-icon.svg';

const { Title, Text } = Typography;
const { Option } = Select;

const NewMessage = ({ visible, handleCancel,setSelectedPrice }) => {
  const [form] = Form.useForm();
  const {user}  = useContext(UserContext);
  const [fillterform] = Form.useForm();
  const [message, setMessage] = useState('');
  const [selectedGender, setSelectedGender] = useState('all');
  const [selectAgeRange, setSelectAgeRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('Untitled');
  const { isMobile } = useScreenSize();

  const [price, setPrice] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [filtersData, setFiltersData] = useState([]);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [priceInput, setPriceInput] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [ageRange, setAgeRange] = useState([16, 25]); 
  const [selectedCommunities, setSelectedCommunities] = useState([]); 
  const [communitiesList, setCommunitiesList] = useState([]);
  const [scheduleMessage, setScheduleMessage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedAmPm, setSelectedAmPm] = useState('AM');
 
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles');
  const [schedule, setSchedule] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const checkAll = communitiesList.length === selectedCommunities.length;
  const [appliedFilters, setAppliedFilters] = useState({
    communities: [],
    gender: null,
    selectAgeRange: [],
    location: '',
  });
  
  const timezones = momenttmz.tz.names();

  const handleInputChange = (value) => {
    setMessage(value);
  };

  const loadCommunities = async (current, pageSize) => {
    setCommunitiesList([]);
    setIsLoading(true);
    try {
      const response = await get('creator/communities/');
      if(response){
        const formattedData = response.map(item => ({
          value: item.id,
          label: item.name
      }));
      formattedData.unshift({value: 'Favorites',label:'Favorites'});
       setCommunitiesList(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadCommunities();
    };
  
    init();
  }, []);

  useEffect(() => {
    if(message){
      form.validateFields(['messagebox']);
    }
  }, [message, form]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleEditTitle = () => {
    setIsEditingTitle(!isEditingTitle);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
  };

  const handleAgeChange = (value) => {
    setAgeRange(value);
  };

  const handleFilterVisibleChange = (flag) => {
    setIsFilterVisible(flag);
  };

  const handlePriceModalCancel = () => {
    setIsPriceModalVisible(false);
  };
  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };
  const handleApplyPrice = () => {
    const priceValue = parseFloat(priceInput);
    if (!isNaN(priceValue) && priceValue >= 0.99) {
      setPrice(priceValue);
      setIsPriceModalVisible(false);
    } else {
      alert("Price must be at least $0.99");
    }
  };

  const handleClearPrice = () => {
    setPrice(null);
  };

  const handleClearScheduleDate = () => {
    setScheduleMessage(null);
  };

  const showModal = () => {
    setIsScheduleModalVisible(true);
  };

  const handleOk = () => {
    if (selectedDate && selectedTime) {
      const formattedDate = selectedDate.format('MM/DD/YYYY');
      const formattedTime = selectedTime.format('h:mm') + ` ${selectedAmPm}`;
      const formattedSchedule = `Schedule for ${formattedDate} at ${formattedTime} - ${selectedTimezone}`;
      setScheduleMessage(formattedSchedule);
      setIsScheduleModalVisible(false);
    }else{
      antdMessage.error('Please choose a time to send the SMS.');
    }
  };

  const applyFillters = async (values)=>{
    setIsLoading(true);
    form.setFieldsValue({
      to: '0 Fans'
    });
    setTitle('Untitled');
    try{
      const collectSelected = checkAll ? { ...selectedCommunities, all: "all" } : selectedCommunities;
      const stringValues = [];
      const numericValues = [];

      Object.values(collectSelected).forEach(value => {
        if (typeof value === 'string') {
          stringValues.push(value);
        } else if (typeof value === 'number') {
          numericValues.push(value);
        }
      });
      const finaldata={...values,stringValues,numericValues,ageRange};
      setFiltersData(finaldata);
      const response = await post('creator/users-filters/',finaldata);
      if(response.status && response.data>0){
        form.setFieldsValue({
          to: `${response.data} Fans`
        });
        setTitle(`${response.data} Fans`);
        setIsFilterVisible(false);
      }else{
        antdMessage.error('0 records match the selected criteria. Please modify your search and try again.');
      }
    }catch(error){
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const sendMessageHandler=async (values)=>{
    setIsMessageSending(true);
    try{
      let schedule={};
      if(selectedDate && selectedTime){
        const formattedDate = selectedDate.format('MM/DD/YYYY');
        const formattedTime = selectedTime.format('h:mm');
  
         schedule={
          formattedDate,
          selectedAmPm,
          selectedTimezone,
          formattedTime
        };
      }
      const completeFormData = { ...values,filtersData,message,title,schedule,price};
      const response = await post(`creator/sendMessage`,completeFormData);
      if(response && response.status){
        form.resetFields();
        antdMessage.success('Success!');
        handleCancel();
      }
     }catch(error){
      console.log("loading......",error);
    } finally {
      setIsMessageSending(false);
    }
  }

  const handleCheckboxChange = (checkedValues) => {
    setSelectedCommunities(checkedValues);
  };

  const onCheckAllChange = (e) => {
    const crm = [];
  
    communitiesList.forEach(c => {
      crm.push(c?.value);
    });

    setSelectedCommunities(e.target.checked ?crm : []);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(newMonth);
  };

  const handleTodayClick = () => {
    const today = dayjs();
    setSelectedDate(today);
    setCurrentMonth(today);
  };

  const handleScheduleCancel = () => {
    setIsScheduleModalVisible(false);
  }

  const onPanelChange = (value, mode) => {
    setCurrentMonth(value);
  };

  const filterMenu = (
    <Menu style={{ maxWidth: '600px', minWidth: isMobile ? '100%' : '500px', width: '100%' , padding: 0 }}>
      <Spin spinning={isLoading}>
      <Form form={fillterform}  onFinish={applyFillters} layout="vertical" className="fan-communities">

        <Flex vertical style={{ padding: '20px 25px 0px 25px' }}>
       
          <Form.Item name="fanscommunities" label="Fans & Communities" style={{ marginBottom: 0 }}>
          <Checkbox onChange={onCheckAllChange} checked={checkAll}>
              All
            </Checkbox>
            <Checkbox.Group
              style={{ gap: '14px' }}
              options={communitiesList}
              value={selectedCommunities}
              onChange={handleCheckboxChange}
            />
          </Form.Item>
        </Flex> 

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 0px 25px' }}>
          <Form.Item name="gender" label="Gender" style={{ marginBottom: 0 }}>
            <Radio.Group defaultValue="all" value={selectedGender} onChange={handleGenderChange}>
              <Radio value="all">All</Radio>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
              <Radio value="other">Other</Radio>
            </Radio.Group>
          </Form.Item>
        </Flex>

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 0px 25px' }}>
          <Form.Item name="ageRange" label="Age">
            <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} />
          </Form.Item>

          <Form.Item name="location" label="Location" style={{ marginBottom: 10 }}>
              <AddressAutocomplete/>
          </Form.Item>
        </Flex>

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 20px 25px' }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="default" onClick={() => {
              setIsFilterVisible(false);
              fillterform.resetFields();
              }} style={{ marginRight: '10px' }}>Cancel</Button>
            <Button type="primary" htmlType='submit'>Apply</Button>
          </Form.Item>
        </Flex>
      </Form>
      </Spin>
    </Menu>
  );

  const displayFilters = () => {
    const { communities, gender, ageRange } = appliedFilters;

    return (
      <div>
        {communities.length > 0 && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Communities: {communities.join(', ')}
          </span>
        )}
        {gender && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Gender: {gender}
          </span>
        )}
        {selectAgeRange[0] && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Age: {selectAgeRange[0]} - {selectAgeRange[1]}
          </span>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={940}
      className="send-message-popup"
      footer={false}
    >
  <Spin spinning={isMessageSending}>
    <Form form={form}  onFinish={sendMessageHandler} layout="vertical" >
      <div style={{ padding: 20, borderBottom: '1px solid #EAECF0' }}>
        {isEditingTitle ? (
          <Flex>
            <Input 
              value={title} 
              onChange={handleTitleChange} 
              onBlur={handleTitleBlur} 
              placeholder="Enter campaign name"
              autoFocus 
              style={{ marginRight: 10, width: 'auto', fontSize: 18, fontWeight: 700, padding: 0, border: 0, borderBottom: '1px solid #EAECF0', boxShadow: '0 0', borderRadius: 0 }} 
            />
          </Flex>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 700, color: '#000000' }}>{title}</Text>
            <Button type="text" icon={<EditOutlined style={{ color: '#000000' }} />} onClick={toggleEditTitle} />
          </div>
        )}
      </div>

      <Flex>
        <Flex vertical style={{ flex: 1, padding: 20 }}>

          <Flex gap={10} horizotanl="true" style={{ marginBottom: 10 }}>
            <Form.Item 
              name="to" 
              rules={[{ required: true, message: "Please add the receiver's phone numbers.!" }]}
              className="custom-form-item"
              style={{ flex: '1', marginBottom: 0 }}
            >
              <Input
                placeholder=""
                addonBefore={<Text>To:</Text>}
                readOnly={true}
              />
            </Form.Item>
            <Dropdown
              overlay={filterMenu}
              trigger={['click']}
              onVisibleChange={handleFilterVisibleChange}
              visible={isFilterVisible}
              className="dropdown-btn"
              placement="bottomRight"
            >
              <Button icon={<PlusOutlined />} style={{ height: '44px', minWidth: '40px' }}></Button>
            </Dropdown>
          </Flex>

          {displayFilters() && (
            <Flex vertical style={{ marginTop: '10px' }}>
              {displayFilters()}
            </Flex>
          )}

          <Form.Item 
           name="messagebox"
           labelCol={{ span: 24 }}
           wrapperCol={{ span: 24 }}
           rules={[{ required: !message, message: 'Please input your message!' }]}
           className="custom-form-item custom-message-box"
          >
            <ChatInput setSelectedPrice={setSelectedPrice} onInputChange={handleInputChange} />
          </Form.Item>

          <Flex
            vertical
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

          {price !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
             <span style={{ color: '#344054', fontSize: '16px', fontWeight: 600 }}>{`$${price.toFixed(2)}`}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsPriceModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
            </Flex>
            ) : (
              ''
          )}

          {scheduleMessage !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '0px'
                }}
              >
                <span style={{ color: '#344054', fontSize: '16px' }}>{scheduleMessage}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsScheduleModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
              </Flex>
            ) : (
            ''
          )}
          </Flex>
 
          <Flex
            horizotanl
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

          {scheduleMessage !== null ? ( 
             ''
            ) : (
              <Button size="large" style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB', display: 'flex', alignItems: 'center', padding: '7px 15px 7px 10px' }} icon={<Image src={CalendarPlusIcon} preview={false} />} onClick={showModal}>Schedule</Button>
          )}

          {price !== null ? ( 
              ''
            ) : (
              <Button
                size="large"
                style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB', display: 'flex', alignItems: 'center', padding: '7px 15px 7px 10px' }}
                onClick={() => setIsPriceModalVisible(true)}
                icon={<Image src={CurrencyDollarIcon} preview={false} />}
              >
                Set Price
              </Button>
          )}
          </Flex>

          <Flex justify="end" style={{ marginTop: 20 }}>
            <Button key="submit" htmlType='submit' type="primary" size="medium" style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>
              Send
            </Button>
          </Flex>

        </Flex>
        {isMobile ?
          ""
        :
          <Flex vertical style={{ flex: '0 1 255px', maxWidth: '255px', background: '#F9FAFB', borderLeft: '1px solid #EAECF0', padding: '24px 24px' }}>
            <Flex vertical style={{ border: '2px solid #000000', borderRadius: '24px', height: 500, overflow: 'hidden', background: '#ffffff' }}>
              <Flex vertical align="center" style={{ textAlign: 'center', marginBottom: 10, paddingTop: 30 }}>
              {user?.profileUrl ?
                  <Avatar size={40} src={getImageUrl(user?.profileUrl)} />          
                  :
                  <Avatar size={40} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
                }
               <Text>{user?.first_name} {user?.last_name}</Text>
              </Flex>

              {message &&
              <div style={{ flex: '1 1 0%', overflow: 'hidden auto', scrollbarWidth: 'none' }}>
                <Flex style={{ padding: '10px 13px', maxWidth: '80%'}}>
                  <div className="chat-bubble" style={{ background: '#e9e9eb', borderRadius: 20, padding: '6px 12px', overflowWrap: 'anywhere' }}>
                 
                     <div dangerouslySetInnerHTML={{ __html: message }} style={{ whiteSpace: 'pre-wrap' }} />
                  </div>
                </Flex>
              </div>
              }
            </Flex>
          </Flex>
        }
      </Flex>
      </Form>
    </Spin>
      {isPriceModalVisible && (
        <Modal
          open={isPriceModalVisible}
          onCancel={handlePriceModalCancel}
          footer={null}
          width={560}
          className="price-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Content Price</Title>
          <Form.Item className="custom-form-input-price" style={{ marginBottom: 5 }}>
            <Input
              placeholder=""
              prefix="$"
              value={priceInput}
              onChange={handlePriceInputChange}
              style={{ marginBottom: '5px' }}
            />
          </Form.Item>
          <Text>$0.99 is standard minimum starting number.</Text>
        </Flex>
        <Flex gap="small" justify="flex-end" style={{ marginTop: '10px', borderTop: '1px solid #EAECF0', padding: '15px 20px' }}>
          <Button size="large" onClick={handlePriceModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button size="large" type="primary" onClick={handleApplyPrice} style={{ fontWeight: 600 }}>Apply</Button>
        </Flex>
        </Modal>
      )}

      <Modal visible={isScheduleModalVisible} onOk={handleOk} onCancel={handleScheduleCancel} width={688} className="schedule-modal" footer={[
        <Flex gap={14} justify="end" style={{ padding: '16px 20px', borderTop: '1px solid #EAECF0' }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={handleOk} 
          style={{ fontWeight: 600 }}
          >Apply</Button>
        </Flex>]}>
        <Flex horizotanl style={{ display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>
          
          <Flex vertical style={{ borderRight: isMobile ? '' : '1px solid #EAECF0', padding: '20px 10px' }}>
            <Flex vertical style={{ padding: '0 10px 0 15px', marginBottom: 20 }}>
              <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>Send on</Text>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <Button type="text" icon={<LeftOutlined />} onClick={() => handleMonthChange(false)} />
                <Text style={{ fontWeight: 600 }}>{currentMonth.format('MMMM YYYY')}</Text>
                <Button type="text" icon={<RightOutlined />} onClick={() => handleMonthChange(true)} />
              </div>
              <Flex gap={10} className="date-today-button">
                <Input 
                  value={selectedDate ? selectedDate.format('MMM DD, YYYY') : ''}
                  readOnly
                  style={{ flex: 1 }}
                />
                <Button onClick={handleTodayClick}>Today</Button>
              </Flex>
            </Flex>
            <Calendar
              fullscreen={false}
              value={selectedDate || currentMonth}
              onSelect={handleDateChange}
              onPanelChange={onPanelChange} 
              className="schedule-calendar"
              headerRender={() => null}
            />
          </Flex>

          <Flex vertical style={{ minWidth: '300px', padding: 20, borderTop: isMobile && '1px solid #EAECF0' }}>
            <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>At this time</Text>
            <Flex horizotanl gap={10} align="center">
              <TimePicker 
                use12Hours 
                format="h:mm" 
                style={{ flex: 1 }}
                onChange={(time) =>{
                 
                  if(time){
                    const getAMPM=time.format('a');
                    setSelectedAmPm(getAMPM);
                  }
                  
                  setSelectedTime(time)
                }} 
                changeOnScroll 
                needConfirm={false}
              />
              <Text>at</Text>
              <Select 
                value={selectedAmPm} 
                onChange={(value) => setSelectedAmPm(value)}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </Flex>

            <div style={{ marginTop: '20px' }}>
              <Text>Timezone</Text>
              <Select 
                allowClear
                style={{ width: '100%', marginTop: '10px' }} 
                value={selectedTimezone} 
                onChange={(value) => setSelectedTimezone(value)}
                showSearch
                optionFilterProp="children"
              >
                 {timezones.map((timezone) => {
                    const offset = momenttmz.tz(timezone).format('Z');
                    return (
                      <Option key={timezone} value={`${timezone}`}>
                        {`${timezone} UTC${offset}`}
                      </Option>
                    );
                  })}
              </Select>
            </div>

          </Flex>
        </Flex>
      </Modal>

    </Modal>
  );
};

export default NewMessage;
