import React from 'react';
import { Layout, Flex } from 'antd';
import Welcome from '../../../components/Team/Welcome';
import Footer from '../../../components/Footer';
import useScreenSize from '../../../utils/useScreenSize';

const { Content } = Layout;

const TeamWelcome = () => {
    const { isMobile } = useScreenSize();
    return (<>
        <Content style={{ padding: isMobile ? '0' : '40px 0px' }}>
            <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
                <Welcome />
            </Flex>
        </Content>
        <Flex style={{ paddingBottom: 30, paddingTop: 50 }} justify="center">
            <Footer/>
        </Flex>
    </>);
}

export default TeamWelcome;
