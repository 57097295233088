import React,{useState,useContext} from 'react';
import { Row, Col, Switch, Button, Flex, Form, Divider, Typography,Spin,message } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { put } from '../../utils/api';
import { convertStringsToBooleans } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text,  } = Typography;

const Notifications = ({settinginfo}) => {
  settinginfo=convertStringsToBooleans(settinginfo);
    const [form] = Form.useForm();
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const { isMobile } = useScreenSize();

    const onChange = (checked) => {
      console.log(`switch to ${checked}`);
    };

    const onSubmit =async (values) => {
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,values);
        if (response && response.status === 200) {
          message.success(response.message);
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };
    if(!settinginfo){
      return false;
    }
    return(
        <div>
        <Spin spinning={loading}>
          <Flex className="head-inner" horizotanl justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Notifications</Title>
                <Text>We may still send you important notifications about your account outside of your notification settings.</Text> 
            </Flex>
          </Flex>

          <Divider />

          <Form form={form} initialValues={settinginfo} onFinish={onSubmit} className="setting-form">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                    <Text level={4} style={{ fontWeight: 600 }}>Messages</Text>
                    <Text>These are notifications for messages on your chats and replies.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
               
                <Flex gap={18} style={{ marginBottom: 10 }}>
                  <Form.Item
                    name="notifications_message_push"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }
                  >
                    <Switch onChange={onChange} />
                </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Push</Text>
                  </Flex>
                </Flex>

                <Flex gap={18} style={ isMobile ? { marginBottom: 0 } : { marginBottom: 20 }}>
                <Form.Item
                    name="notifications_message_email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }

                  >
                    <Switch  onChange={onChange} />
                </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Email</Text>
                  </Flex>
                </Flex>

              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                    <Text level={4} style={{ fontWeight: 600 }}>New fan</Text>
                    <Text>These are notifications for when someone becomes a new fan to your account.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
               
                <Flex gap={18} style={{ marginBottom: 10 }}>
                <Form.Item
                    name="notifications_new_fan_push"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }

                  >
                    <Switch  onChange={onChange} />
                  </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Push</Text>
                  </Flex>
                </Flex>

                <Flex gap={18} style={ isMobile ? { marginBottom: 0 } : { marginBottom: 20 }}>
                <Form.Item
                    name="notifications_new_fan_email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }
                  >
                    <Switch onChange={onChange} />
                  </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Email</Text>
                  </Flex>
                </Flex>

              </Col>
            </Row>

            <Divider />

             <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                    <Text level={4} style={{ fontWeight: 600 }}>New purchases</Text>
                    <Text>These are notifications to notify when a fan purchases content from your messages.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
               
                <Flex gap={18} style={{ marginBottom: 10 }}>
                <Form.Item
                    name="notifications_new_purchases_push"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }
                  >
                  <Switch onChange={onChange} />
                  </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Push</Text>
                  </Flex>
                </Flex>

                <Flex gap={18} style={ isMobile ? { marginBottom: 0 } : { marginBottom: 20 }}>
                <Form.Item
                    name="notifications_new_purchases_email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: false }]}
                    style={ isMobile ? { marginBottom: 0 } : "" }
                  >
                  <Switch onChange={onChange} />
                  </Form.Item>
                  <Flex vertical>
                    <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Email</Text>
                  </Flex>
                </Flex>

              </Col>
            </Row>

            <Divider />

            <Flex
                horizotanl
                justify="end"
                gap="middle"
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
              <Button style={{ fontWeight: 600  }} size="large" >
                Cancel
              </Button>
                <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                  Save
                </Button>
            </Flex>

          </Form>
          </Spin>
        </div>
    );
};

export default Notifications;
