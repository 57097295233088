import React, { useState, useContext,useEffect } from 'react';
import { Layout, Typography, Breadcrumb, Button, Progress, Flex, Radio, Divider, Avatar, Row, Col, Input, Table, Form } from 'antd';
import { RightOutlined, BarChartOutlined, SearchOutlined } from '@ant-design/icons';
import { get } from '../../../utils/api';
import { UserContext } from '../../../contexts/UserContext';
import AvatarImage from '../../AvatarImage';
import { calculateAge,formatPrice } from '../../../utils/utils';
const { Title: AntTitle, Text } = Typography;
const { Search } = Input;
const EngagementReport = () => {

  const [selectedRange, setSelectedRange] = useState('top');
  const {user}  = useContext(UserContext);
  const [topPagination, setTopPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [loading, setLoading] = useState(false);
  const [topFansData, setTopFansData] = useState([]);

  const handleRangeChange = (elem) => {
    const val=elem.target.value;
    setSelectedRange(val);
    fetchTopUsers({sortBy:val});
  };

 
  const fetchTopUsers = async (params = {}) => {
    setLoading(true);
    try {
      const response = await get('creator/topFans', {
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 10,
      q: params?.searchTerm ||'',
      sortBy:params?.sortBy ||'',
      });
      setTopFansData(response.data);
      setTopPagination({
      ...params.pagination,
      total: response.pagination.total,
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
    };
    useEffect(()=>{
      fetchTopUsers();
     },[]);

    const handleTopTableChange = (pagination) => {
        fetchTopUsers({ pagination });
      };
    const handleTopSearch = (value) => {
      fetchTopUsers({searchTerm:value});
    };
    const searchTopChange=(elem)=>{
      try{
        const val=elem.target.value;
        if(val?.length===0){
          fetchTopUsers();
        }
      }catch(error){
        console.log(error);
      }
    }

      const columnsTopFan = [
        {
          title: 'Name & Number',
          dataIndex: 'name',
          key: 'name',
          render: (text, record) => {
            const {revenueUser}=record;
            const imageId = revenueUser && revenueUser?.uploadedIds && revenueUser?.uploadedIds.length > 0 ? revenueUser?.uploadedIds[0] : null;
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                   <AvatarImage size={30} imageId={imageId} title={`${revenueUser?.first_name} ${revenueUser.last_name}`}   />
                <div>
                  <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 5 }}>{revenueUser?.first_name} {revenueUser?.last_name}</AntTitle>
                  <div style={{ fontSize: '14px', color: '#101828' }}>+1{revenueUser?.phone_number}</div>
                </div>
              </div>
            );
          },
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'gender',
          render: (text, record) => {
            const {revenueUser}=record;
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.gender}</div>
            );
          }
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
          render: (text, record) => {
            const {revenueUser}=record;
            const value =calculateAge(revenueUser?.dob);
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
            );
          }
        },
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          render: (text, record) => {
            const {revenueUser}=record;
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.city}</div>
            );
          }
        },
        {
          title: 'Interactions',
          dataIndex: 'interactions',
          key: 'interactions',
          render: (text, record) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>{record?.itemCount} Interactions {formatPrice(record?.totalAmount || 0)} Spent</div>
            );
          }
        },
      ];


  return (
    <Layout style={{ minHeight: '100vh', padding: 30 }}>
      {user?.role==="Team"?
      <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/team/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/team/insights">
          <span>Audience Insights</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Engagement</span>
        </Breadcrumb.Item>
      </Breadcrumb>:
      <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/insights">
          <span>Audience Insights</span>
          </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Engagement</span>
        </Breadcrumb.Item>
      </Breadcrumb>}

      <Flex horizontal="true" style={{ marginBottom: 40 }}>
        <Flex vertical>
          <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Engagement report</AntTitle>
          <Text>Manage and see your top fan engagements.</Text>
        </Flex>
      </Flex>

      <Flex horizontal="true" align="center">
        <Flex vertical>
          <AntTitle level={4} style={{ marginTop: 0, marginBottom: 0, fontSize: '18px' }}>Metrics</AntTitle>
        </Flex>
        <Flex className="button-date-group" style={{ marginLeft: 'auto' }}>
          <Radio.Group value={selectedRange} onChange={handleRangeChange}>
            <Radio.Button key="top" value="top">Top</Radio.Button>
            <Radio.Button key="most" value="most">Most recent</Radio.Button>
            <Radio.Button key="least" value="least">Least recent</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Divider />

      <Flex vertical>
        <Row>
          <Col span={6}>
            <Form.Item 
              label="Search fan"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
                <Search
                        placeholder="Search by name"
                        allowClear
                        onSearch={handleTopSearch}
                        enterButton
                        onChange={searchTopChange}
                      />
            </Form.Item>
          </Col>
        </Row>
        <div className="insight-table">
            <Table 
                columns={columnsTopFan} 
                dataSource={topFansData} 
                pagination={topPagination} 
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTopTableChange}
                />
        </div>
      </Flex>
  
    </Layout>
  );
};

export default EngagementReport;
