import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { get } from '../utils/api';

const AddressAutocomplete = ({ value = '', onChange }) => {
  const [options, setOptions] = useState([]);

  const handleSearch = async (value) => {
    if (value) {
      const response = await get(`auth/autocomplete?input=${value}`);
      const suggestions = response.predictions.map((prediction) => ({
        value: prediction.description,
      }));
      setOptions(suggestions);
    } else {
      setOptions([]);
    }
  };

  const handleChange = (val) => {
    setOptions([]);
    if (onChange) {
      onChange(val);
    }
  };
  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onChange={handleChange}
      style={{ width: '100%' }}
      value={value}
    >
      <Input.Search placeholder="Enter city name" />
    </AutoComplete>
  );
};

export default AddressAutocomplete;
