import { useState } from "react";
import {createUseStyles} from "react-jss"

// Sub Components
import  MyGraph  from "./graph.js";

const useStyles = createUseStyles(() => ({
  container: {
    color: '#fff',
    padding: '0',
    transition: '0.3s ease-in-out',
    width: '100%',
    height: '400px',
    display:'flex',
    flexDirection:'column',
    position:'relative'
  },
}));

function Analytics({ data,isFormated }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MyGraph data={data} isFormated={isFormated} />
    </div>
  );
}

export default Analytics;