import React, { useState,useContext,useEffect } from 'react';
import { Row, Image, Col, Switch, Button, Flex, Form, Input, Divider, Typography,Spin,message  } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import EqualIcon from '../../images/equal-icon.svg';
import { convertStringsToBooleans } from '../../utils/utils';
import { put } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text } = Typography;

const DefaultPrice = ({settinginfo}) => {

    const [form] = Form.useForm();
    settinginfo=convertStringsToBooleans(settinginfo);
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const [privateRequests, SetPrivateRequests] = useState(false);
    const { isMobile } = useScreenSize();

    const onSubmit =async (values) => {
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,values);
        if (response && response.status === 200) {
          message.success(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    useEffect(()=>{
      if(settinginfo?.is_private_requests){
        SetPrivateRequests(true);
      }
    },[settinginfo]);
  
    return(
        <div>
          <Spin spinning={loading}>
          <Form form={form} onFinish={onSubmit} initialValues={settinginfo} className="setting-form" style={{ marginTop: 10 }}>

          {privateRequests &&
            <>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                      <Text level={4} style={{ fontWeight: 600 }}>Default Price</Text>
                    </Flex>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>

                <Form.List name="default_price_list">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Flex
                            key={key}
                            vertical
                            className="request-list-row"
                            style={{ marginBottom: 10, width: '100%', position: 'relative', paddingLeft: 35, paddingRight: 35 }}
                            align="baseline"
                          >
                            <Flex vertical style={{ width: '100%' }}>
                              <Image width={20} src={EqualIcon} preview={false} />
                              <Flex vertical>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'title']}
                                  fieldKey={[fieldKey, 'title']}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'Please input a title!' }]}
                                  className="custom-form-item"
                                  style={{ marginBottom: 0, width: '100%' }}
                                >
                                  <Input placeholder="Example: Sing me a song" className="custom-form-item" />
                                </Form.Item>
                                <Text style={{ textAlign: 'right' }}>30 Characters</Text>
                                {fields.length > 0 && (
                                  <CloseCircleOutlined
                                    style={{ color: '#D92D20', position: 'absolute', right: 0, top: 12, fontSize: 20 }}
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </Flex>

                              <Row gutter={20}>
                                <Col span={24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'basePrice']}
                                    fieldKey={[fieldKey, 'basePrice']}
                                    label="Price"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: false, message: 'Please input your base price!' }]}
                                    className="custom-form-item"
                                  >
                                    <Input addonBefore={<Text>$</Text>} placeholder="00.99" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Flex>
                            <Divider style={ isMobile ? { margin: 10 } : ""} />
                          </Flex>
                        ))}
                        <Form.Item style={ isMobile ? { marginBottom: 0, marginLeft: 35 } : { marginLeft: 35 }}>
                          <Button type="link" onClick={() => add()} icon={<PlusOutlined />} style={{ fontWeight: 600, padding: 0 }}>
                            Add another
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>

                </Col>
              </Row>

              <Divider />

              <Flex
                  horizotanl
                  justify="end"
                  gap="middle"
                  style={{
                    width: '100%',
                    marginTop: '20px'
                  }}
                >
                <Button style={{ fontWeight: 600 }} size="large">
                  Cancel
                </Button>
                  <Button htmlType='submit' type="primary" size="large" style={{ fontWeight: 600 }}>
                    Save
                  </Button>
              </Flex>
              </>
           
          }
          </Form>
          </Spin>
        
        </div>
    );
};

export default DefaultPrice;
