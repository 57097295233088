import React from 'react';
import { Helmet } from 'react-helmet';
const ContentSharing=()=>{
    const currentUrl = window.location.href;
    const ogpTags = {
        'og:title': 'Your Page Title',
        'og:type': 'website',
        'og:url': currentUrl,
        'og:image': 'https://phpstack-1262415-4593135.cloudwaysapps.com/uploads/singleImage-1718107387718.png',
        'og:description': 'Your page description.',
        'og:site_name': 'Your Site Name',
        'og:locale': 'en_US',
    };

    return (
        <>
            <Helmet>
                {Object.keys(ogpTags).map((property) => (
                    <meta key={property} property={property} content={ogpTags[property]} />
                ))}
            </Helmet>
            <p>Hi</p>
        </>
    );
}
export default ContentSharing;