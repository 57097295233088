import React,{useState,useEffect,useContext} from 'react';
import { Layout, Row, Col, Button, Upload, Image, Form, Input, Flex, Avatar, Divider, Typography, message, Spin,Modal } from 'antd';
import { MailOutlined, LogoutOutlined,CloudUploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CopyIcon from "../../../images/copy-icon.svg";
import UploadIcon from '../../../images/upload-icon.svg';
import { UserContext } from '../../../contexts/UserContext';
import { AuthContext } from '../../../contexts/AuthContext';
import AvatarImg from "../../../images/avatar-image.png";
//import '../profile.css';
import { get,put } from '../../../utils/api';
import { extractImageIds,getImageUrl,imageUrl,getInitials,convertStringsToBooleans } from '../../../utils/utils';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const { Dragger } = Upload;


const TeamProfile = () => {

  const [form] = Form.useForm();
  const [loading,setLoading]=useState(false);
  const [profileInfo,setProfileInfo]=useState(null);
  const {user}  = useContext(UserContext);
  const [uploadedIds, setUploadedIds] = useState([]);
  const [profileImage,setProfileImage]=useState(null);
  const [textboxMessage, setTextboxMessage] = useState("");
  const [settinginfo, setSettingInfo] = useState(null);
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const maxLength = 500;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try{
        const completeFormData = { ...values,uploadedIds};
        const response = await put(`admin/users/update/${user?.id}`, completeFormData);
        if (response && response.status === 200) {
            if(response?.token){
              localStorage.setItem('token', response?.token);
            }
            message.success('Update successful');
            window.location.reload();
          }else{
            message.error(response.message);
          }
    }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'Error submitting form');
        } else {
          message.error(error.message || 'Error submitting form');
        }
      } finally {
        setLoading(false);
      }
  };

  useEffect(()=>{
    const init=async()=>{
      try {
        setLoading(true);
        const response = await get(`/creator/get-profile/${user?.id}`);
        if(response && response.data){
           setProfileInfo(response.data); 
           setUploadedIds(response.data?.uploadedIds);
           setSettingInfo(response.data?.bio);
           if(response.data && response.data.images && response.data.images){
            const {filepath}=response.data.images[0];
            setProfileImage(getImageUrl(filepath));
           }
        }
      }catch(error){
        console.log('error',error);
      }finally {
        setLoading(false);
      }
    }
    init();
   
  },[user?.id]);

  const handleCopy = () => {
    const inputValue = form.getFieldValue('assign_number');
    console.log(inputValue, 'inputValue');
    navigator.clipboard.writeText(inputValue).then(() => {
      message.success('Copied to clipboard!');
    }).catch(err => {
      message.error('Failed to copy!');
    });
  };

  useEffect(() => {
    if (settinginfo) {
      setTextboxMessage(settinginfo);
    }
  }, [settinginfo]);

  const handleChange = (e) => {
    if (e.target.value.length <= maxLength) {
      setTextboxMessage(e.target.value);
    }
  };

  if(!profileInfo){
    return (  <Layout className="profile-page" style={{ padding: 30 }}><Spin spinning={true}></Spin></Layout>);
  }

  const handleLogout = () => {
    setIsModalVisible(true);
  };

 
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logoutHandel = () => {
    localStorage.removeItem('token');
    message.success('Logged out successfully!');
    setAuth(false);
    navigate('/');
  }

  return(
    <Layout className="profile-page" style={{ padding: 30 }}>
      <Spin spinning={loading}>
        <Flex className="content-head-area" horizontal justify="space-between" align="center">
              <Title level={2} style={{ marginBottom: '10px' }}>My Profile</Title>
              <Button size="large" onClick={handleLogout} icon={<LogoutOutlined />} style={{ fontWeight: 600 }}>Log out</Button>
          </Flex>

          <Title level={4} style={{ fontSize: '18px', marginBottom: 5 }}>Personal info</Title>
          <Text>Update your photo and personal details here.</Text> 
      
          <Divider />

        <Divider />
       
        <Form form={form} initialValues={profileInfo} onFinish={onSubmit} className="setting-form">
        <Row>
            <Col span={6}>
                <Flex vertical>
                  <Text level={4} style={{ fontWeight: 600 }}>Name</Text>
                </Flex>
            </Col>
            <Col span={1}></Col>
            <Col span={12}>

                <Row gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      name="first_name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please input your first name!' }]}
                      className="custom-form-item"
                      defaultValue="Oliva"
                    >
                      <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="last_name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please input your first name!' }]}
                      className="custom-form-item"
                    >
                      <Input placeholder="Enter last name" style={{ padding: '7px 10px' }} />
                    </Form.Item>
                  </Col>
                </Row>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col span={6}>
                <Flex vertical>
                  <Text level={4} style={{ fontWeight: 600 }}>Email address</Text>
                </Flex>
            </Col>
            <Col span={1}></Col>
            <Col span={12}>

                <Form.Item
                  name="email"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your first name!' }]}
                  className="custom-form-email"
                >
                  <Input  prefix={<MailOutlined />} placeholder="Enter first name" style={{ padding: '4px 10px' }} className="custom-form-item" />
                </Form.Item>

            </Col>
          </Row>

          <Row>
            <Col span={6}>
                <Flex vertical>
                  <Text level={4} style={{ fontWeight: 600 }}>Your photo</Text>
                  <Text>This will be displayed on your profile.</Text> 
                </Flex>
            </Col>
            <Col span={1}></Col>
            <Col span={12}>

                <Flex className="avatar-with-upload" horizontal gap={20} style={{ marginTop: 10 }}>
                  <Flex>
                    {profileImage? <Avatar size={165} src={profileImage} />: <Avatar size={96} className="rounded-avatar" style={{ fontSize: 26, fontWeight: 600 }}>
                    {getInitials(`${profileInfo?.first_name} ${profileInfo?.last_name}`)}
                    </Avatar>}
                  </Flex>
                  <Flex className="avatar-upload-area">
                  <Form.Item name="uploadedIds"  noStyle>
                      <Input type="hidden" />
                  </Form.Item>
                    <Form.Item
                      name="photo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                     <Dragger 
                        multiple
                        name="singleImage"
                        action={`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`}
                        onChange={async (info)=>{
                          console.log("info",info);
                          const { status } = info.file;
                          if (status !== 'uploading') {
                            console.log(info.file, info.fileList);
                          }
                          if (status === 'done') {
                            message.success(`${info.file.name} file uploaded successfully.`);
                          } else if (status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                          }
                          const {fileList}=info;
                          const getIds=extractImageIds(fileList);
                          if(getIds){
                            const url=await imageUrl(getIds[0]);
                            setProfileImage(url);
                            setUploadedIds(getIds);
                          }
                        }} style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
                          <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
                            <Image src={UploadIcon} preview={false} size={40} />
                          </p>
                          <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
                          <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                      </Dragger>
                    </Form.Item>
                  </Flex>
                </Flex>

            </Col>
          </Row>

     

          <Divider />


          <Flex
              horizotanl
              justify="end"
              gap="middle"
              style={{
                width: '100%',
                marginTop: '20px'
              }}
            >
            <Button style={{ fontWeight: 600 }} size="large">
              Cancel
            </Button>

            <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
              Save
            </Button>
          
          </Flex>

        </Form>
        </Spin>
        <Modal keyboard={false} maskClosable={false} className="logout-confirm" open={isModalVisible} onOk={logoutHandel} onCancel={handleCancel} width={480} footer={[
        <Flex gap={10} justify="center" style={{ marginTop: 30, marginBottom: 20 }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={logoutHandel} 
          style={{ fontWeight: 600 }}
          >Yes, log me out</Button>
        </Flex>
      ]}>
        <Title level={4} style={{ textAlign: 'center' }}>Are you sure that you want to logout?</Title>
      </Modal>
    </Layout>
  );
};

export default TeamProfile;
