import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, Flex, Typography, Table, Space, Divider, Dropdown, Menu, Modal, Image, Switch, Avatar,message,Spin,Tag } from 'antd';
import { PlusOutlined, EditOutlined, MoreOutlined, MailOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { post,get } from '../../utils/api';
import EditPermissions from '../Network/EditPermissions';
import ShareIcon from "../../images/share-profile-icon.svg";
import EditIcon from "../../images/edit-icon.svg";
import AvatarImage from '../AvatarImage';

const { Title, Text } = Typography;
const {confirm}=Modal;

const Team = () => {

  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalShareVisible, setIsModalShareVisible] = useState(false);
  const [isEditPermissionsVisible, setIsEditPermissionsVisible] = useState(false);
  const [isLoding,setIsLoding]=useState(false);
  const [dataList,setDataList]=useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [permissions, setPermissions] = useState({
    message:false,
    myNetwork:false,
    scheduledMessages:false,
    community:false,
    myGallery:false,
    insights:false,
    notifications:false,
    settings:false,
    myProfile: false,
  //   accessToPrivateVault: false,
  //   setFavorites: false,
  //   schedulePosts: false,
  //   createAndEditCommunities: false,
  //   postPriceToContent: false,
  //   shareInsightsAnalytics: false,
  //   //inviteAndRemoveFromTeamMembers: false,
  //  // deleteMessages: false,
  //  // inviteAndRemoveFromNetwork: false,
  //   deleteContentFromVault: false,
  //   editCreatorsProfile: false,
  //   editAutomations: false,
  });

  const showShareAccessModal = () => {
    setIsModalShareVisible(true);
  };

  const handleShareAccessCancel = () => {
    setIsModalShareVisible(false);
  };

  // const handleShareAccess = () => {
  // 	setIsModalShareVisible(false);
  // 	setIsModalVisible(true);
  // }

  const showGiveAccessModal = () => {
    setIsModalShareVisible(true);
  };

  const sendInvites=async()=>{
      setIsLoding(true);
      try{
        const emails = await form.validateFields();
        const formData={...emails,permissions};
        const response = await post('creator/send-invites',formData);
        if(response && response.status){
          setIsModalShareVisible(false);
          await fetchUsers();
          message.success('Invitation has been successfully sent.');
        }
      }catch(error){
        console.log("error",error);
      }finally{
        setIsLoding(false);
      }
   
  }

  const fetchUsers = async (params = {}) => {
		setIsLoding(true);
		try {
		  const response = await get('creator/invites', {
			_page: params.pagination?.current || 1,
			_limit: params.pagination?.pageSize || 10,
			q: params?.searchTerm ||'',
		  });
		  setDataList(response?.data?.rows);
		  setPagination({
			...params.pagination,
			total: response.pagination.total,
		  });
		} catch (error) {
		  console.error('Error fetching users:', error);
		} finally {
		  setIsLoding(false);
		}
	  };

    useEffect(()=>{
      fetchUsers();
     },[]);

  const handleShareAccess = async() => {
   
    try {
      const values = await form.validateFields();
     
      confirm({
        title: 'Are you sure?',
        content: 'Are you sure you want to give this user access to your profile? You can change this later.',
        okText: 'Confirm',
        cancelText: 'Back',
        onOk:sendInvites,
        onCancel() {
          console.log('Cancel');
        },
      });
      // proceed with form submission or other logic
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const handleGiveAccessCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveFromNetwork = (record) => {
    // Logic to remove from network
    console.log('Removed from network', record);
  };

  const handleToggle = (key) => {
    setPermissions({ ...permissions, [key]: !permissions[key] });
  };

  const editPermissions=async(record)=>{
    setSelectedUser(record);
  }

  const removeRequest=async (record)=>{
    setIsLoding(true);
    try{
   
      const formData={...record,permissions};
      const response = await post('creator/permissionsRemove',formData);
      if(response && response.status){
        message.success('Permissions are removed successfully.');
        await fetchUsers();
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoding(false);
    }
  }

  const menu = (record) => (
    <Menu>
      <Menu.Item 
      	icon={ 
	      	<Image
	            width={12}
	            src={EditIcon}
	            preview={false}
	        />
	    } onClick={()=>editPermissions(record)}><Text style={{ marginLeft: 8 }}>Edit Permissions</Text></Menu.Item>
      <Menu.Item onClick={()=>removeRequest(record)} icon={<DeleteOutlined />}>Remove Access</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AvatarImage title={`${record?.firstName} ${record?.lastName}`} size="large" style={{ marginRight: 10 }} />
          <div>
            <Text style={{ fontWeight: 600 }}>{record?.firstName} {record?.lastName}</Text>
            <br />
            <Text type="secondary">{record.email}</Text>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {text==1?
              <Tag color="green">Active</Tag>:
            <Tag color="gold">Pending</Tag>}
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <Button type="text" icon={<EditOutlined />} /> */}
          <Dropdown overlay={menu(record)} trigger={['click']}>
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Smith',
  //     handle: 'johnsmith@gmail.com',
  //     status: 'Pending',
  //     image: 'https://via.placeholder.com/40',
  //   },
  //   {
  //     key: '2',
  //     name: 'Phoenix Baker',
  //     handle: 'phoenix@gmail.com',
  //     status: 'Active',
  //     image: 'https://via.placeholder.com/40',
  //   },
  //   {
  //     key: '3',
  //     name: 'Lana Steiner',
  //     handle: 'lana@gmail.com',
  //     status: 'Active',
  //     image: 'https://via.placeholder.com/40',
  //   },
  //   {
  //     key: '4',
  //     name: 'Demi Wilkinson',
  //     handle: 'demi@gmail.com',
  //     status: 'Active',
  //     image: 'https://via.placeholder.com/40',
  //   },
  // ];

  	useEffect(() => {
        form.setFieldsValue({
            emails: [''],
        });
    }, [form]);

    const onSubmit = (values) => {
        console.log('Form values:', values);
    };


    const validateEmails = (_, value) => {
      if (!value || value.length < 1) {
        return Promise.reject(new Error('At least one email is required'));
      }
      return Promise.resolve();
    };


    const checkEmailAvailability = async (_, value) => {
      if(!value){
        return false;
      }
      try {
        const response = await get(`creator/teamCheckemail/${value}`);
        if (response.available) {
          return Promise.resolve(); // Username is available
        } else {
          return Promise.reject('An invitation has already been sent to this email. Please try another.');
        }
      } catch (error) {
        return Promise.reject('Failed to check username availability.');
      }
    };
 

  return (
    <div>
       {selectedUser ?(<EditPermissions user={selectedUser} onBack={() => {setSelectedUser(null);fetchUsers();}} />):
     ( <><Flex className="head-inner" horizotanl={true} justify="space-between">
        <Flex vertical>
          	<Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>My Team</Title>
			<Text>Manage your team members here.</Text>	
        </Flex>
        <Flex vertical>
          	<Button onClick={showShareAccessModal} type="primary" size="large" icon={<PlusOutlined />} style={{ fontWeight: 600 }}>Invite</Button>
        </Flex>
      </Flex>
      <Divider />

      <Table className="my-team-table" columns={columns} dataSource={dataList} />

      <Modal
        open={isModalShareVisible}
        onOk={handleShareAccess}
        onCancel={handleShareAccessCancel}
        width={600}
       	className="share-modal"
        footer={[
	        <Button key="cancel" size="large" onClick={handleShareAccessCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
	        <Button key="share" size="large" type="primary" onClick={handleShareAccess} style={{ fontWeight: 600 }}>Send Invite</Button>,
	    ]}
      >	
      <Spin spinning={isLoding}>
      	<Image
            width={48}
            src={ShareIcon}
            preview={false}
            style={{ marginTop: '10px', marginBottom: '10px' }}
        />
      	<Title level={4} style={{ marginTop: 0, marginBottom: '5px' }}>Invite Team Members</Title>
        <p style={{ marginTop: 0 }}>Send an invite to add a team members to help manage your blonde account.</p>

        <Form form={form} onSubmit={onSubmit}>
	        <Form.List name="emails" rules={[{ validator: validateEmails }]}>
	          {(fields, { add, remove }) => (
	            <>
	              {fields.map(({ key, name, fieldKey, ...restField }) => (
	                  <Flex key={key} className="custom-email" style={{ marginBottom: 10, width: '100%' }} align="baseline">
	                      <Form.Item
	                          {...restField}
	                          name={[name]}
	                          fieldKey={[fieldKey]}
	                          wrapperCol={{ span: 24 }}
	                          rules={[
                              { required: true, message: 'Email is required' },
                              { type: 'email', message: 'Please input a valid email!' },
                              { validator: checkEmailAvailability, message: 'An invitation has already been sent to this email. Please try another.' }
                            ]}
	                          className="custom-form-email"
	                          style={{ marginBottom: 0, width: '100%' }}
                            
	                      >
	                          <Input prefix={<MailOutlined />} placeholder="Enter email" className="custom-form-item" />
	                      </Form.Item>
	                      {fields.length > 2 && <MinusCircleOutlined onClick={() => remove(name)} />}
	                  </Flex>
	              ))}
	              <Form.Item>
	                  <Button type="link" onClick={() => add()} icon={<PlusOutlined />} style={{ fontWeight: 600, padding: 0 }}>
	                      Add another
	                  </Button>
	              </Form.Item>
	            </>
	          )}
	      	</Form.List>

	        <Flex vertical style={{ marginTop: '40px', marginBottom: '25px' }}>
		        <Text level={4} style={{ fontWeight: 600 }}>Permissions</Text>
		        <Text level={4}>Toggle permissions on and off</Text>
	        </Flex>
	    	<Row gutter={24}>
		      {Object.keys(permissions).map((key) => (
	      		<Col className="gutter-row" span={12} key={key} style={{ marginBottom: '20px' }}>
	      			<Flex>
			          <Switch
			            checked={permissions[key]}
			            onChange={() => handleToggle(key)}
			          />
			          <span style={{ marginLeft: '10px', color: '#344054', fontWeight: 500, textTransform: 'capitalize' }}>{key.replace(/([A-Z])/g, ' $1')}</span>
			         </Flex>
		        </Col>
		      ))}
	        </Row>
	    </Form>
        </Spin>
      </Modal>

     


          {/* <Modal
            open={isModalVisible}
            onOk={handleGiveAccessConfirm}
            onCancel={handleGiveAccessCancel}
            width={400}
            className="confirmation-modal"
            footer={[
              <Button key="cancel" size="large" onClick={handleGiveAccessCancel} style={{ fontWeight: 600 }}>Back</Button>,
              <Button key="share" size="large" type="primary" onClick={handleGiveAccessConfirm} style={{ fontWeight: 600 }}>Confirm</Button>,
          ]}
          >
            <Title level={4}>You are about to sure?</Title>
            <p>Are you sure you want to give this user access to your profile? You can change this later.</p>
          </Modal> */}

    </>)}
    </div>
  );
};

export default Team;
