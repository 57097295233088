import React, { useState,useContext,useEffect } from 'react';
import { Card, Button, Flex, Typography } from 'antd';
import { ArrowUpOutlined,ArrowDownOutlined ,RightOutlined } from '@ant-design/icons';
import { UserContext } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../../utils/utils';
import useScreenSize from '../../../utils/useScreenSize';

const { Title, Text } = Typography;

const TotalFans = ({data}) => {
   const [position, setPosition] = useState('end');
   const [growth, setGrowth] = useState(0);
   const [growthString, setGrowthString] = useState(null);
   const navigate = useNavigate();
   const {user}  = useContext(UserContext);

   const compareWeeks=(data)=> {
    const { currentWeekFans, previousWeekFans } = data;
    let ctl=0;
    let changeStatus;
    // Calculate the percentage change
    const percentageChange = ((currentWeekFans - previousWeekFans) / previousWeekFans) * 100;
    
    if(percentageChange===Infinity){
      ctl=100;
    }else{
       ctl = isNaN(percentageChange) ? 0 : percentageChange;
      if (percentageChange >=0) {
          changeStatus = 'increased';
      } else if (percentageChange < 0) {
          changeStatus = 'decreased';
      } 
    }
   return {ctl,changeStatus};
}


   useEffect(()=>{
      if(data){
        const p=compareWeeks(data);
        setGrowth(p?.ctl);
        setGrowthString(p?.changeStatus);
      }
   },[data]);
 
   const { isMobile } = useScreenSize();



   const navigateToReport = () => {
      const redirectLink=user?.role==="Team"?'/team/total-fans-details':'/total-fans-details';
      navigate(redirectLink);
   };

   return (
    <Card className="insight-card">
      <Flex className="top-content" vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
        <Title level={4} style={{ marginTop: 0, fontSize: '18px' }}>Total Fans</Title>
        <Title level={2} style={{ marginTop: 'auto', marginBottom: 10 }}>{formatNumber(data?.totalUsers || 0)}</Title>
        <Text>
          {growthString=="increased"?<span style={{ color: '#079455' }}><ArrowUpOutlined />{Math.abs(growth).toFixed(2)}%</span> :<span style={{ color: 'red' }}><ArrowDownOutlined/>{Math.abs(growth).toFixed(2)}%</span> }
           vs last week</Text>
      </Flex>
      <Flex vertical align="end" style={ isMobile ? { padding: '10px 20px', borderTop: '1px solid #EAECF0' } : { padding: '15px 30px', borderTop: '1px solid #EAECF0' }}>
        <Button type="text" icon={<RightOutlined />} iconPosition={position} style={{ color: '#6941C6', fontWeight: 600, padding: 0, background: 'transparent' }}  onClick={navigateToReport}>View report</Button>
      </Flex>
    </Card>
  );
}

export default TotalFans;
