import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import { Layout, Flex, Image, Button, Spin } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import SmirkingIcon from '../../../images/smirking-face.png';
import './FanSignup.css';
import { get } from '../../../utils/api';
import { useParams } from 'react-router-dom';

const { Content } = Layout;

const FanSignupUnlock = () => {

    const [data,setData]=useState(null);
    const [isLoading,setIsLoading]=useState(false);
    const [creatorData,setCreatorData]=useState(null);
    const [privateRequestEnabled,setPrivateRequestEnabled]=useState(false);
    const { smsId,phone } = useParams();
    useEffect(()=>{
        const init=async()=>{
            setIsLoading(true);
            try{
                const response = await get(`user/paidContent/${smsId}`);
                if (response && response.status) {
                    setData(response?.data.postData);
                    if(response && response?.data?.creatorData){
                        setCreatorData(response?.data?.creatorData);
                        setPrivateRequestEnabled(response?.data?.privateRequestEnabled);
                    }
                }
            }catch(error){
                console.log(error);
            }finally{
                setIsLoading(false); 
            }
        }
        init();
    },[smsId]);

    return (
        <Layout style={{ minHeight: 'calc(100vh - 58px)', backgroundColor: '#000000', justifyContent: 'start' }}>
            <Spin spinning={isLoading}>
            <Content style={{ padding: '16px 20px', flex: '0' }}>
                <Flex vertical style={{ maxWidth: '416px', height: '100vh', margin: '0 auto', borderRadius: 10 }}>
                    <Flex className='unlock-wrapper' style={{ backgroundColor: '#ffffff', borderRadius: 8, padding: 20, marginBottom: 20, flex: 1, overflow: 'hidden auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: data?.message }} style={{ whiteSpace: 'pre-wrap' }} />
                    </Flex>
                    <Flex horizontal="true" gap="middle">
                        {/*{privateRequestEnabled && 
                        <Link to={`/audience/private-requests/${smsId}`}>
                            <Button size="large" icon={<Image width={20} src={SmirkingIcon} preview={false} />} style={{ width: '100%', fontWeight: 600, fontSize: '14px' }}>Private request</Button>
                        </Link>}*/}
                        <Link style={{ width: '100%'}} to={`sms:${data?.creator_phone}`}><Button type="primary" icon={<MessageOutlined style={{ fontSize: '16px' }} />} size="large" style={{ width: '100%', fontWeight: 600, fontSize: '14px' }}>Reply</Button></Link>
                    </Flex>
                </Flex>
            </Content>
            </Spin>
        </Layout>
    );
}

export default FanSignupUnlock;