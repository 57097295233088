import React, { useEffect,useState } from 'react';
import { Form, Input, Typography, Flex, Image, Button,message, Spin } from 'antd';
import CheckIcon from '../../images/check-icon.svg';
import CopyIcon from "../../images/copy-icon.svg";
import { useNavigate } from 'react-router-dom';
import { CopyOutlined, PlusOutlined, MinusCircleOutlined, MailOutlined } from '@ant-design/icons';
import { post } from '../../utils/api';
const { Title, Paragraph } = Typography;

const AccountCreated = ({userEmail,user_name}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false);
    useEffect(() => {
        form.setFieldsValue({
            emails: ['', ''],
        });
    }, [form]);

    const onSubmit =async (values) => {
      setLoading(true);
      try {
        const response = await post('auth/sendInvites',{
          userEmail,
          emails:values?.emails
      });
        if (response && response.status === 200) {
          message.success('The invitation has been sent successfully.');
          navigate('/private-requests');
        } else {
          message.error(response.message || 'Login failed');
        }
  
      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    const handelSkip=(()=>{
      navigate('/private-requests');
    });

    useEffect(() => {
      if (user_name) {
        form.setFieldsValue({ blondehandle: user_name });
      }
    }, [user_name, form]);

    const handleCopy = () => {
      const inputValue = form.getFieldValue('blondehandle');
     
      navigator.clipboard.writeText(inputValue).then(() => {
        message.success('Copied to clipboard!');
      }).catch(err => {
        message.error('Failed to copy!');
      });
    };

    return (
        <div>
          <Spin spinning={loading}>
          <Flex
            vertical
            align="center"
            style={{
              width: '100%',
            }}
          >
            <Image
                width={60}
                src={CheckIcon}
                preview={false}
            />
          </Flex>

            <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>Account created!</Title>
            <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Text an invite to add a fellow creator or friend to your network.</Paragraph>

            <Form form={form} initialValues={{ blondehandle: user_name }} onFinish={onSubmit}>

              <Form.Item
                name="blondehandle"
                label="Share Link"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your tiktok username!' }]}
                className="custom-form-item"
              >
                <Input 
                  addonAfter={
                    <Button type="text" onClick={handleCopy}>
                        <Image 
                          width={18} 
                          src={CopyIcon} 
                          preview={false}
                        />
                        <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Copy Link</span>
                    </Button>} 
                  placeholder="Blonde handle" 
                  defaultValue={`${user_name}`}
                  className="copy-paste-input"
                  style={{ padding: '7px 0px' }} 
                  readOnly={true}
                />
              </Form.Item>

              <Paragraph>Email</Paragraph>
              <Form.List name="emails">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Flex key={key} className="custom-email" style={{ marginBottom: 10, width: '100%' }} align="baseline">
                              <Form.Item
                                  {...restField}
                                  name={[name]}
                                  fieldKey={[fieldKey]}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ type: 'email', message: 'Please input a valid email!' }]}
                                  className="custom-form-email"
                                  style={{ marginBottom: 0, width: '100%' }}
                              >
                                  <Input prefix={<MailOutlined />} placeholder="Enter email" className="custom-form-item" />
                              </Form.Item>
                              {fields.length > 2 && <MinusCircleOutlined onClick={() => remove(name)} />}
                          </Flex>
                      ))}
                      <Form.Item>
                          <Button type="link" onClick={() => add()} icon={<PlusOutlined />} style={{ fontWeight: 600, padding: 0 }}>
                              Add another
                          </Button>
                      </Form.Item>
                    </>
                  )}
              </Form.List>

              <Button type="primary" htmlType='submit' size="large" block style={{ fontWeight: 600, marginBottom: '20px' }}>
                Send Invites
              </Button>
        
              <Button size="large" onClick={handelSkip} block style={{ fontWeight: 600 }}>
                  Skip
              </Button>

            </Form>
            </Spin>
        </div>
    );
}

export default AccountCreated;
