import React,{useEffect,useState} from 'react';
import { Layout, Flex ,Spin} from 'antd';
import StepSignupForm from '../../../components/Audience/StepSignup';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { get } from '../../../utils/api';
const { Content } = Layout;

const AudienceSignup = () => {
    const { creatorId,phone } = useParams();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState(false);
    useEffect(()=>{
        const init=async()=>{
            try {
              setLoading(true);
              const response = await get(`auth/creatorInfo/${creatorId}`);
              if(response && response.data){
                setData(response.data);
              }
            }catch(error){
              console.log('error',error);
            }finally {
              setLoading(false);
            }
          };
          init();
      },[creatorId]);
    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#F2F2F7'}}>
            <Content style={{ padding: '16px 20px', flex: '0' }}>
                <Spin spinning={loading}>
                    <Flex vertical style={{ maxWidth: '416px', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                        <StepSignupForm data={data} phone={phone} creatorId={creatorId} />
                    </Flex>
                </Spin>
            </Content>
        </Layout>
    );
}

export default AudienceSignup;