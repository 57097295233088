import React, { useState,useEffect} from 'react';
import { Button, Layout, Col, Row, Flex, Typography, Table, Space, Divider, Dropdown, Menu, Modal, Image, Switch, Avatar } from 'antd';
import { PlusOutlined, EditOutlined, MoreOutlined, KeyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import EditPermissions from './EditPermissions';
import { get } from '../../utils/api';
import ShareIcon from "../../images/share-profile-icon.svg";
import EditIcon from "../../images/edit-icon.svg";
import AvatarImage from '../AvatarImage';

const { Title, Text } = Typography;

const Network = () => {
	const navigate = useNavigate();

  	const [selectedUser, setSelectedUser] = useState(null);
  	const [isModalVisible, setIsModalVisible] = useState(false);
  	const [isModalShareVisible, setIsModalShareVisible] = useState(false);
  	const [isEditPermissionsVisible, setIsEditPermissionsVisible] = useState(false);
	const [data,setData]=useState([]);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [loading, setLoading] = useState(false);

  	const [permissions, setPermissions] = useState({
	    accessToPrivateVault: false,
	    setFavorites: false,
	    schedulePosts: false,
	    createAndEditCommunities: false,
	    postPriceToContent: false,
	    shareInsightsAnalytics: false,
	    inviteAndRemoveFromTeamMembers: false,
	    deleteMessages: false,
	    inviteAndRemoveFromNetwork: false,
	    deleteContentFromVault: false,
	    editCreatorsProfile: false,
	    editAutomations: false,
		message:false,
		myNetwork:false
	 });

	 const fetchUsers = async (params = {}) => {
		setLoading(true);
		try {
		  const response = await get('creator/my-network', {
			_page: params.pagination?.current || 1,
			_limit: params.pagination?.pageSize || 10,
			q: params?.searchTerm ||'',
		  });
		  setData(response.data);
		  setPagination({
			...params.pagination,
			total: response.pagination.total,
		  });
		} catch (error) {
		  console.error('Error fetching users:', error);
		} finally {
		  setLoading(false);
		}
	  };

	  const handleTableChange = (pagination) => {
		fetchUsers({ pagination });
	  };

	 useEffect(()=>{
		fetchUsers();
	 },[]);

  	const showShareAccessModal = () => {
    	setIsModalShareVisible(true);
  	};

  	const handleShareAccessCancel = () => {
    	setIsModalShareVisible(false);
  	};

  	const handleShareAccess = () => {
  		setIsModalShareVisible(false);
  		setIsModalVisible(true);
  	};

  	const showModalGiveAccess = () => {
    	setIsModalShareVisible(true);
  	};

  	const showGiveAccess = (record) => {
    	setSelectedUser(record);
 	};

  	const handleGiveAccessConfirm = () => {
    	setIsModalVisible(false);
  	};

  	const handleGiveAccessCancel = () => {
    	setIsModalVisible(false);
  	};

  	const handleRemoveFromNetwork = (record) => {
    	// Logic to remove from network
    	console.log('Removed from network', record);
  	};

  	const handleToggle = (key) => {
    	setPermissions({ ...permissions, [key]: !permissions[key] });
  	};

  	const menu = (record) => (
	    <Menu>
	      <Menu.Item 
	      	icon={ 
		      	<Image
		            width={12}
		            src={EditIcon}
		            preview={false}
		        />
		    } onClick={showModalGiveAccess}><Text style={{ marginLeft: 8 }}>Give Access</Text></Menu.Item>
		    <Menu.Item 
	      	icon={ 
		      	<Image
		            width={12}
		            src={EditIcon}
		            preview={false}
		        />
		    } onClick={() => navigate(`/permissions/${record.key}`)}><Text style={{ marginLeft: 8 }}>Edit Access</Text></Menu.Item>
	      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleRemoveFromNetwork(record)}>Remove from network</Menu.Item>
	    </Menu>
	);

  	const columns = [
	    {
	      title: 'Name',
	      dataIndex: 'name',
	      key: 'name',
	      render: (text, record) =>{
			const imageId = record && record?.uploadedIds && record.uploadedIds.length > 0 ? record.uploadedIds[0] : null;
	        return (<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ marginRight: 10 }}>
			     <AvatarImage size={48} imageId={imageId} title={`${record?.first_name} ${record?.last_name}`}   />
				 </div>
				<div>
					<Text style={{ fontWeight: 600 }}>{record?.first_name} {record?.last_name}</Text>
				</div>
	        </div>)
		  },
	    },
	    {
	      title: 'Blonde Number',
	      dataIndex: 'assign_number',
	      key: 'assign_number',
	    },
	    {
	      title: 'Amount of Fans',
	      dataIndex: 'fans',
	      key: 'fans',
		  render: (text, record) =>{
				return record?.Users?.length || 0;
		  }
	    },
	];

  	return (
	    <div>
	    	{selectedUser ? (
		        <EditPermissions user={selectedUser} onBack={() => setSelectedUser(null)} />
		    ) : (
		        <>
			      <Flex className="head-inner" horizotanl justify="space-between">
			        <Flex vertical>
			          <Title level={4} style={{ fontSize: '18px' }}>My Network</Title>
			        </Flex>
			        {/* <Flex vertical>
			          <Button type="primary" size="large" icon={<PlusOutlined />} style={{ fontWeight: 600 }}>
			            Invite
			          </Button>
			        </Flex> */}
			      </Flex>
			      <Divider />

			      <Table className="my-network-table"
				   columns={columns}
				   rowKey={(record) => record.id}
				   dataSource={data}
				   pagination={pagination}
				   loading={loading}
				   onChange={handleTableChange}
				   />

			      <Modal
			        visible={isModalShareVisible}
			        onOk={handleShareAccess}
			        onCancel={handleShareAccessCancel}
			        width={600}
			       	className="share-modal"
			        footer={[
				        <Button key="cancel" size="large" onClick={handleShareAccessCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
				        <Button key="share" size="large" type="primary" onClick={handleShareAccess} style={{ fontWeight: 600 }}>Share Account</Button>,
				    ]}
			      >	
			      	<Image
			            width={48}
			            src={ShareIcon}
			            preview={false}
			            style={{ marginTop: '10px', marginBottom: '10px' }}
			        />
			      	<Title level={4} style={{ marginTop: 0, marginBottom: '5px' }}>Share Profile</Title>
			        <p style={{ marginTop: 0 }}>Share your account with other creators and organizations to help manage your blonde account.</p>

			        <Flex vertical style={{ marginTop: '40px', marginBottom: '25px' }}>
				        <Text level={4} style={{ fontWeight: 600 }}>Permissions</Text>
				        <Text level={4}>Toggle permissions on and off</Text>
			        </Flex>
			    	<Row gutter={24}>
				      {Object.keys(permissions).map((key) => (
			      		<Col className="gutter-row" span={12} key={key} style={{ marginBottom: '20px' }}>
			      			<Flex>
					          <Switch
					            checked={permissions[key]}
					            onChange={() => handleToggle(key)}
					          />
					          <span style={{ marginLeft: '10px', color: '#344054', fontWeight: 500, textTransform: 'capitalize' }}>{key.replace(/([A-Z])/g, ' $1')}</span>
					         </Flex>
				        </Col>
				      ))}
			        </Row>
			      </Modal>

			      <Modal
			        visible={isModalVisible}
			        onOk={handleGiveAccessConfirm}
			        onCancel={handleGiveAccessCancel}
			        width={400}
			        className="confirmation-modal"
			        footer={[
				        <Button key="cancel" size="large" onClick={handleGiveAccessCancel} style={{ fontWeight: 600 }}>Back</Button>,
				        <Button key="share" size="large" type="primary" onClick={handleGiveAccessConfirm} style={{ fontWeight: 600 }}>Confirm</Button>,
				    ]}
			      >
			      	<Title level={4}>You are about to sure?</Title>
			        <p>Are you sure you want to give this user access to your profile? You can change this later.</p>
			      </Modal>
			    </>
		    )}
	    </div>
	);
};

export default Network;
