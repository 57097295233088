import React,{useState} from 'react';
import { Form, Layout, Button, Typography, Image, Flex,message,Spin } from 'antd';
import { post } from '../../utils/api';
import PhoneIcon from '../../images/phone-icon.svg';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const VerifyStep = ({userEmail,setStep}) => {
  const [loading, setLoading]=useState(false);
  const onFinish =async (values) => {
    setLoading(true);
    try {
      const response = await post('auth/login-send-email',{
        "email":userEmail
    });
      if (response && response.status === 200) {
        message.success('OTP has been sent to your registered email.');
        setStep(2);
      } else {
        message.error(response.message || 'Login failed');
      }

    }catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    }finally {
      setLoading(false);
    }
  };

  return (

      <Content style={{ padding: '40px 0px' }}>
        <Spin spinning={loading}>
          <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
              <Flex
                vertical
                align="center"
                style={{
                  width: '100%',
                }}
              >
                <Image
                    width={60}
                    src={PhoneIcon}
                    preview={false}
                />
              </Flex>

              <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>Set up 2-step verification</Title>
              <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Enter the 2-step verification code we texted to your phone</Paragraph>

              <Form
                name="verifyStepOne"
                onFinish={onFinish}
                initialValues={{ remember: true }}
              >
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" block style={{ fontWeight: 600 }}>
                    Send code
                  </Button>
                </Form.Item>
              </Form>
              <Button size="large" onClick={()=>{setStep(3)}} block style={{ fontWeight: 600 }}>
                  Skip
              </Button>
          </Flex>
          </Spin>
      </Content>
  );
};

export default VerifyStep;