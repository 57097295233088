import React,{useState,useEffect} from 'react';
import { Layout, Row, Col, Flex, Avatar, Button, Divider, Switch, Typography,Modal,message,Spin } from 'antd';
import { RightOutlined, UserOutlined, DeleteOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import { createRoutesFromChildren, useParams } from 'react-router-dom';
import data from '../../data/data.json';
import { post } from '../../utils/api';
const { Title, Text } = Typography;
const {confirm}=Modal;
const EditPermissions = ({user,onBack}) => {
  const [isLoading,setIsLoding]=useState(false);
  const [permissions, setPermissions] = useState({
    accessToPrivateVault: false,
    setFavorites: false,
    schedulePosts: false,
    createAndEditCommunities: false,
    postPriceToContent: false,
    shareInsightsAnalytics: false,
    inviteAndRemoveFromTeamMembers: false,
   // deleteMessages: false,
   // inviteAndRemoveFromNetwork: false,
    deleteContentFromVault: false,
    editCreatorsProfile: false,
    editAutomations: false,
    message:false,
    myNetwork:false
  });
  useEffect(()=>{
    setPermissions(user?.permissions);
    
  },[user]);

  const handleToggle = (key) => {
    setPermissions({ ...permissions, [key]: !permissions[key] });
  };

  const updateRequest=async ()=>{
    setIsLoding(true);
    try{
   
      const formData={...user,permissions};
      const response = await post('creator/permissionsUpdate',formData);
      if(response && response.status){
        message.success('Permissions are updated successfully.');
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoding(false);
    }
  }

  const removeRequest=async ()=>{
    setIsLoding(true);
    try{
   
      const formData={...user,permissions};
      const response = await post('creator/permissionsRemove',formData);
      if(response && response.status){
        message.success('Permissions are removed successfully.');
        onBack();
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoding(false);
    }
  }
  const saveChanges=async()=>{
    try {
      confirm({
        title: 'Are you sure?',
        content: 'Are you sure you want to give this user access to your profile?',
        okText: 'Confirm',
        cancelText: 'Back',
        onOk:updateRequest,
        onCancel() {
          setIsLoding(false);
        },
      });
      // proceed with form submission or other logic
    } catch (error) {
      console.log('Validation failed:', error);
    }
  }
  const confrimRemove=async()=>{
    try {
      confirm({
        title: 'Are you sure?',
        content: 'Are you sure you want to remove this user access to your profile?',
       
        okText: 'Remove',
        okType:'danger',
        onOk:removeRequest,
        onCancel() {
          setIsLoding(false);
        },
      });
      // proceed with form submission or other logic
    } catch (error) {
      console.log('Validation failed:', error);
    }
  }

  return (
    <Layout style={{ padding: 30 }}>
      <Spin spinning={isLoading}>
      <Row className="permission-head" justify="space-between">
        <Col>
          <Title level={2}>Permissions</Title>
          <Flex gap={20} style={{ marginBottom: 10 }}>
            <Flex>
              <Avatar size={40} style={{ background: '#98A2B3' }}><UserOutlined /></Avatar>
            </Flex>
            <Flex>
              <Flex vertical>
                <Text style={{ fontWeight: 600 }}>{user?.name}</Text>
                <Text>{user?.email}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Col>
        <Col>
          <Button type="primary" onClick={onBack} icon={<ArrowLeftOutlined />}>Back</Button>
          <Button type="primary" onClick={confrimRemove} danger icon={<DeleteOutlined />}>Remove Access</Button>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={6}>
            <Flex vertical>
              <Text level={4} style={{ fontWeight: 600 }}>Permissions</Text>
              <Text>Toggle permissions on and off</Text> 
            </Flex>
        </Col>
        <Col span={1}></Col>
        <Col span={12}>
        <Flex gap={20} vertical>
		      {Object.keys(permissions).map((key) => (
	      	
	      			<Flex gap={10}>
			          <Switch
			            checked={permissions[key]}
			            onChange={() => handleToggle(key)}
			          />
			          <span style={{ marginLeft: '10px', color: '#344054', fontWeight: 500, textTransform: 'capitalize' }}>{key.replace(/([A-Z])/g, ' $1')}</span>
			         </Flex>
		      ))}
        </Flex>
        </Col>
      </Row>
     

      <Divider />

      <Flex
          horizotanl
          justify="end"
          gap="middle"
          style={{
            width: '100%',
            marginTop: '20px'
          }}
        >
        <Button onClick={saveChanges} type="primary" size="large" style={{ fontWeight: 600 }}>
          Save Changes
        </Button>
      
      </Flex>
      </Spin>
    </Layout>
  );
};

export default EditPermissions;
