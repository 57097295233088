import React,{useEffect,useState} from 'react';
import { Layout, List, Flex, Typography,Spin } from 'antd';
import { get } from '../../../utils/api';
import { formatPrice } from '../../../utils/utils';
const { Title, Text } = Typography;

const MyRequests = () => {
    const [myrequests,setMyrequests]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
  useEffect(()=>{
    const init=async()=>{
      setIsLoading(true);
      try{
        const response=await get('user/myrequests');
        if(response.status){
          const {rows,count}=response?.data;
          setMyrequests(rows);
        }
      }catch(error){
        console.log("error",error);
      }finally{
        setIsLoading(false);
      }
    }
    init();
  },[]);
  // const myrequests = [
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Pending' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Pending' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Delivered' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Delivered' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Pending' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Pending' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Delivered' },
  //   { creator: '{Creator Name}', request: '{Request name}', amount: '$10.00', status: 'Pending' }
  // ];

  return (
    <Layout style={{ padding: '20px 20px' }}>

      <Flex vertical align="start">
        <Title level={3} style={{ marginTop: 0, marginBottom: 15 }}>My Requests</Title>
      </Flex>
    <Spin spinning={isLoading}>
      <List
        dataSource={myrequests}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={<Text strong style={{ color: '#101828', fontSize: '18px' }}>{item?.User?.first_name} {item?.User?.last_name}</Text>}
              description={<Text>{item?.title}</Text>}
            />
            <Flex vertical style={{ textAlign: 'right' }}>
              <Text strong style={{ fontSize: '20px', color: '#000000' }}>{formatPrice(item?.price||0)}</Text>
              <span style={{ border: '1px solid #D0D5DD', borderRadius: '6px', padding: '2px 6px' }}>
              {item.status === 0 ? (
                <Text type="secondary" style={{ backgroundColor: '#FFFAEB', color: '#B54708', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #FEDF89', textTransform: 'capitalize' }}>Pending</Text>
              ) : item.status === 1 ?(
                <Text type="secondary" style={{ backgroundColor: '#ECFDF3', color: '#067647', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #ABEFC6', textTransform: 'capitalize' }}>Approved</Text>
              ) : (
                <Text type="secondary" style={{ backgroundColor: '#f1f1f1', color: '#9c9c9c', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #9c9c9c', textTransform: 'capitalize' }}>Decline</Text>
              )}
              </span>
            </Flex>
          </List.Item>
        
        )}
      />
      </Spin>

    </Layout>
  );
};

export default MyRequests;
