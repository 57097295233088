import React, { useContext, useState } from 'react';
import { Menu, Button, Flex,  Typography, Image, Drawer, message ,Modal} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import HamburgerIcon from "../../images/menu-icon.svg";
import AvatarImage from '../AvatarImage';
import './sidebar.css';

import {
  LogoutOutlined,
} from '@ant-design/icons';


const { Title,Text } = Typography;

const AudienceHeader = ({ menuItems = [] }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const {user}  = useContext(UserContext);
  
  const handleLogout = () => {
    setIsLogout(true);
  };

  const logoutHandel = () => {
    localStorage.removeItem('token');
    message.success('Logged out successfully!');
    setAuth(false);
    navigate('/');
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  const handleCancel = () => {
    setIsLogout(false);
  };

  return (<>
    <Flex className="head-mobile">
      <Flex horizontal="true">
        <Button type="link" onClick={showDrawer} style={{ margin: '13px 29px 13px', padding: 0 }}>
          <Image width={20} src={HamburgerIcon} preview={false} />
        </Button>
      </Flex>
    </Flex>

    <Drawer
      placement="left"
      width={310}
      onClose={onClose}
      open={open}
      className="mobile-drawer-sidebar"
    >
      <Flex horizontal="true" align="center" gap={15}>
      
         <AvatarImage size={40} title={`${user?.first_name} ${user?.last_name}`} Url={user?.profileUrl}/>
        <Flex vertical>
          <Text style={{ fontWeight: 600 }}>{user?.first_name} {user?.last_name}</Text>
          {user?.user_name && <Text>@{user?.user_name}</Text>}
        </Flex>
      </Flex>
      <Menu className="sidebar-menu main-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon} tr={item.path}>
            <Link to={item.path}  onClick={()=>{ setOpen(false);}}>
              <Text>{item.label}</Text>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <Menu className="sidebar-menu bottom-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="11" icon={<LogoutOutlined />} onClick={handleLogout}>
          <Text>Logout</Text>
        </Menu.Item>
      </Menu>
    </Drawer>
    <Modal keyboard={false} maskClosable={false} className="logout-confirm" open={isLogout} onOk={logoutHandel} onCancel={handleCancel} width={480} footer={[
        <Flex gap={10} justify="center" style={{ marginTop: 30, marginBottom: 20 }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={logoutHandel} 
          style={{ fontWeight: 600 }}
          >Yes, log me out</Button>
        </Flex>
      ]}>
        <Title level={4} style={{ textAlign: 'center' }}>Are you sure that you want to logout?</Title>
      </Modal>
 </>
 
);
};

export default AudienceHeader;
