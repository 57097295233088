import React, {useEffect,useState,useMemo,useContext,useRef } from 'react';
import { Layout, Button, Input, List, Typography, Avatar, Flex, Form, Spin, Image } from 'antd';
import { SearchOutlined, EditOutlined, ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { get,post } from '../../../utils/api';
import { Link } from 'react-router-dom';
import useScreenSize from '../../../utils/useScreenSize';
import NewMessage from '../../../components/GlobalComponents/NewMessage';
import ReplyMessage from '../../../components/Message/Audience/ReplyMessage';
import AvatarImage from '../../../components/AvatarImage';
import TimeAgo from '../../../components/TimeAgo';
import socket from '../../../services/socket';
import { UserContext } from '../../../contexts/UserContext';
import { cleanPhoneNumber,processHtmlContent } from '../../../utils/utils';
import SidebarFan from '../../Communities/SidebarFan';
import ChatInput from '../../../components/GlobalComponents/ChatInput';
import moment from 'moment';
import '../message.css';
import ArrowRight from "../../../images/arrow-right.svg";
import UnlockIcon from "../../../images/unlock-icon.svg";
import GroupUserIcon from "../../../images/user-icon-white.svg";
import hand from "../../../images/hand.svg"

const { Title, Text } = Typography;
const { Sider, Content } = Layout;
const { Search } = Input;

const Audience = () => {
  const [form] = Form.useForm();
  const { isMobile, isTablet } = useScreenSize();
  const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
  const [searchText, setSearchText] = useState('');  
  const [isReplyMessageVisible, setIsReplyMessageVisible] = useState(false);  
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [messagesData,setMessagesData] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);  
  const [isMessageSending, setIsMessageSendingg] = useState(false);  
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const {user}  = useContext(UserContext);
  const [selectedFan, setSelectedFan] = useState(false);
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const [setPrice, setInlinePrice] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [setSendButton, setInlineButton] = useState(true);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const fetchData = async (params = {}) => {
    const response = await get(`creator/inbox`,{
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 10,
      q: params?.searchTerm ||'',
    });
   
    if (response && response.data) {
      setMessagesData(response.data);
      setPagination({
        ...params.pagination,
        total: response.pagination.total,
      });
    }
  };
  const handleInputChange = (value) => {
    setMessage(value);
  };

  const handleClose = () => {
    setSelectedMessage(false);
  };

  useEffect(() => {
    if(selectedMessage){
      fetchData({searchTerm:searchText}); 
    }else{
      fetchData(); 
    }
  

    const handleNewMessage =async (message) => {
      if (message && message.status) {
        const { output } = message;

        if (cleanPhoneNumber(output?.creator_phone) === cleanPhoneNumber(user?.assign_number)) {
          setMessagesData(prevData => updateOrAppendMessage(prevData, output));

        }
       
        if(selectedMessage && output?.user_phone){
          const response = await post(`creator/chat`,{
            phn:output?.user_phone
          });
          
          if(response && response.status){
            setChatHistory(response?.data.reverse());
          }
        }
      }
    };

    socket.on('messageInbox', handleNewMessage);

    // Clean up on component unmount
    return () => {
      socket.off('messageInbox', handleNewMessage);
    };
  }, [user,selectedMessage]);


  const updateOrAppendMessage = (data, newMessage) => {
    // Check if the user_phone exists in the data array
    const index = data.findIndex(item => cleanPhoneNumber(item.user_phone) === cleanPhoneNumber(newMessage.user_phone));
    
    if (index !== -1) {
      // If user_phone exists, update the last_message
      data[index] = {
        ...data[index],
        last_message: newMessage.last_message,
        updatedAt: new Date().toISOString()
      };
    } else {
      // If user_phone does not exist, append the newMessage to the array
      data.push(newMessage);
    }
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    return [...data]; // Return a new array to trigger re-render
  };

  
  const handleSearch = (value) => {
    const searchvalue=value.target.value;
    setSelectedMessage(false);
    setSearchText(searchvalue);
    fetchData({searchTerm:searchvalue});
  };

  const showNewMessage = () => {
    setIsNewMessageVisible(true);
    setSelectedMessage(false);
    setIsReplyMessageVisible(false);
  };

  const handleNewMessageClose = () => {
    setIsNewMessageVisible(false);
    fetchData();
  };

  const handleReplyMessageClose = async() => {
    setIsReplyMessageVisible(false);
    if(selectedMessage){
      await handleSelectMessage(selectedMessage);
    }
    
  };  

  const handleSelectMessage = async(item) => {
     setSelectedMessage(item);
     setIsNewMessageVisible(false);
     setSelectedFan(false);
     setIsHistoryLoading(true);
     if(item && item?.type==="individual"){
            try{
                const response = await post(`creator/chat`,{
                  phn:item?.user_phone
                });
                if(response && response.status){
                  setChatHistory(response?.data.reverse());
                }
              }catch(error){
                console.log("loading......");
              } finally {
                setIsHistoryLoading(false);
            }
     }else{
      try{
        const response = await post(`creator/groupMessage`,{
            grpId:item?.id
          });
            if(response && response.status){
                const {groupLogs}=response?.data||{};
              setChatHistory(groupLogs.reverse());
            }
          }catch(error){
            console.log("loading......");
          } finally {
            setIsHistoryLoading(false);
        }
     } 
  };

  const sendMessageHandler = async (values) => {
    setIsMessageSendingg(true);
    try {
      const completeFormData = { ...values, phone_number: selectedMessage.user_phone,message,price:selectedPrice};
      const response = await post(`creator/sendMessage`, completeFormData);
      if (response && response.status) {
        form.resetFields();
        const UpdateData = [...chatHistory, response?.data];
        setChatHistory(UpdateData);
        setMessage('');
      }
    } catch (error) {
      console.log("loading......");
    } finally {
      setIsMessageSendingg(false);
    }
  };

  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(date).startOf('day');

    if (messageDate.isSame(today)) return 'Today';
    if (messageDate.isSame(yesterday)) return 'Yesterday';
    return messageDate.format('MMMM D, YYYY'); // Format as needed
  };

  // Helper function to determine if we need to show the date
  const shouldShowDate = (index, date) => {
    if (index === 0) return true; // Always show date for the first message
    const prevDate = moment(chatHistory[index - 1].updatedAt).startOf('day');
    const currDate = moment(date).startOf('day');
    return !prevDate.isSame(currDate); // Show date if different from the previous message
  };

  const getUserDetails=async()=>{
    setSelectedFan(true);
  }

  const handleCheckboxChange = (event, value) => {
    if (event.target.checked) {
      // Add the value to the array if the checkbox is checked
      setSelectedPhoneNumbers([...selectedPhoneNumbers, value]);
    } else {
      // Remove the value from the array if the checkbox is unchecked
      setSelectedPhoneNumbers(selectedPhoneNumbers.filter(item => item !== value));
    }
  };

  const layoutStyles = useMemo(() => ({
    backgroundColor: '#ffffff', 
    borderRight: '1px solid #EAECF0', 
    height: isMobile ? 'calc(100vh - 70px)' : '100vh', 
    display: 'flex', 
    flexDirection: 'column',
  }), [isMobile]);

  const selectedMessageStyles = useMemo(() => 
    isMobile ? { 
      backgroundColor: '#ffffff', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      display: 'flex', 
      flexDirection: 'column' 
    } : { 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column' 
    }
  , [isMobile]);


  const replyToAll=async()=>{
    setIsReplyMessageVisible(true);
    console.log("selectedMessage",selectedPhoneNumbers);
  }
  return (
    <Layout style={ isMobile ? "" : { minHeight: '100vh' }} className="message-screen">
      <Sider width={isMobile ? '100%' : isTablet ? 320 : 370} style={layoutStyles}>
        <div className="head-area" style={{ padding: '20px' }}>
          <Title level={2}>Messages</Title>
        </div>

        {/*<Flex  className="message-tabs">
          <Flex className="ant-tabs-nav">
            <Flex className="ant-tabs-nav-wrap">
              <Flex className="ant-tabs-nav-list">
                <Flex className="ant-tabs-tab ant-tabs-tab-active">
                  <Link to="/message/audience"><Text>Audience</Text></Link>
                </Flex>
                <Flex className="ant-tabs-tab">
                  <Link to="/message/network"><Text>Network</Text></Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>*/}

        <Flex gap={15} vertical style={{ paddingLeft: 20, paddingRight: 20, borderBottom: '1px solid #EAECF0' }}>
          <Button size="large" type="primary" style={{ textAlign: 'center', fontWeight: '600' }} icon={<EditOutlined />} onClick={showNewMessage}>New Message</Button>
          <Form.Item 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Search 
              className="custom-form-search-item search-no-icon"
              placeholder="Search audience messages" 
              onChange={handleSearch}
              prefix={<SearchOutlined />} 
              style={{ marginBottom: 0 }} 
              allowClear
            />
             
          </Form.Item>
        </Flex>
      
        {messagesData && messagesData.length>0 ?(
        <List
          itemLayout="horizontal"
          dataSource={messagesData}
          className="message-data"
          style={{ flex: 1, overflowY: 'auto' }}
          renderItem={(item) => (
            <List.Item onClick={() => handleSelectMessage(item)} style={{ cursor: 'pointer', background: item.id === selectedMessage?.id ? '#F9FAFB' : 'transparent', paddingLeft: 20, paddingRight: 20 }}>
              <List.Item.Meta
                avatar={item.type == 'group' ? <Avatar size={48} style={{ backgroundColor: '#98A2B3', padding: 10 }} src={<img src={GroupUserIcon} />} /> : <AvatarImage imageId={item.image} title={item.title}/>}
                title={
                  <Flex horizontal="true">
                    <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.title}</span> <span style={{ color: '#475467', marginLeft: 'auto', fontSize: '14px', fontWeight: 'normal', minWidth: '120px', textAlign: 'right' }}><TimeAgo timestamp={item.updatedAt}/></span>
                  </Flex>
                }
                // description={<div dangerouslySetInnerHTML={{ __html:item.last_message}}/> }
                description={processHtmlContent(item?.last_message)}
              />
            </List.Item>
          )}
        />):<>
            <Flex vertical style={{ padding: 20, flex: 1, overflowY: 'auto', borderTop: '1px solid #EAECF0' }}>
              <Title level={3} style={{ marginTop: 0 }}>Suggestions</Title>
              <Flex gap={20} align="center" style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '17px 20px', cursor: 'pointer' }} onClick={showNewMessage}>
                  <Image
                      width={36}
                      src={hand}
                      preview={false}
                  />
                  <Flex  vertical>
                  <Title level={5} style={{ margin: 0 }}>Send your first message!</Title>
                  <Text>Send them a message!</Text>
                </Flex>
                <RightOutlined />
              </Flex>
            </Flex>
        </>}

      </Sider>


      {selectedMessage &&
        <Content style={selectedMessageStyles}>
          <Flex vertical align="start" style={{ backgroundColor: '#F9FAFB', padding: '20px 25px', borderBottom: '1px solid #EAECF0' }}>
            {isMobile && 
              <Button
                type="text"
                icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                onClick={handleClose}
                style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
              >
              Back
              </Button>
            }
            <Flex>
              {selectedMessage.title !== selectedMessage?.user_phone && selectedMessage?.type === "individual" ? (
                <span onClick={getUserDetails} style={{ cursor: 'pointer' }}>
                  <Flex horizontal="true" gap="middle" align="center">
                    <AvatarImage title={selectedMessage.title} />
                    <Flex vertical>
                      <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                        {selectedMessage.title}
                      </Title>
                    </Flex>
                    {selectedMessage.title !== selectedMessage?.user_phone && (
                      <Image
                        width={20}
                        src={ArrowRight}
                        preview={false}
                      />
                    )}
                  </Flex>
                </span>
              ) : (
                <span>
                  <Flex horizontal="true" gap="middle" align="center">
                    <Avatar size={48} style={{ backgroundColor: '#7F56D9', padding: 10 }} src={<img src={GroupUserIcon} />} />
                    <Flex vertical>
                      <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                        {selectedMessage.title}
                      </Title>
                    </Flex>
                  </Flex>
                </span>
              )}
            </Flex>
          </Flex>

        
      {selectedMessage && selectedMessage?.type==="individual" ?
          <>
            <div className='message-box' style={{ padding: '20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isHistoryLoading}>
                  {chatHistory && chatHistory.map((message,index) => ( 
                     <Flex vertical key={message.id} style={{ marginBottom: '10px' }}>
                        {shouldShowDate(index, message.updatedAt) && (
                          <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                            {formatDate(message.updatedAt)}
                          </Text>
                        )}
                          {message?.direction===0?
                            <Flex justify="flex-start" className='fan-message'>
                              <Text style={{ fontSize: '17px', backgroundColor: '#E5E5EA', padding: '6px 14px', color: '#000000', borderRadius: 18, maxWidth: '75%' }}> 
                                  <div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} />
                                </Text>
                           
                            </Flex>
                            :
                            <Flex className="message-response" justify="flex-end">
                              {message?.price && message.price > 0 ? 
                                <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center', marginLeft: 'auto' }}>
                                    <Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px' }}>
                                     <Image
                                        width={56}
                                        src={UnlockIcon}
                                        preview={false}
                                        style={{ marginBottom: 20 }}
                                      />
                                      <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${message.price}</Button>
                                    </Flex>
                                    <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%' }}>
                                      <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                                      <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                                    </Flex>
                                  </Flex>
                                  :
                                  // <Text style={{ fontSize: '17px', backgroundColor: '#7F56D9', padding: '6px 14px', color: '#ffffff', borderRadius: 18, maxWidth: '75%' }}><div dangerouslySetInnerHTML={{ __html: message.message }} style={{ whiteSpace: 'pre-wrap' }} /></Text>}
                                  <Text style={{ maxWidth: '75%'  }}><div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} /></Text>}
                            </Flex>
                          }
                     </Flex>
                  ))}
                   <div ref={messagesEndRef} />
              </Spin>
            </div>
            <Flex vertical style={{ padding: '12px 20px' }}>
              <Spin spinning={isMessageSending}>
                <Form form={form} onFinish={sendMessageHandler} className="messagebox-form">
                  <Form.Item
                    name="messagebox"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: !message, message: 'Please input your message!' }]}
                    className="custom-form-item"
                  >
                     <ChatInput setSelectedPrice={setSelectedPrice}  onInputChange={handleInputChange} setPriceButton={setInlinePrice} setSendButton={setInlineButton} setLoading={isMessageSending} />
                  </Form.Item>
                  {/*<Button loading={isMessageSending} type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>Send</Button>*/}
                </Form>
              </Spin>
            </Flex>
          </>
          :
          <>
            <div className='message-box' style={{ padding: '20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isHistoryLoading}>
                  {chatHistory && chatHistory.map((message,index) => ( 
                     <Flex vertical key={message.id} style={{ marginBottom: '10px' }}>
                        {shouldShowDate(index, message.updatedAt) && (
                          <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                            {formatDate(message.updatedAt)}
                          </Text>
                        )}
                     
                          {message?.direction===0?
                            <Flex justify="flex-start" className='fan-message'>
                                <Flex>
                                  <Input type="checkbox" style={{ marginRight: '10px', width: '16px', marginTop: '24px' }}  onChange={(e) => handleCheckboxChange(e, message?.user_phone|| index)} />
                                </Flex>
                                <Flex vertical>
                                    {message?.user_name && <Text block style={{ marginBottom: 5 }}>{message.user_name}</Text>}
                                    <Flex horizontal gap={10}>
                                      <Text style={{ fontSize: '17px', backgroundColor: '#E5E5EA', padding: '6px 14px', color: '#000000', borderRadius: 18}}>
                                        <div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} />
                                      </Text>
                                      <RightOutlined/>
                                    </Flex>
                                </Flex>
                            </Flex>
                            :
                            <Flex className="message-response" justify="flex-end">
                              {message?.price && message.price > 0 ? 
                                <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center', marginLeft: 'auto' }}>
                                  <Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px' }}>
                                   <Image
                                      width={56}
                                      src={UnlockIcon}
                                      preview={false}
                                      style={{ marginBottom: 20 }}
                                    />
                                    <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${message.price}</Button>
                                  </Flex>
                                  <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%' }}>
                                    <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                                    <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                                  </Flex>
                                </Flex>
                                :
                                <Text style={{ fontSize: '17px', backgroundColor: '#7F56D9', padding: '6px 14px', color: '#ffffff', borderRadius: 18, maxWidth: '75%' }}><div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} /></Text>}
                            </Flex>
                          }
                     </Flex>
                  ))}
                   <div ref={messagesEndRef} />
              </Spin>
            </div>
              <Flex horizontal justify="end" style={{ padding: '12px 20px', borderTop: '1px solid #EAECF0' }}>
                <Button loading={isMessageSending} type="primary" onClick={replyToAll} size="large" style={{ fontWeight: 600 }}>Reply to all</Button>
              </Flex>
          </>
          }
        </Content>
      }

      {isNewMessageVisible &&
        <NewMessage setSelectedPrice={setSelectedPrice} visible={isNewMessageVisible} handleCancel={handleNewMessageClose} />
      }

      {isReplyMessageVisible &&
        <ReplyMessage setSelectedPrice={setSelectedPrice} visible={isReplyMessageVisible} handleCancel={handleReplyMessageClose} replyTo={selectedPhoneNumbers} selectedMessage={selectedMessage} />
      }
      
      {selectedFan && <SidebarFan selectedUser={selectedMessage} selectedFan={selectedFan} setSelectedFan={setSelectedFan} setIsLoading={setIsHistoryLoading} />}
    </Layout>
  );
};

export default Audience;
