// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-head-area {
    padding: 20px 30px;
    border-bottom: 1px solid #EAECF0;
}
.notification-head-area h4 {
    margin: 0;
}
.notification-list {
    padding-top: 5px;
}
.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-content {
  display: flex;
  flex-direction: column;
}
.notification-actions {
  display: flex;
  align-items: center;
}
.notification-list li.ant-list-item {
    border: none;
    padding: 15px 30px;
    position: relative;
}
.notification-list li.ant-list-item:before {
    content: "";
    background: #EAECF0;
    width: 2px;
    height: 16px;
    position: absolute;
    left: 52px;
    top: 70px;
    border-radius: 5px;
}
.notification-list li.ant-list-item:last-child:before{
    display: none;
}
.notification-top-head .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (max-width: 767px){
    .notification-head-area {
        padding: 20px 20px;
    }
    .notification-list li.ant-list-item {
        padding: 15px 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/NotificationCenter/notification.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gCAAgC;AACpC;AACA;IACI,SAAS;AACb;AACA;IACI,gBAAgB;AACpB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".notification-head-area {\n    padding: 20px 30px;\n    border-bottom: 1px solid #EAECF0;\n}\n.notification-head-area h4 {\n    margin: 0;\n}\n.notification-list {\n    padding-top: 5px;\n}\n.notification-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.notification-content {\n  display: flex;\n  flex-direction: column;\n}\n.notification-actions {\n  display: flex;\n  align-items: center;\n}\n.notification-list li.ant-list-item {\n    border: none;\n    padding: 15px 30px;\n    position: relative;\n}\n.notification-list li.ant-list-item:before {\n    content: \"\";\n    background: #EAECF0;\n    width: 2px;\n    height: 16px;\n    position: absolute;\n    left: 52px;\n    top: 70px;\n    border-radius: 5px;\n}\n.notification-list li.ant-list-item:last-child:before{\n    display: none;\n}\n.notification-top-head .title {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n@media (max-width: 767px){\n    .notification-head-area {\n        padding: 20px 20px;\n    }\n    .notification-list li.ant-list-item {\n        padding: 15px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
