const data_1 = [
  { Fans: 60, name: "01:00" },
  { Fans: 55, name: "02:00" },
  { Fans: 60, name: "03:00" },
  { Fans: 55, name: "04:00" },
  { Fans: 33, name: "05:00" },
  { Fans: 55, name: "06:00" },
  { Fans: 55, name: "07:00" },
  { Fans: 33, name: "08:00" },
  { Fans: 55, name: "09:00" },
  { Fans: 33, name: "10:00" },
  { Fans: 55, name: "11:00" },
  { Fans: 76, name: "12:00" },
  { Fans: 24, name: "13:00" },
  { Fans: 55, name: "14:00" },
  { Fans: 54, name: "15:00" },
  { Fans: 55, name: "16:00" },
  { Fans: 45, name: "17:00" },
  { Fans: 77, name: "18:00" },
  { Fans: 23, name: "19:00" },
  { Fans: 55, name: "20:00" },
  { Fans: 65, name: "21:00" },
  { Fans: 85, name: "22:00" },
  { Fans: 80, name: "23:00" },
  { Fans: 80, name: "24:00" },
];

const data_2 = [
  { Fans: 540, name: "Mon" },
  { Fans: 520, name: "Tue" },
  { Fans: 600, name: "Wed" },
  { Fans: 620, name: "Thu" },
  { Fans: 690, name: "Fri" },
  { Fans: 701, name: "Sat" },
  { Fans: 756, name: "Sun" },
];

const data_3 = [
  { Fans: 345, name: "1" },
  { Fans: 633, name: "2" },
  { Fans: 343, name: "3" },
  { Fans: 353, name: "4" },
  { Fans: 675, name: "5" },
  { Fans: 644, name: "6" },
  { Fans: 464, name: "7" },
  { Fans: 456, name: "8" },
  { Fans: 756, name: "9" },
  { Fans: 745, name: "10" },
  { Fans: 254, name: "11" },
  { Fans: 934, name: "12" },
  { Fans: 349, name: "13" },
  { Fans: 394, name: "14" },
  { Fans: 845, name: "15" },
  { Fans: 493, name: "16" },
  { Fans: 394, name: "17" },
  { Fans: 691, name: "18" },
  { Fans: 953, name: "19" },
  { Fans: 956, name: "20" },
  { Fans: 342, name: "21" },
  { Fans: 945, name: "22" },
  { Fans: 606, name: "23" },
  { Fans: 645, name: "24" },
  { Fans: 436, name: "25" },
  { Fans: 325, name: "26" },
  { Fans: 902, name: "27" },
  { Fans: 646, name: "28" },
  { Fans: 785, name: "29" },
  { Fans: 239, name: "30" },
];

const data_4 = [
  { Fans: 500, name: "Jan" },
  { Fans: 510, name: "Fab" },
  { Fans: 520, name: "March" },
  { Fans: 540, name: "April" },
  { Fans: 560, name: "May" },
  { Fans: 585, name: "June" },
  { Fans: 595, name: "July" },
  { Fans: 610, name: "Aug" },
  { Fans: 610, name: "Sept" },
  { Fans: 630, name: "Oct" },
  { Fans: 650, name: "Nov" },
  { Fans: 651, name: "Dec" },
];

 const dataSet = {
  Today: data_1,
  Last_7_days: data_2,
  Last_30_days: data_3,
  Last_12_months: data_4,
};

export default dataSet