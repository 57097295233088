import { List ,Flex,Typography} from "antd";
import AvatarImage from "../AvatarImage";

const { Text, Title, Paragraph } = Typography;
const PrivateRequestFillter=({setSelectedRequest,requestsData,isMobile,selectedRequest})=>{

    return (<List
        itemLayout="horizontal"
        dataSource={requestsData}
        style={isMobile ? {} : { flex: 1, overflowY: 'auto' }}
        renderItem={item => (
          <List.Item
            style={{ cursor: 'pointer', padding: '13px 20px', background: item.id === selectedRequest?.id ? '#F9FAFB' : 'transparent' }}
            onClick={() => setSelectedRequest(item)}
          >
            <List.Item.Meta
              avatar={<AvatarImage title={item?.to_name} size={48} style={{ backgroundColor: '#98A2B3', fontWeight: 600, fontSize: '18px' }}/>}
              title={<Text strong>{item?.to_name}</Text>}
              description={<Text>{item?.title}</Text>}
            />
            <Flex vertical align="end">
              <Text type="secondary" style={{ color: '#475467', marginBottom: 5 }}>{item.time}</Text>
              {item.status === 0 ? (
                <Text type="secondary" style={{ backgroundColor: '#FFFAEB', color: '#B54708', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #FEDF89', textTransform: 'capitalize' }}>Pending</Text>
              ) : item.status === 1 ?(
                <Text type="secondary" style={{ backgroundColor: '#ECFDF3', color: '#067647', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #ABEFC6', textTransform: 'capitalize' }}>Approved</Text>
              ) : (
                <Text type="secondary" style={{ backgroundColor: '#f1f1f1', color: '#9c9c9c', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #9c9c9c', textTransform: 'capitalize' }}>Decline</Text>
              )}
            </Flex>
          </List.Item>
        )}
      />);
}

export default PrivateRequestFillter;


