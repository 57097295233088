// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-tabs .ant-tabs-content .ant-card-body {
    padding: 15px 18px;
}

.billing-tabs .ant-tabs-nav {
    background: #F9FAFB;
    padding: 5px;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    margin-bottom: 20px;
}
.billing-tabs .ant-tabs-nav::before {
    display: none;
}
.billing-tabs .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 17px;
    margin: 0;
    border: none;
    color: #667085;
    font-weight: 600;
    font-size: 14px;
    text-shadow: 0 0 !important;
    width: 50%;
    justify-content: center;
}
.billing-tabs .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
}
.billing-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    width: 100%;
}
.billing-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 3px 3px rgb(16 24 40 / 10%);
}
.billing-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
}
.billing-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #344054;
}

.custom-form-item.billing-email input.ant-input {
    min-height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Audience/Billing/Billing.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,SAAS;IACT,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,2BAA2B;IAC3B,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,2CAA2C;AAC/C;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".billing-tabs .ant-tabs-content .ant-card-body {\n    padding: 15px 18px;\n}\n\n.billing-tabs .ant-tabs-nav {\n    background: #F9FAFB;\n    padding: 5px;\n    border-radius: 12px;\n    border: 1px solid #EAECF0;\n    margin-bottom: 20px;\n}\n.billing-tabs .ant-tabs-nav::before {\n    display: none;\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-tab {\n    padding: 8px 17px;\n    margin: 0;\n    border: none;\n    color: #667085;\n    font-weight: 600;\n    font-size: 14px;\n    text-shadow: 0 0 !important;\n    width: 50%;\n    justify-content: center;\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-ink-bar {\n    display: none;\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {\n    width: 100%;\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {\n    background: #ffffff;\n    border-radius: 6px;\n    box-shadow: 0px 3px 3px rgb(16 24 40 / 10%);\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-nav-wrap {\n    overflow: visible;\n}\n.billing-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: #344054;\n}\n\n.custom-form-item.billing-email input.ant-input {\n    min-height: 34px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
