import React,{useState,useEffect} from 'react';
import {Avatar,Image} from "antd";
import {  UserOutlined} from '@ant-design/icons';
import { imageUrl,getInitials,getImageUrl } from '../utils/utils';
const AvatarImage=({imageId,title,size,font,Url=null})=>{
    const [imagesrc, setImagesrc] = useState(null);
    useEffect(() => {
        const init = async () => {
            if(Url){
                const rsp=await getImageUrl(Url);
                setImagesrc(rsp); 
            }else{
                const rsp=await imageUrl(imageId);
                if(rsp){
                    setImagesrc(rsp); 
                }
            }
            
        };
        init(); // Call the initialization function immediately
      }, [imageId,Url]);
    return ( <>
            {imagesrc  ? <Avatar key={imageId} size={size ? size : 64} src={imagesrc} />:
            <Avatar size={size ? size : 48} style={{ backgroundColor: '#98A2B3', fontWeight: 600 }}>{title ? getInitials(title):<UserOutlined />}</Avatar>}
        </>);
}

export default AvatarImage;