import React from 'react';
import StepForm from '../components/StepFormSignup';
import { Layout, Flex } from 'antd';
import useScreenSize from '../utils/useScreenSize';

const { Content } = Layout;

const Signup = () => {
    const { isMobile } = useScreenSize();
    return (
        <Content style={{ padding: isMobile ? '15px 0' : '40px 0px' }}>
            <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
                <StepForm />
            </Flex>
        </Content>
    );
}

export default Signup;
