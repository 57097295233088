import { Layout, Menu, Image, Flex, Typography, Tabs, Table, Avatar, Space, Button, Dropdown } from 'antd';
import { DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';
import data from '../../../data/data.json';

import './network.css';

import EditIcon from "../../../images/edit-icon.svg";

const { Title, Text } = Typography;


const Network = () => {

	const handleRemoveFromNetwork = (record) => {
    	// Logic to remove from network
    	console.log('Removed from network', record);
  	};

	const menu = (record) => (
	    <Menu>
	      <Menu.Item icon={<DeleteOutlined />} onClick={() => handleRemoveFromNetwork(record)}>Leave network</Menu.Item>
	    </Menu>
	);

	const columns = [
	    {
	      title: 'Name',
	      dataIndex: 'name',
	      key: 'name',
	      render: (text, record) => (
	        <div style={{ display: 'flex', alignItems: 'center' }}>
	          <Avatar src={record.image} size="large" style={{ marginRight: 10 }} />
	          <div>
	            <Text style={{ fontWeight: 600 }}>{text}</Text>
	            <br />
	            <Text type="secondary">{record.handle}</Text>
	          </div>
	        </div>
	      ),
	    },
	    {
	      title: 'Blonde Number',
	      dataIndex: 'number',
	      key: 'number',
	    },
	    {
	      title: 'Amount of Fans',
	      dataIndex: 'fans',
	      key: 'fans',
	    },
	    {
	      title: 'Insights',
	      key: 'ins',
	      render: (_, record) => (
	        <Space size="middle">
	          <Button type="text" style={{ color: '#6941C6', fontWeight: 600 }}>View</Button>
	        </Space>
	      ),
	    },
	    {
	      title: '',
	      key: 'action',
	      render: (_, record) => (
	        <Space size="middle">
	          <Button type="text" icon={<EditOutlined />} />
	          <Dropdown overlay={menu(record)} trigger={['click']}>
	            <Button type="text" icon={<MoreOutlined />} />
	          </Dropdown>
	        </Space>
	      ),
	    },
	];


	return(
		<Layout style={{ padding: 30 }}>
			<Flex className="content-head-area" horizontal justify="space-between" align="center">
		    	<Title level={2} style={{ marginBottom: '30px' }}>Network</Title>
		    </Flex>

		    <Table className="network-table" columns={columns} dataSource={data} />

		</Layout>
	);
};

export default Network;