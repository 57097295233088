import React,{useEffect,useState} from 'react';
import { Form, Input, Typography, Button, Flex, message, Upload, Spin, Image } from 'antd';
import { UploadOutlined, CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useParams,useNavigate } from 'react-router-dom';
import { get, put,post } from '../../../utils/api';
import Logo from "../../../images/logo.svg";
import LogoMob from "../../../images/Logoicon.svg";
import CodeInput from '../../CodeInput';
import AvatarImage from '../../AvatarImage';

import '../../Welcome/welcome.css';
const { Title, Paragraph, Text } = Typography;
const { Dragger } = Upload;
const Welcome = () => {
    const [ form ] = Form.useForm();
    const navigate = useNavigate();
    const [ loading, setLoading ]=useState(true);
    const [ loginValue, setLoginValue ]=useState(null);
    const [ step, setStep ]=useState(0);
    const [ assignNumber, setAssignNumber ]=useState(null);
    const [ assignedUrl, setAssignedUrl ]=useState(null);
    const [ uploadedIds, setUploadedIds ] = useState([]);
    const [profileImage,setProfileImage]=useState(null);
    const [ code, setCode ] = useState('');
    const AppUrl=process.env.REACT_APP_APPLICATION_URL;
    const { creatorId,teamEmail } = useParams();
    const [data,setData]=useState(false);
   
 

    useEffect(()=>{
        const init=async()=>{
            try {
              setLoading(true);
              const response = await get(`auth/creatorInfo/${creatorId}`);
             
              if(response && response.data){
                setData(response.data);
              }
            }catch(error){
              console.log('error',error);
            }finally {
              setLoading(false);
            }
          };
          init();
          console.log("teamEmail",teamEmail,"creatorId",creatorId);
      },[creatorId]);


      useEffect(()=>{
        const checkUser=async()=>{
          const responsecheck = await get(`teams/checkTeam/${creatorId}/${teamEmail}`);
           if (responsecheck && responsecheck?.status) {
                message.error('This link has expired. If you have already signed up, please log in.');
               
                 navigate(`/`);
                  
              }
        }
        checkUser();
      },[creatorId,teamEmail]);


    const verifictionHandller=async(values)=>{
      const currentStepData = await form.validateFields();
    
      if(currentStepData){
        setLoading(true);
        try{
          const otpCode = code.join("");
          const response = await post('auth/teamCheckOtp',{otp:otpCode,creatorId,teamEmail});
            if (response && response.available) {
              if(response?.existingUser){
                message.success('Your account has been successfully connected to this creator profile. Please log in to your account.');
                navigate(`/`);
              }else{
                navigate(`/team/signup/${creatorId}/${teamEmail}`);
              }
             
            }else {
              message.error(response.message || 'The code you entered is incorrect. Please try again.');
            }
        }catch(error){
          if (error.response && error.response.data) {
            message.error(error.response.data.message || 'The code you entered is incorrect. Please try again.');
          } else {
            message.error(error.message || 'The code you entered is incorrect. Please try again.');
          }
        }finally {
          setLoading(false);
        }
        
      }
    }

    const imageId = data && data.uploadedIds && data.uploadedIds.length > 0 ? data.uploadedIds[0] : null;

    return (
      <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
          <Flex style={{ paddingBottom: 20 }} justify="center">
              <div className="logo">
                <Link to="/">
                    <Image width={100} src={Logo} preview={false} />
                </Link>
              </div>
          </Flex>
          <div className="setpfrm">
            <Flex vertical align="center" style={{ marginTop: 50 }}>
              <AvatarImage imageId={imageId} size={64} title={`${data?.first_name} ${data?.last_name}`} />
              <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px' }}>{data?.first_name} {data?.last_name}</Title>
              

              <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600', marginTop: 0 }}>Welcome to Blonde!</Title>
              <Paragraph style={{ marginBottom: 40 }}>Submit your invitation code from Creator {data?.user_name} </Paragraph>
            </Flex>
            <Form
              name="verificationStepOne"
              form={form}
              onFinish={verifictionHandller}
            >
              <CodeInput setCode={setCode} />
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600, display: 'block', margin: '0 auto' }}>
                  Submit
                </Button>
              </Form.Item>

            </Form>

          </div>

      </Flex>
    );
}

export default Welcome;
