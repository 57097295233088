import React from 'react';
import { Layout, Col, Row, Flex } from 'antd';
import AccountCreated from '../components/AccountCreated';

const { Content } = Layout;

const AccountCreatedPage = () => {
    return (
        <Content style={{ padding: '40px 50px' }}>
            <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
                <AccountCreated />
            </Flex>
        </Content>
    );
}

export default AccountCreatedPage;
