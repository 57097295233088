import React, { useState, useEffect } from 'react';
import { Input, Col, Form } from 'antd';
import './CodeInput.css';

const CodeInput = ({ setCode }) => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [form] = Form.useForm();

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      form.setFieldsValue({ [`otp-${index}`]: value });
      if (value !== '' && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      if (otp[index] === '') {
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      } else {
        newOtp[index] = '';
        setOtp(newOtp);
        form.setFieldsValue({ [`otp-${index}`]: '' });
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 4);
    if (/^\d{4}$/.test(pastedData)) {
      const newOtp = pastedData.split('');
      setOtp(newOtp);
      newOtp.forEach((value, index) => {
        form.setFieldsValue({ [`otp-${index}`]: value });
      });
      document.getElementById('otp-input-3').focus();
    }
  };

  useEffect(() => {
    setCode(otp);
  }, [otp, setCode]);

  return (
    <Form form={form} className="code-input-container" onPaste={handlePaste}>
      {otp.map((val, index) => (
        <Col key={index}>
          <Form.Item
            name={`otp-${index}`}
            rules={[{ required: false, message: '' }]}
            style={{ marginBottom: 0 }}
          >
            <Input
              id={`otp-input-${index}`}
              placeholder="-"
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
              style={{ width: '40px', textAlign: 'center' }}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="code-input"
            />
          </Form.Item>
        </Col>
      ))}
    </Form>
  );
};

export default CodeInput;