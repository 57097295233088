import React from 'react';
import { Row, Col, Image } from 'antd';

const ImagesGallery = () => {
  const images = [
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
    'https://via.placeholder.com/500',
  ];

  return (
    <div horizontal style={{ padding: '20px' }}>
      <Row gutter={[10, 10]}>
        {images.map((src, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Image
              src={src}
              alt={`Gallery image ${index + 1}`}
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ImagesGallery;
