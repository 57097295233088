import React from "react";
import { createUseStyles } from "react-jss";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";

const useStyles = createUseStyles(() => ({
  container: {
    color: "#fff",
    backgroundColor: "rgb(255, 255, 255)",
    padding: "0",
    transition: "0.3s ease-in-out",
    width: "100%",
    height: "400px",
  },
}));

const GradientColors = () => {
  return (
    <linearGradient id="colorView" x1="0" y1="0" x2="0" y2="1">
      <stop offset="20%" stopColor="#7F56D9" stopOpacity={0.4} />
      <stop offset="75%" stopColor="#ff9bff81" stopOpacity={0.3} />
      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.2} />
    </linearGradient>
  );
};

 const MyGraph = ({ data,isFormated=false }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart  data={data} >
          <defs>
            <GradientColors />
          </defs>
          {isFormated? <Tooltip
            itemStyle={{ color: "#fff", backgroundColor: "#0A1322" }}
            contentStyle={{ backgroundColor: "#0A1322" }}
            formatter={(value) => [`$${value}`, 'Amount']} 
          />:<Tooltip
          itemStyle={{ color: "#fff", backgroundColor: "#0A1322" }}
          contentStyle={{ backgroundColor: "#0A1322" }}
        />}
         
          <CartesianGrid stroke="#8884d8" opacity={0.1} />
          <XAxis dataKey="name" tick={{ fill: "#B6BAC3" }} stroke="#EEEEEE" />
          <YAxis tick={{ fill: "#B6BAC3" }} stroke="#EEEEEE" />
          <Area
            dataKey="Fans"
            type="monotone"
            stroke="#7F56D9"
            strokeWidth={3}
            strokeOpacity={1}
            fill="url(#colorView)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MyGraph;
