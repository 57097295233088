// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-request-modal .ant-modal-content {
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Audience/CustomRequest/CustomRequest.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd","sourcesContent":[".custom-request-modal .ant-modal-content {\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
