import React, { useState } from 'react';
import { Layout, Flex, Collapse, Divider, Typography } from 'antd';

import Icon1 from '../../../images/visa-icon.png';
import Icon2 from '../../../images/mastercard-icon.png';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const Faq = () => {
  const faqData = [
    {
      question: 'What is Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      question: 'Why do we use it?',
      answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
    {
      question: 'Where can I get some?',
      answer: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
    },
  ];

  return (
    <Layout style={{ padding: '20px 20px' }}>
      <Flex vertical align="start">
        <Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>FAQ</Title>
        <Divider />
        <Collapse className="accordion-panel" accordion>
          {faqData.map((item, index) => (
            <Panel header={item.question} key={index}>
              <Text>{item.answer}</Text>
            </Panel>
          ))}
        </Collapse>
      </Flex>
    </Layout>
  );
};

export default Faq;
