import React,{useEffect,useState} from 'react';
import { Form, Input, Typography, Button, Flex, message, Upload, Avatar, Spin, Image } from 'antd';
import { UploadOutlined, CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import useScreenSize from '../../utils/useScreenSize';
import { get, put,post } from '../../utils/api';
import { getInitials,extractImageIds,imageUrl } from '../../utils/utils';
import VerifyStep from '../VerifyStep/VerifyStep';
import PhoneIcon from '../../images/phone-icon.svg';
import UploadIcon from '../../images/upload-icon.svg';
import CopyIcon from "../../images/copy-icon.svg";
import ShareIcon from "../../images/share-icon.svg";
import CheckSMIcon from "../../images/check-sm.svg";
import Logo from "../../images/logo.svg";
import LogoMob from "../../images/Logoicon.svg";
import CodeInput from '../CodeInput';
import AccountCreated from '../AccountCreated';

import './welcome.css';
const { Title, Paragraph } = Typography;
const { Dragger } = Upload;
const Welcome = () => {
    const { isMobile } = useScreenSize();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ]=useState(true);
    const [ username, setUsername ]=useState(null);
    const [ userEmail, setUserEmail ]=useState(null);
    const [ loginValue, setLoginValue ]=useState(null);
    const [ step, setStep ]=useState(0);
    const [ assignNumber, setAssignNumber ]=useState(null);
    const [ assignedUrl, setAssignedUrl ]=useState(null);
    const [ uploadedIds, setUploadedIds ] = useState([]);
    const [profileImage,setProfileImage]=useState(null);
    const [ code, setCode ] = useState('');
    const AppUrl=process.env.REACT_APP_APPLICATION_URL;
    const { token } = useParams();
    console.log(assignNumber, 'assignNumber')

    const resendOtp= async()=>{
     
      setLoading(true);
      try {
        const response = await post('auth/login-send-email',{
          "email":userEmail
      });
        if (response && response.status === 200) {
          message.success('OTP has been sent to your registered email.');
          setStep(2);
        } else {
          message.error(response.message || 'Login failed');
        }
  
      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    }

    const submitHandller = async(values) => {
     
      const data = { ...values, uploadedIds,token };
     
      setLoading(true);
      try {
        const response = await put('auth/updateProfile',data);
        if (response && response.status === 200) {
          const usetToken = response.usetToken;
          const {email}=response?.data;
          setUserEmail(email);
          setLoginValue({email:email,password:values?.password});
          localStorage.setItem('token', usetToken);
          setStep(1);
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    const verifictionHandller=async(values)=>{
      const currentStepData = await form.validateFields();
    
      if(currentStepData){
        setLoading(true);
        try{
          const otpCode = code.join("");
          const response = await post('auth/login-verify-email',{otp:otpCode,loginValue});
            if (response && response.status === 200) {
              const token = response.token;
              localStorage.setItem('token', token);
              setStep(3);
            }else {
              message.error(response.message || 'The code you entered is incorrect. Please try again.');
            }
        }catch(error){
          if (error.response && error.response.data) {
            message.error(error.response.data.message || 'The code you entered is incorrect. Please try again.');
          } else {
            message.error(error.message || 'The code you entered is incorrect. Please try again.');
          }
        }finally {
          setLoading(false);
        }
        
      }
    }

    useEffect(()=>{
        const init=async()=>{
          try {
            const response = await get(`auth/welcome/${token}`);
            if(response){
              const {user_name,assign_number}=response.data;
              setUsername(user_name);
              const cleanedUrl = AppUrl.replace(/^https?:\/\//, '');
              setAssignedUrl(`${cleanedUrl}/${user_name}`);
              console.log(assign_number, 'assignNumber')
              if(assign_number){
                setAssignNumber(`+1 ${assign_number}`);
              }
              
            }
          }catch (error) {
            if (error.response && error.response.data) {
              message.error(error.response.data.message);
            } else {
              message.error(error.message);
            }
          }finally {
            setLoading(false);
          }
        }
        init();
    },[token,AppUrl]);

     useEffect(() => {
      if (assignNumber) {
        form.setFieldsValue({ blonephone: assignNumber });
      }
    }, [assignNumber, form]);

    const handleCopy = () => {
      const inputValue = form.getFieldValue('blonephone');
      console.log(inputValue, 'inputValue');
      navigator.clipboard.writeText(inputValue).then(() => {
        message.success('Copied to clipboard!');
      }).catch(err => {
        message.error('Failed to copy!');
      });
    };

    return (
        <div>
        <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
        <Spin spinning={loading}>
         {step===0 && (<>
            <Flex style={{ paddingBottom: 20 }} justify="center">
                <div className="logo">
                  <Link to="/">
                    {isMobile ?
                      <Image width={40} src={LogoMob} preview={false} />
                    : 
                      <Image width={100} src={Logo} preview={false} />
                    }
                    </Link>
                </div>
            </Flex>
            <div className="setpfrm">
            <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600', marginTop: isMobile ? '0px' : '' }}>Welcome to Blonde!</Title>
            <Form form={form} initialValues={{ blonephone: assignNumber }} onFinish={submitHandller} className="welcome-form">

                <div className="avatar-container">
                  {profileImage? <Avatar size={165} src={profileImage} />: <Avatar size={96} className="rounded-avatar">
                    {getInitials(username)}
                  </Avatar>}
                 
                </div>

                <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>Your blonde username <strong>{username}</strong></Paragraph>

                <Dragger
                  multiple
                  name="singleImage"
                  action={`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`}
                  onChange={async (info)=>{
                    const { status } = info.file;
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                      message.success(`${info.file.name} file uploaded successfully.`);
                    } else if (status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                    const {fileList}=info;
                    const getIds=extractImageIds(fileList);
                    if(getIds){
                      const url=await imageUrl(getIds[0]);
                      setProfileImage(url);
                      setUploadedIds(getIds);
                    }
                  }}
                >
                    <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
                      <Image src={UploadIcon} size={40} preview={false} />
                    </p>
                    <p className="ant-upload-text" style={{ fontSize: '14px' }}><span style={{ fontWeight: 600, color: '#6941C6' }}>Click to upload</span> or drag and drop</p>
                    <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                </Dragger>
                {assignedUrl && 
                <Form.Item
                  name="blondehandle"
                  label="Blonde handle"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your tiktok username!' }]}
                  className="custom-form-item"
                  initialValues={assignedUrl}
                >
                  <Input 
                    addonAfter={
                      <Button type="text">
                          <Image 
                            width={16} 
                            src={ShareIcon} 
                            preview={false}
                          />
                          <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Share</span>
                      </Button>} 
                    placeholder="Blonde handle" 
                    style={{ padding: '7px 0px' }} 
                    className="share-input"
                    defaultValue={assignedUrl}
                    readOnly={true}
                  />
                </Form.Item>}
                {assignNumber &&      
                <Form.Item
                  name="blonephone"
                  label="Blonde phone number"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your tiktok username!' }]}
                  className="custom-form-item"
                  initialValues={assignNumber}
                >
                   <Input
                        style={{ padding: '7px 0px' }}
                        addonAfter={
                            <Button type="text" onClick={handleCopy}>
                                <Image 
                                  width={18} 
                                  src={CopyIcon} 
                                  preview={false}
                                />
                                <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Copy</span>
                            </Button>}
                        placeholder="+1 999 999 9999"
                        defaultValue={assignNumber}
                        className="copy-paste-input"
                        maxLength={14}
                        readOnly={true}
                    />
                </Form.Item>}

                <Form.Item
                    name="password"
                    label="Password*"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        min: 8,
                        message: 'Password must be at least 8 characters long!',
                      },
                      {
                        pattern: /[!@#$%^&*(),.?":{}|<>]/,
                        message: 'Password must contain at least one special character!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Create a password" />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm password*"
                    dependencies={['password']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('The two passwords that you entered do not match!')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm password" />
                </Form.Item>

                <div className="password-criteria">
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CheckSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span>Must be at least 8 characters</span>
                    </div>
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CheckSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span>Must contain one special character</span>
                    </div>
                </div>

                <Flex
                    vertical
                    gap="small"
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                    <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                      Continue
                    </Button>
                </Flex>

            </Form>
            </div>
          </>)}
          {step===1 && (
             <div className="setpfrm">
                  <VerifyStep userEmail={userEmail} setStep={setStep}/>
             </div>
          )}
          {step===2 && (
            <div className="setpfrm">
               <Flex
                    vertical
                    align="center"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Image
                        width={60}
                        src={PhoneIcon}
                        preview={false}
                    />
                  </Flex>

                  <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>2-step verification</Title>
                  <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Enter the 2-step verification code we texted to your phone</Paragraph>

                  <Form
                    name="verificationStepOne"
                    form={form}
                    onFinish={verifictionHandller}
                  >
                    <CodeInput setCode={setCode} /> {/* Use the CodeInput component */}
                    <Form.Item>
                      <Button type="primary" size="large" htmlType="submit" block style={{ fontWeight: 600 }}>
                        Submit
                      </Button>
                    </Form.Item>

                  </Form>
                  <Button onClick={()=>{ setStep(1)}} size="large" block style={{ fontWeight: 600 }}>Back</Button>
                  <Paragraph style={{ textAlign: 'center', marginTop: '30px' }}>Didn’t receive the email? <Button loading={loading} className='resendOtp' onClick={resendOtp}>Click to resend</Button></Paragraph>

              </div>
            )}

             {step===3 && (
                <div className="setpfrm">
                   <AccountCreated userEmail={userEmail} user_name={assignedUrl}/>
                </div>
             )}
        </Spin>
        </Flex>
        </div>
    );
}

export default Welcome;
