// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.giphyMain {
    min-width: 0;
    padding: 0;
    column-count: 4;
    column-gap: 5px;
    display: block;
    margin: 0 !important;
    .item {
        margin: 0 0 5px 0;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/GlobalComponents/Gifs.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IAGV,eAAe;IAGf,eAAe;IACf,cAAc;IACd,oBAAoB;IACpB;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".giphyMain {\n    min-width: 0;\n    padding: 0;\n    -webkit-column-count: 3;\n    -moz-column-count: 3;\n    column-count: 4;\n    -webkit-column-gap: 20px;\n    -moz-column-gap: 20px;\n    column-gap: 5px;\n    display: block;\n    margin: 0 !important;\n    .item {\n        margin: 0 0 5px 0;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
