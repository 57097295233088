import React, { useState } from "react";
import { Radio, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";

/**
 * Add your all dropdown categories here with unique key
 */
const dropdownCategories = [
  {
    key: 0,
    content: "12 months",
    value: "Last_12_months",
  },
  {
    key: 1,
    content: "30 days",
    value: "Last_30_days",
  },
  {
    key: 2,
    content: "7 days",
    value: "Last_7_days",
  },
  {
    key: 3,
    content: "24 hours",
    value: "Today",
  }
];

/**
 * Stylesheet using react-jss
 */
const useStyles = createUseStyles(() => ({
  container: {
    position: "relative",
    right: 0,
    "& button": {
      color: "black",
      border: "1.5px solid #EDEEF1",
      width: 150,
      borderRadius: "15px",
    },
  },
}));

export const DropdownSelector = ({ fetchCustomData }) => {
  const classes = useStyles();

  // This state is used to track selected value from Radio.Group
  const [activeTimeFrame, setActiveTimeFrame] = useState(0);

  const handleRangeChange = (e) => {
    const selectedValue = e.target.value;
    const selectedKey = dropdownCategories.findIndex(
      (category) => category.value === selectedValue
    );
    setActiveTimeFrame(selectedKey);
    fetchCustomData(selectedValue);
  };

  return (
    <div className={classes.container}>
      <Radio.Group
        value={dropdownCategories[activeTimeFrame].value}
        onChange={handleRangeChange}
        buttonStyle="solid"
      >
        {dropdownCategories.map((category) => (
          <Radio.Button key={category.key} value={category.value}>
            {category.content}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default DropdownSelector;
