import React,{useEffect,useState} from 'react';
import { Layout, Typography, Button, Flex, Image,message } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import EmailIcon from '../../images/email-icon.svg';
import { post } from '../../utils/api';

const { Title, Paragraph } = Typography;

const { Content } = Layout;

const EmailSubmitted = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { values } = location.state || {};
  const {email}=values || {};
  const [loading, setLoading] = useState(false);

 useEffect(() => {
    if (!email) {
      navigate('/forgot-password');
    }
  }, [email, navigate]);


  const handleResend =async () => {
    
    if(email){
      setLoading(true);
      try {
        const response = await post('auth/forgot-password',{email});
        if (response && response.status === 200) {
          message.success('Your password reset link has been resent');
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    }
  };

  const handleOpenEmailApp = () => {
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  return (
    <Content style={{ paddingTop: 40 }}>
      <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
        <Flex
          vertical
          align="center"
          style={{
            width: '100%',
          }}
        >
          <Image
              width={60}
              src={EmailIcon}
              preview={false}
          />
        </Flex>

        <Title level={2} style={{ textAlign: 'center', marginTop: 25, marginBottom: 15 }}>Check your email</Title>
        <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>We sent a password reset link to {email}</Paragraph>

        <Button  onClick={handleOpenEmailApp} type="primary" size="large" block style={{ fontWeight: 600, marginTop: 10 }}>
          Open email app
        </Button>
        <Paragraph style={{ textAlign: 'center', marginTop: '30px' }}>Didn’t receive the email? <Link loading={loading} onClick={handleResend} style={{ fontWeight: 600 }}>Click to resend</Link></Paragraph>
        
        <Link to="/"><Button type="text" size="large" block icon={<ArrowLeftOutlined />} style={{ fontWeight: 600, fontSize: 14 }}>Back to log in</Button></Link>

      </Flex>
    </Content>
  );
};

export default EmailSubmitted;