import React,{useEffect,useState,useContext} from 'react';
import { Layout, Typography, Flex, List, Avatar, Space } from 'antd';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import socket from '../../services/socket';
import { get } from '../../utils/api';
import AvatarImage from '../../components/AvatarImage';
import TimeAgo from '../../components/TimeAgo';

import './notification.css';

const { Title, Text } = Typography;


const NotificationCenter = () => {
  const [ellipsis, setEllipsis] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const {user}  = useContext(UserContext);

  const fetchData = async (params = {}) => {
    const response = await get(`creator/notification`,{
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 20,
      q: params?.searchTerm ||'',
    });
   
    if (response && response.data) {
      setNotificationData(response.data);
      setPagination({
        ...params.pagination,
        total: response.pagination.total,
      });
    }
  };


  const updateOrAppendMessage = (data, newMessage) => {
    //Check if the user_phone exists in the data array
  
    const index = data.findIndex(item => item?.id === newMessage?.id);
    
    if (index !== -1) {
      // If user_phone exists, update the last_message
      data[index] = {
        ...data[index],
        updatedAt: new Date().toISOString()
      };
    } else {
      // If user_phone does not exist, append the newMessage to the array
      data.push(newMessage);
    }
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    return [...data]; // Return a new array to trigger re-render
  };

  useEffect(() => {
    fetchData(); 

    const handleNewMessage =async (message) => {
      if (message && message.status) {
        const { output } = message;

        if (output?.creator_id === user?.id) {
          setNotificationData(prevData => updateOrAppendMessage(prevData, output));
        }
      }
    };

    socket.on('notifications', handleNewMessage);

    // Clean up on component unmount
    return () => {
      socket.off('notifications', handleNewMessage);
    };
  }, [user]);
  
  return (
    <Layout>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Flex className="notification-head-area" horizontal justify="space-between" align="center">
            <Title level={4}>Notification Center</Title>
        </Flex>

        <List
          itemLayout="horizontal"
          dataSource={notificationData}
          className="notification-list"
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<AvatarImage  size={48} style={{ background: '#98A2B3', fontSize: 20 }}  title={`${item?.title}`}  />}
                title={
                  <Flex className="notification-top-head">
                    <Text className="title" strong>{item?.title}</Text>
                    <Text style={{ minWidth: "50px", display: 'block', textAlign: 'right' }} type="secondary"><TimeAgo timestamp={item.updatedAt} /></Text>
                  </Flex>
                }
                description={
                  <div>
                    <Text>{item?.message}</Text>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Space>
    </Layout>
  );
};

export default NotificationCenter;
