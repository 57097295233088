import React, { useState,useContext,useMemo,useEffect } from 'react';
import { Form, Upload, Layout, List, AutoComplete, Typography, Button, Dropdown, Input, Divider, Checkbox, Flex, Card, Radio, Row, Col, Slider, Image, Spin, message,Popconfirm } from 'antd';
import { PlusOutlined, SearchOutlined, ArrowLeftOutlined, UserOutlined, CloseOutlined, CloudUploadOutlined, RightOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import SidebarFan from './SidebarFan';
import './communities.css';
import ArrowRight from "../../images/arrow-right.svg";
import UserIcon from "../../images/user-icon.svg";
import EllipsisIcon from "../../images/ellipsis-icon.svg";
import { UserContext } from '../../contexts/UserContext';
import { getInitials,calculateAge,imageUrl} from '../../utils/utils';
import AvatarImage from '../../components/AvatarImage';
import { get,post,del } from '../../utils/api';
import ImageUpload from '../../components/ImageUploads/ImageUpload';
import AddressAutocomplete from '../../components/AddressAutoComplete';
import SendMessage from '../../components/Communities/SendMessage';
import useScreenSize from '../../utils/useScreenSize';

const { Sider, Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const Communities = () => {
  const [form] = Form.useForm();
  const { isMobile } = useScreenSize();
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [communitiesList, setCommunitiesList] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectOnclick, setSelectOnclick] = useState(null);
  const [selectedFan, setSelectedFan] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const [selectedNewCommunity, setNewCommunity] = useState(null);
  const [editCommunity, setEditCommunity] = useState(null);
  const [searchCommunity, setSearchCommunity] = useState('');
  const [searchFan, setSearchFan] = useState('');
  const [imageId,setImageId]=useState(null);
  const [photo,setPhoto]=useState(null);
  const [photoId,setPhotoId]=useState(null);
  const [fansOption, setFansOption] = useState('all');
  const [gender, setGender] = useState([]);
  const [ageRange, setAgeRange] = useState([16, 25]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSendMessage, setSendMessage] = useState (false);
  const [selectAll, setSelectAll] = useState(false);
  const {user}  = useContext(UserContext);
  const [communities, setCommunities] = useState([
    { id: 1, name: 'All Audience', userCount: 0 },
    { id: 2, name: 'Favorite Audience', userCount: 0 },
  ]);
  const loadCommunities = async (current, pageSize) => {
   
    setCommunitiesList([]);
    setIsLoading(true);

    try {
      const response = await get('creator/communities/',{s:searchCommunity});
      if(response){
        setCommunitiesList(response);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    loadCommunities();
  },[searchCommunity]);

  const loadfavoriteAudiencefanCounts=async()=>{
    try {
      const response = await get('creator/fanCounts/');
      if(response && response.status){
        const { allUsers, favoriteAudienceCount } = response.data;
        setCommunities(prevCommunities =>
          prevCommunities.map(community => {
            if (community.name === 'All Audience') {
              return { ...community, userCount: allUsers };
            } else if (community.name === 'Favorite Audience') {
              return { ...community, userCount: favoriteAudienceCount };
            }
            return community;
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadCommunities();
      await loadfavoriteAudiencefanCounts();
    };
  
    init(); // Call the initialization function immediately
  }, []);


 const deleteconfirm=async()=>{
  try {
    await del(`creator/deleteCommunities/${selectOnclick?.id}`);
    setSelectedCommunity(null);
    await loadCommunities();
    message.success("Successfully deleted!");
  } catch (error) {
    console.error('Error fetching users:', error);
  }
 }
 const cancel=()=>{
  console.log("cancel");
 }

 const fneditCommunity=async()=>{
  form.resetFields();

      let memData=selectOnclick;
     
      if(memData?.image){
        const imagePath=await imageUrl(memData?.image);
        setPhoto(imagePath);
      }
      
      setEditCommunity(memData);
      setSelectedCommunity(null);
 }

  const fans = [
    { id: 1, name: 'John Doe', phone: '+1 (800) 758-5000',  gender: 'Male', age: 23, location: 'Newport Beach, CA', spent: 1000, interactions: 30, communities: ['Avocado Junkies'] },
    { id: 2, name: 'Olivia Rhye', phone: '+1 (800) 758-5000', gender: 'Female', age: 23, location: 'Palm Springs, CA', spent: 1000, interactions: 30, communities: ['Avocado Junkies'] },
  ];

  const communityitems = (
    <Flex vertical align="start" style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0', borderRadius: '8px', paddingTop: 5, paddingBottom: 5 }}>
      <Button onClick={fneditCommunity} block type="text" size="large" style={{ textAlign: 'left', color: '#344054' }} icon={<EditOutlined />}>Edit</Button>
      <Divider style={{ margin: 0 }} />
      <Popconfirm
      placement="topLeft"
        title="Delete Community"
        description={`Are you sure to delete ${selectedCommunity?.name}?`}
        onConfirm={deleteconfirm}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
      <Button block type="text" size="large" style={{ textAlign: 'left', color: '#344054' }} icon={<DeleteOutlined style={{ color: '#D92D20' }} />}>Delete Community</Button>
      </Popconfirm>
    </Flex>
  );


  // const filteredCommunities = communities.filter(community =>
  //   community.name.toLowerCase().includes(searchCommunity.toLowerCase())
  // );

  // const filteredFans = fans.filter(fan =>
  //   fan.name.toLowerCase().includes(searchFan.toLowerCase())
  // );

  const addNewcommunity = () => {
    setSelectedCommunity(false);
    setSelectedFan(false);
    setNewCommunity(true);
  };

  const handleCommnityClose = () => {
    setNewCommunity(false);
  }

  const handleFansOptionChange = (e) => {
    setFansOption(e.target.value);
  };

  const handleGenderChange = (checkedValues) => {
    setGender(checkedValues);
  };

  const handleAgeChange = (value) => {
    setAgeRange(value);
  };

  const updateEdits=async(values)=>{
    const data = { ...values,photo:imageId};
    setIsLoading(true);
      try {
        const response = await post(`creator/communities/update/${editCommunity?.id}`,data);
        if (response && response.status) {
          await loadCommunities();
          setNewCommunity(null);
          setEditCommunity(null);
          form.resetFields();
          message.success(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setIsLoading(false);
      }
  }

  const onFinish = async(values) => {
    const data = { ...values,photo:imageId ,created_by:user?.id,age:ageRange};
    setIsLoading(true);
      try {
        const response = await post(`creator/communities/create`,data);
        if (response && response.status) {
          await loadCommunities();
          setNewCommunity(null);
          form.resetFields();
          message.success(response.message);
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setIsLoading(false);
      }
  };

  const GetSingleCommunity=async(item,searchFan='')=>{

    const formData = {
      id: item?.id,
      s:searchFan
    };
    setIsLoading(true);
    setSelectedFan(false);
    setEditCommunity(null);
    setNewCommunity(null);
    if(item.name==="All Audience"){
      try {
        const response = await get(`creator/allCommunities`,formData);
        if(response.status){
          const {Users}=response.data || {};
          const dataCollection={
            name:"All Audience",
            Users,
            userCount:item?.userCount
          }
          setSelectedCommunity(dataCollection);
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
      
    }else if(item.name==="Favorite Audience"){

      try {
        const response = await get(`creator/favoriteAudience`,formData);
        if(response.status){
          const {favorites}=response.data || {};
          const dataCollection={
            name:"Favorite Audience",
            Users:favorites,
            userCount:item?.userCount
          }
          setSelectedCommunity(dataCollection);
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }

    }else{
      try {
        const response = await post(`creator/communities/${user?.id}`, formData);
        if(response.status){
          setSelectedCommunity({...response.data,userCount:item?.userCount});
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  }


  const handleCommnityEditClose=async()=>{
    setEditCommunity(null);
  }
  

  useEffect(() =>{ 
    if(form){
      form.resetFields();
    }
    
  }, [form,editCommunity]);
  useEffect(() => {
    const loaderInit = async () => {
      if (selectedCommunity) {
        try {
          await GetSingleCommunity(selectedCommunity);
        } catch (error) {
          console.error('Failed to fetch community data:', error);
        }
      }
    };

    loaderInit();
  }, [isReload]);

  const searchFans=async(searchvalue)=>{
    if(searchvalue.target.value){
      await GetSingleCommunity(selectOnclick,searchvalue.target.value);
    }else{
      await GetSingleCommunity(selectOnclick);
    }
  }
 
  
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedItems(checked ? selectedCommunity?.Users : []);
  };

  const handleItemChange = (item, e) => {
    const checked = e.target.checked;
    setSelectedItems((prevSelectedItems) =>
      checked ? [...prevSelectedItems, item] : prevSelectedItems.filter((i) => i !== item)
    );
    if (!checked) setSelectAll(false);
  };


  const handleSendMessage = () => {
    if(selectedItems?.length>0){
      setSendMessage(true);
    }else{
      message.error('Please select at least one user to send a message.');
    }
   
  };

  const handleCloseMessage = () => {
    setSendMessage(false);
  };

  const handleClose = () => {
    setSelectedCommunity(false);
  };

  const selectedCommunityStyles = useMemo(() => 
    isMobile ? { 
      backgroundColor: '#ffffff', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      display: 'flex', 
      flexDirection: 'column' 
    } : { 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column' 
    }
  , [isMobile]);
  
  return (
    <Layout className="communities-main" style={{ minHeight: '100vh' }}>
      <Spin spinning={isLoading}>
      <Sider width={ isMobile ? '100%' : 360} style={{ backgroundColor: '#ffffff', borderRight: '1px solid #EAECF0', minHeight: '100vh' }}>
        <div className="head-area" style={{ padding: '20px', justifyContent: 'space-between' }}>
          <Title level={2}>Communities</Title>
          <Button type="primary" onClick={addNewcommunity} icon={<PlusOutlined />} style={{ float: 'right' }} />
        </div>

        <div style={{ padding: '0 20px 0px 20px' }}>
          <Input
            placeholder="Search communities"
            onChange={e => setSearchCommunity(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginBottom: '20px' }}
          />
        </div>

        {communitiesList.length>0 ?
        <>
              <List
              itemLayout="horizontal"
              dataSource={communities}
              style={{ borderTop: '1px solid #EAECF0' }}
              renderItem={item => (
               
                <List.Item
                  style={{ cursor: 'pointer', padding: '10px 20px', background: item.name === selectedCommunity?.name ? '#F9FAFB' : 'transparent' }}
                   onClick={() =>{
                    setSelectOnclick(item);
                    GetSingleCommunity(item)}}
                >
                  
                  <List.Item.Meta
                    avatar={<AvatarImage imageId={item?.image} title={item.name} />}
                    title={<Text strong>{item.name}</Text>}
                    description={<Text>{item.userCount} Fans</Text>}
                  />
                </List.Item>
              )}
            />
          <List
            itemLayout="horizontal"
            dataSource={communitiesList}
            style={{ borderTop: '1px solid #EAECF0' }}
            renderItem={item => (
              <List.Item
                style={{ cursor: 'pointer', padding: '10px 20px', background: item.id === selectedCommunity?.id ? '#F9FAFB' : 'transparent' }}
                onClick={()=>{
                   setSelectOnclick(item);
                   GetSingleCommunity(item)
                  }
                }
              >
               
                <List.Item.Meta
                  avatar={<AvatarImage />}
                  title={<Text strong>{item.name}</Text>}
                  description={<Text>{item.userCount} Fans</Text>}
                />
              </List.Item>
            )}
          />
        </>
        : (<>
            <Flex vertical style={{ padding: 20, flex: 1, overflowY: 'auto', borderTop: '1px solid #EAECF0' }}>
              <Title level={3} style={{ marginTop: 0 }}>Suggestions</Title>
              <Flex gap={20} align="center" style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '17px 20px', cursor: 'pointer' }} onClick={addNewcommunity}>
                <Image
                    width={36}
                    src={UserIcon}
                    preview={false}
                />
                <Title level={5} style={{ margin: 0 }}>Add your first community</Title>
                <RightOutlined />
              </Flex>
            </Flex>
            <List
              itemLayout="horizontal"
              dataSource={communities}
              style={{ borderTop: '1px solid #EAECF0' }}
              renderItem={item => (
                <List.Item
                style={{ cursor: 'pointer', padding: '10px 20px', background: item.name === selectedCommunity?.name ? '#F9FAFB' : 'transparent' }}
                  onClick={() =>{
                    setSelectOnclick(item);
                    GetSingleCommunity(item);}}
                >
                  <List.Item.Meta
                    avatar={<AvatarImage />}
                    title={<Text strong>{item.name}</Text>}
                    description={<Text>{item.userCount} Fans</Text>}
                  />
                </List.Item>
              )}
            />
          </>)}
      </Sider>
      </Spin>
      
      {selectedNewCommunity &&
        <Content className="new-community" style={{ backgroundColor: '#F9FAFB' }}>
           <Spin spinning={isLoading}>
            <Flex horizontal="true" className="head" justify="space-between">
              <Title level={4}>New Community</Title>
              <Button
                type="link"
                icon={<CloseOutlined style={{ color: '#101828' }}/>}
                onClick={handleCommnityClose}
                style={{ alignSelf: 'flex-end' }}
              >
              </Button>
            </Flex>

            <Flex vertical>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Flex gap="large" vertical style={{ padding: '20px 30px' }}>
                  <Card className="community-form-grid">
                    <Title level={4}>Community Details</Title>
                    <Flex gap="large" horizontal="true">
                    
                      <Flex vertical style={{ flex: 1 }}>
                        <Form.Item
                          label="Avatar"
                          name="photo"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: false }]}
                          default
                        >

                          <ImageUpload setImageId={setImageId} photoId={photoId} imageUrl={photo}/>
                        </Form.Item>
                      </Flex>
                    </Flex>
                    <Form.Item className="community-field" name="communityName" label="Community Name" rules={[{ required: true, message: 'Please enter community name' }]}>
                      <Input placeholder="Enter community name" className="custom-form-item" />
                    </Form.Item>
                  </Card>
                  <Card className="fans-form-grid">
                    <Flex vertical style={{ padding: '25px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Title level={4}>Fans</Title>
                      <Form.Item label="Fans & Communities" name="fansOption" initialValue={fansOption}>
                        <Radio.Group onChange={handleFansOptionChange}>
                          <Radio value="all">All</Radio>
                          <Radio value="favorites">Favorites</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="gender" label="Gender">
                        <Checkbox.Group onChange={handleGenderChange}>
                          <Row gutter={10}>
                            <Col><Checkbox value="male">Male</Checkbox></Col>
                            <Col><Checkbox value="female">Female</Checkbox></Col>
                            <Col><Checkbox value="other">Other</Checkbox></Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="ageRange" label="Age">
                        <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} />
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 10px 24px' }}>
                      <Form.Item name="location" label="Location">
                           <AddressAutocomplete/>
                      </Form.Item>
                    </Flex>
                  </Card>
                </Flex>
                <Flex vertical className="form-footer">
                  <Form.Item>
                    <Button type="default" size="large" onClick={() => form.resetFields()} style={{ marginRight: '20px', border: 0, fontWeight: 600 }}>Cancel</Button>
                    <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600 }}>Create</Button>
                  </Form.Item>
                </Flex>
              </Form> 
            </Flex>
            </Spin>
        </Content>
      }

    {editCommunity &&
        <Content className="new-community" style={{ backgroundColor: '#F9FAFB' }}>
           <Spin spinning={isLoading}>
            <Flex horizontal="true" className="head" justify="space-between">
              <Title level={4}>Edit Community</Title>
              <Button
                type="link"
                icon={<CloseOutlined style={{ color: '#101828' }}/>}
                onClick={handleCommnityEditClose}
                style={{ alignSelf: 'flex-end' }}
              >
              </Button>
            </Flex>

            <Flex vertical>
           
              <Form name='editCommunity' form={form} id="editCommunity"  initialValues={editCommunity}  layout="vertical" onFinish={updateEdits}>
                <Flex gap="large" vertical style={{ padding: '20px 30px' }}>
                  <Card className="community-form-grid">
                    <Title level={4}>Community Details</Title>
                    <Flex gap="large" horizontal="true">
                    
                      <Flex vertical style={{ flex: 1 }}>
                        <Form.Item
                          label="Avatar"
                          name="photo"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: false }]}
                          default
                        >

                          <ImageUpload setImageId={setImageId} photoId={photoId} imageUrl={photo}/>
                        </Form.Item>
                      </Flex>
                    </Flex>
                    <Form.Item className="community-field" name="name" label="Community Name" rules={[{ required: true, message: 'Please enter community name' }]}>
                      <Input placeholder="Enter community name" className="custom-form-item" />
                    </Form.Item>
                  </Card>
                  <Card className="fans-form-grid">
                    <Flex vertical style={{ padding: '25px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Title level={4}>Fans</Title>
                      <Form.Item label="Fans & Communities" name="fansOption" initialValue={fansOption}>
                        <Radio.Group onChange={handleFansOptionChange}>
                          <Radio value="all">All</Radio>
                          <Radio value="favorites">Favorites</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="gender" label="Gender">
                        <Checkbox.Group onChange={handleGenderChange}>
                          <Row gutter={10}>
                            <Col><Checkbox value="male">Male</Checkbox></Col>
                            <Col><Checkbox value="female">Female</Checkbox></Col>
                            <Col><Checkbox value="other">Other</Checkbox></Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="ageRange" label="Age">
                        <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} />
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 10px 24px' }}>
                      <Form.Item name="location" label="Location">
                         <AddressAutocomplete/>
                      </Form.Item>
                    </Flex>
                  </Card>
                </Flex>
                <Flex vertical className="form-footer">
                  <Form.Item>
                    <Button type="default" size="large" onClick={() => form.resetFields()} style={{ marginRight: '20px', border: 0, fontWeight: 600 }}>Cancel</Button>
                    <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600 }}>Save Edits</Button>
                  </Form.Item>
                </Flex>
              </Form> 
            </Flex>
            </Spin>
        </Content>
      }

      {selectedCommunity ? (
        <Content style={selectedCommunityStyles}>
            <Flex horizontal="true" className="communities-head" align="start" justify="space-between" style={ isMobile && { flexDirection: 'column' }}>
              {isMobile && 
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                  onClick={handleClose}
                  style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
                >
                Back
                </Button>
              }
              <Flex gap={20} horizontal="true" style={ isMobile && { marginBottom: 15, width: '100%' }}>
                <AvatarImage />
                <Flex vertical>
                  <Title level={3}>{selectedCommunity.name}</Title>
                  <Text className="count">{selectedCommunity.userCount} Fans</Text>
                </Flex>

                {isMobile &&<>
                  {(selectedCommunity.name !== 'All Audience' && selectedCommunity.name !== 'Favorite Audience') &&
                    <Dropdown overlay={communityitems} autoFocus={true} trigger={['click']}>
                      <a onClick={(e) => e.preventDefault()} style={{ marginLeft: 'auto' }}>
                        <Image src={EllipsisIcon} width={20} preview={false} />
                      </a>
                    </Dropdown>
                  }
                </>}
              </Flex>
              <Flex gap={15} horizontal="true" align="start">

                <Button type="primary" size="medium" onClick={handleSendMessage} style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send Message</Button>
                {isMobile ? 
                  ""
                :<>
                  {(selectedCommunity.name !== 'All Audience' && selectedCommunity.name !== 'Favorite Audience') &&
                    <Dropdown overlay={communityitems} autoFocus={true} trigger={['click']}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Image src={EllipsisIcon} width={20} preview={false} />
                      </a>
                    </Dropdown>
                  }
                </>}
              </Flex>
            </Flex>

            <Flex vertical style={ isMobile ? { padding: '0px 15px 10px 15px' } : { padding: '0px 20px 15px 20px' }}>
              <Input
                placeholder="Search fans"
                onChange={e => searchFans(e)}
                prefix={<SearchOutlined />}
                style={{ marginBottom: '10px' }}
                className="custom-form-search-item"
              />
            </Flex>
            <Flex vertical style={ isMobile ? { padding: '0px 15px 10px 15px', flex: 1, overflowY: 'auto' } : { padding: '0px 20px 10px 20px', flex: 1, overflowY: 'auto' }}>
            <Spin spinning={isLoading}>

                <Checkbox
                  onChange={handleSelectAllChange}
                  checked={selectAll}
                >
                  Select All ({selectedItems.length})
                </Checkbox>

              <List
                itemLayout="horizontal"
                dataSource={selectedCommunity?.Users}
                className="communities-list"
                renderItem={item => (
                  <List.Item
                    style={{ cursor: 'pointer', padding: '15px 0', background: item.id === selectedFan?.id ? 'transparent' : 'transparent' }}
                    
                  >
                    <Checkbox
                      checked={selectedItems.includes(item)}
                      onChange={(e) => handleItemChange(item, e)} 
                      style={{ marginRight: 15 }} 
                    />

                    <List.Item.Meta
                      avatar={<AvatarImage imageId={item?.image} title={`${item?.first_name} ${item?.last_name}`} />}
                      title={<Text strong>{item.first_name} {item.last_name}</Text>}
                      description={<Text>{item.gender && (<span>{item.gender} |</span>)}{calculateAge(item?.dob)}  {item.location && (<span>{item.location}</span>)} </Text>}
                      onClick={() =>{
                        setSelectedFan(true);
                        const updatedObject = {
                          ...item,
                          user_phone: item.phone_number
                      };
                        setSelectedUser(updatedObject);
                      }}
                    />
                     <Image
                        width={20}
                        src={ArrowRight}
                        preview={false}
                    />

                  </List.Item>
                )}
              />
              </Spin>
            </Flex>
        </Content>
      ) : (
          ''
      )}

    {openSendMessage && <SendMessage selectedItems={selectedItems} setSelectedItems={setSelectedItems} openSendMessage={openSendMessage} handleCloseMessage={handleCloseMessage} /> }
     
    {selectedFan && <SidebarFan selectedUser={selectedUser} selectedFan={selectedFan} setSelectedFan={setSelectedFan} setIsLoading={setIsLoading} setIsReload={setIsReload} selectedCommunity={selectedCommunity} />}

    </Layout>
  );
};

export default Communities;
