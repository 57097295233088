import { Typography,Layout,Flex } from "antd";
import useScreenSize from "../../../utils/useScreenSize";
const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;
const TeamDashboard=()=>{
        const { isMobile } = useScreenSize();
        return (<>
          <Content style={{ padding: isMobile ? '0' : '40px 0px' }}>
          <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
                <Title>Welcome to Blonde!</Title>
        </Flex>
        </Content>
        </>);
};
export default TeamDashboard;