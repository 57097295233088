import axios from 'axios';
import { message } from 'antd';
import { jwtDecode } from "jwt-decode";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});


const authTeamPaths = ['teams/teamList'];
// Middleware to attach the token to the headers
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // Or any other way you store the token
    const { url } = config;

    

    if (token) {
      const decodedToken = jwtDecode(token);
      if(decodedToken?.role && decodedToken?.role=="Team"){
        const teamToken = localStorage.getItem('teamToken');
        if(teamToken){
          if (authTeamPaths.some(path => url.includes(path))) {
            config.headers.Authorization = `Bearer ${token}`;
          }else{
            config.headers.Authorization = `Bearer ${teamToken}`;
          }
        }else{
          config.headers.Authorization = `Bearer ${token}`;
        }
      }else{
      
          config.headers.Authorization = `Bearer ${token}`;
      }

    }
    return config;
  },
  error => Promise.reject(error)
);

// Middleware to handle responses and errors
api.interceptors.response.use(
  response => response,
  error => {
    // Handle errors, e.g., redirect to login on 401 Unauthorized
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
      localStorage.clear();
      message.error(error.response.data.message);
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

// Common API request function
const apiRequest = async (method, url, data = {}, params = {},customHeaders = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      params,
      headers: {
        ...api.defaults.headers.common,
        ...customHeaders
      },
    });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

// Export HTTP methods as helper functions
const get = (url, params, headers = {}) => apiRequest('get', url, {}, params, headers);
const post = (url, data, headers = {}) => apiRequest('post', url, data, {}, headers);
const put = (url, data, headers = {}) => apiRequest('put', url, data, {}, headers);
const del = (url, params, headers = {}) => apiRequest('delete', url, {}, params, headers);

export { get, post, put, del };
