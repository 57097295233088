import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Image } from 'antd';
import { MessageOutlined, CalendarOutlined, UsergroupAddOutlined, LockOutlined, UserAddOutlined, BarChartOutlined, BellOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import SmirkingIcon from '../images/smirking-face.png';
import { useLocation } from 'react-router-dom';
import useScreenSize from '../utils/useScreenSize';
const { Content } = Layout;

const CreatorLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(null); // Set to null initially
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const savedCollapsed = localStorage.getItem('sidebarCollapsed') === 'true'; // Fetch and parse boolean
    setCollapsed(savedCollapsed); // Set the state after fetching
  }, []);

  const toggleCollapsed = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem('sidebarCollapsed', newCollapsed);
  };

  const layoutStyles = useMemo(() => ({
    paddingLeft: isDesktop ? (collapsed ? '80px' : '312px') : '0',
  }), [isDesktop, collapsed]);

  if (collapsed === null) {
    return null; 
  }

  const menuItems = [
    { key: '2', icon: <MessageOutlined />, label: 'Messages', path: '/message/audience' },
    { key: '3', icon: <CalendarOutlined />, label: 'Scheduled Messages', path: '/schedule-message' },
    { key: '4', icon: <UsergroupAddOutlined />, label: 'Community', path: '/communities' },
    { key: '5', icon: <LockOutlined />, label: 'My Gallery', path: '/content-vault' },
    { key: '7', icon: <BarChartOutlined />, label: 'Insights', path: '/insights' },
    { key: '8', icon: <BellOutlined />, label: 'Notifications', path: '/notification' },
    { key: '9', icon: <SettingOutlined />, label: 'Settings', path: '/settings' },
  ];

  const menuItemsBottom = [{ key: '5', icon: <UserOutlined />, label: 'My profile', path: '/profile' }];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        menuItems={menuItems}
        currentPath={currentPath}
        menuItemsBottom={menuItemsBottom}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        toggleCollapsed={toggleCollapsed}
      />
      <Layout className="site-layout" style={layoutStyles}>
        <Content>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CreatorLayout;
