import React, { useEffect, useState,useContext } from 'react';
import { Modal, Button, Input, Tooltip, Typography, Flex, Select ,Form, Avatar, Calendar, TimePicker, Image, message as antdMessage,Spin} from 'antd';
import { SmileOutlined, PictureOutlined, LeftOutlined, RightOutlined, EditOutlined, CheckOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import ChatInput from '../GlobalComponents/ChatInput';
import { getInitials ,getImageUrl} from '../../utils/utils';
import dayjs from 'dayjs';
import momenttmz from 'moment-timezone';
import { post } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import CalendarPlusIcon from '../../images/calendar-plus-icon.svg';
import CurrencyDollarIcon from '../../images/currency-dollar-icon.svg';

const { Title, Text } = Typography;
const { Option } = Select;

const SendMessage = ({ openSendMessage, handleCloseMessage,selectedItems,setSelectedItems }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [title, setTitle] = useState('Untitled');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const {user}  = useContext(UserContext);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [priceInput, setPriceInput] = useState('');
  const [scheduleMessage, setScheduleMessage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedAmPm, setSelectedAmPm] = useState('AM');
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [price, setPrice] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles');
  const { isMobile } = useScreenSize();
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const [form] = Form.useForm();

  const handleClearScheduleDate = () => {
    setScheduleMessage(null);
  };
  const showModal = () => {
    setIsScheduleModalVisible(true);
  };
  const handleClearPrice = () => {
    setPrice(null);
  };

  const handleInputChange = (value) => {
    setMessage(value);
  };

  useEffect(() => {
    setVisible(openSendMessage);
  }, [openSendMessage]);

  useEffect(()=>{
    setTitle(`${selectedItems?.length} fans`);
    form.setFieldsValue({
      to: `${selectedItems?.length} Fans`
    });
  },[selectedItems]);

  const handleCancel = () => {
    setVisible(false);
    handleCloseMessage();
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleEditTitle = () => {
    setIsEditingTitle(!isEditingTitle);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  const handlePriceModalCancel = () => {
    setIsPriceModalVisible(false);
  };

  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };

  const handleApplyPrice = () => {
    const priceValue = parseFloat(priceInput);
    if (!isNaN(priceValue) && priceValue >= 0.99) {
      setPrice(priceValue);
      setIsPriceModalVisible(false);
    } else {
      alert("Price must be at least $0.99");
    }
  };

  const handleOk = () => {
    if (selectedDate && selectedTime) {
      const formattedDate = selectedDate.format('MM/DD/YYYY');
      const formattedTime = selectedTime.format('h:mm') + ` ${selectedAmPm}`;
      const formattedSchedule = `Schedule for ${formattedDate} at ${formattedTime} - ${selectedTimezone}`;
      setScheduleMessage(formattedSchedule);
      setIsScheduleModalVisible(false);
    }else{
      antdMessage.error('Please choose a time to send the SMS.');
    }
  };

  const handleScheduleCancel = () => {
    setIsScheduleModalVisible(false);
  }

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(newMonth);
  };
  const handleTodayClick = () => {
    const today = dayjs();
    setSelectedDate(today);
    setCurrentMonth(today);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const onPanelChange = (value, mode) => {
    setCurrentMonth(value);
  };

  const timezones = momenttmz.tz.names();

  const sendMessageHandler=async (values)=>{
    setIsMessageSending(true);
    try{
      let schedule={};
      if(selectedDate && selectedTime){
        const formattedDate = selectedDate.format('MM/DD/YYYY');
        const formattedTime = selectedTime.format('h:mm');
  
         schedule={
          formattedDate,
          selectedAmPm,
          selectedTimezone,
          formattedTime
        };
      }
    
     
      const completeFormData = { ...values,message,selectedItems,title,schedule,price};
      const response = await post(`creator/sendMessageByItem`,completeFormData);
      if(response && response.status){
        form.resetFields();
        antdMessage.success('Success!');
        setSelectedItems([]);
        handleCancel();
      }else{
        antdMessage.error('Oops something went wrong please try again later.');
      }
     }catch(error){
      console.log("loading......",error);
    } finally {
      setIsMessageSending(false);
    }
  }

  return (
    <Modal
      open={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={940}
      className="send-message-popup"
      footer={false}
    >
      <Spin spinning={isMessageSending}>
       <Form form={form}  onFinish={sendMessageHandler} layout="vertical" >
      <div style={{ padding: 20, borderBottom: '1px solid #EAECF0' }}>
        {isEditingTitle ? (
          <Flex>
            <Input 
              value={title} 
              onChange={handleTitleChange} 
              onBlur={handleTitleBlur} 
              placeholder="Enter campaign name"
              autoFocus 
              style={{ marginRight: 10, width: 'auto', fontSize: 18, fontWeight: 700, padding: 0, border: 0, borderBottom: '1px solid #EAECF0', boxShadow: '0 0', borderRadius: 0 }} 
            />
          </Flex>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text style={{ fontSize: 18, fontWeight: 700, color: '#000000' }}>{title}</Text>
            <Button type="text" icon={<EditOutlined style={{ color: '#000000' }} />} onClick={toggleEditTitle} />
          </div>
        )}
      </div>

      <Flex>
        <Flex vertical style={{ flex: 1, padding: 20 }}>

          <Flex gap={10} horizotanl="true" style={{ marginBottom: 10 }}>
            <Form.Item 
              name="to" 
              rules={[{ required: true, message: "Please add the receiver's phone numbers.!" }]}
              className="custom-form-item"
              style={{ flex: '1', marginBottom: 0 }}
              
            >
              <Input
                placeholder=""
                addonBefore={<Text>To:</Text>}
                readOnly={true}
               
              />
            </Form.Item>
          </Flex>


          <Form.Item 
           name="messagebox"
           labelCol={{ span: 24 }}
           wrapperCol={{ span: 24 }}
           rules={[{ required: !message, message: 'Please input your message!' }]}
           className="custom-form-item custom-message-box"
          >
            <ChatInput setSelectedPrice={setSelectedPrice} onInputChange={handleInputChange} />
          </Form.Item>

          <Flex
            vertical
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

          {price !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
             <span style={{ color: '#344054', fontSize: '16px', fontWeight: 600 }}>{`$${price.toFixed(2)}`}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsPriceModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
            </Flex>
            ) : (
              ''
          )}

          {scheduleMessage !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '0px'
                }}
              >
                <span style={{ color: '#344054', fontSize: '16px' }}>{scheduleMessage}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsScheduleModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
              </Flex>
            ) : (
            ''
          )}
          </Flex>
 
          <Flex
            horizotanl
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

          {scheduleMessage !== null ? ( 
             ''
            ) : (
              <Button size="large" style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB', display: 'flex', alignItems: 'center', padding: '7px 15px 7px 10px' }} icon={<Image src={CalendarPlusIcon} preview={false} />} onClick={showModal}>Schedule</Button>
          )}

          {price !== null ? ( 
              ''
            ) : (
              <Button
                size="large"
                style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB', display: 'flex', alignItems: 'center', padding: '7px 15px 7px 10px' }}
                onClick={() => setIsPriceModalVisible(true)}
                icon={<Image src={CurrencyDollarIcon} preview={false} />}
              >
                Set Price
              </Button>
          )}
          </Flex>

          <Flex justify="end" style={{ marginTop: 20 }}>
            <Button key="submit" htmlType='submit' type="primary" size="medium" style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>
              Send
            </Button>
          </Flex>

        </Flex>
        {isMobile ?
          ""
        :
          <Flex vertical style={{ flex: '0 1 255px', maxWidth: '255px', background: '#F9FAFB', borderLeft: '1px solid #EAECF0', padding: '24px 24px' }}>
            <Flex vertical style={{ border: '2px solid #000000', borderRadius: '24px', height: 500, overflow: 'hidden', background: '#ffffff' }}>
              <Flex vertical align="center" style={{ textAlign: 'center', marginBottom: 10, paddingTop: 30 }}>
              {user?.profileUrl ?
                  <Avatar size={40} src={getImageUrl(user?.profileUrl)} />          
                  :
                  <Avatar size={40} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
                }
               <Text>{user?.first_name} {user?.last_name}</Text>
              </Flex>

              {message &&
              <div style={{ flex: '1 1 0%', overflow: 'hidden auto', scrollbarWidth: 'none' }}>
                <Flex style={{ padding: '10px 13px', maxWidth: '80%'}}>
                  <div className="chat-bubble" style={{ background: '#e9e9eb', borderRadius: 20, padding: '6px 12px', overflowWrap: 'anywhere' }}>
                 
                     <div dangerouslySetInnerHTML={{ __html: message }} style={{ whiteSpace: 'pre-wrap' }} />
                  </div>
                </Flex>
              </div>
              }
            </Flex>
          </Flex>
        }

      </Flex>
      </Form>
      </Spin>

      {isPriceModalVisible && (
        <Modal
          open={isPriceModalVisible}
          onCancel={handlePriceModalCancel}
          footer={null}
          width={560}
          className="price-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Content Price</Title>
          <Form.Item className="custom-form-input-price" style={{ marginBottom: 5 }}>
            <Input
              placeholder=""
              prefix="$"
              value={priceInput}
              onChange={handlePriceInputChange}
              style={{ marginBottom: '5px' }}
            />
          </Form.Item>
          <Text>$0.99 is standard minimum starting number.</Text>
        </Flex>
        <Flex gap="small" justify="flex-end" style={{ marginTop: '10px', borderTop: '1px solid #EAECF0', padding: '15px 20px' }}>
          <Button size="large" onClick={handlePriceModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button size="large" type="primary" onClick={handleApplyPrice} style={{ fontWeight: 600 }}>Apply</Button>
        </Flex>
        </Modal>
      )}

      <Modal visible={isScheduleModalVisible} onOk={handleOk} onCancel={handleScheduleCancel} width={688} className="schedule-modal" footer={[
        <Flex gap={14} justify="end" style={{ padding: '16px 20px', borderTop: '1px solid #EAECF0' }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={handleOk} 
          style={{ fontWeight: 600 }}
          >Apply</Button>
        </Flex>]}>
        <Flex horizotanl style={{ display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>
          
          <Flex vertical style={{ borderRight: isMobile ? '' : '1px solid #EAECF0', padding: '20px 10px' }}>
            <Flex vertical style={{ padding: '0 10px 0 15px', marginBottom: 20 }}>
              <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>Send on</Text>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <Button type="text" icon={<LeftOutlined />} onClick={() => handleMonthChange(false)} />
                <Text style={{ fontWeight: 600 }}>{currentMonth.format('MMMM YYYY')}</Text>
                <Button type="text" icon={<RightOutlined />} onClick={() => handleMonthChange(true)} />
              </div>
              <Flex gap={10} className="date-today-button">
                <Input 
                  value={selectedDate ? selectedDate.format('MMM DD, YYYY') : ''}
                  readOnly
                  style={{ flex: 1 }}
                />
                <Button onClick={handleTodayClick}>Today</Button>
              </Flex>
            </Flex>
            <Calendar
              fullscreen={false}
              value={selectedDate || currentMonth}
              onSelect={handleDateChange}
              onPanelChange={onPanelChange} 
              className="schedule-calendar"
              headerRender={() => null}
            />
          </Flex>

          <Flex vertical style={{ minWidth: '300px', padding: 20, borderTop: isMobile && '1px solid #EAECF0' }}>
            <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>At this time</Text>
            <Flex horizotanl gap={10} align="center">
              <TimePicker 
                use12Hours 
                format="h:mm" 
                style={{ flex: 1 }}
                onChange={(time) =>{
                 
                  if(time){
                    const getAMPM=time.format('a');
                    setSelectedAmPm(getAMPM);
                  }
                  
                  setSelectedTime(time)
                }} 
                changeOnScroll 
                needConfirm={false}
              />
              <Text>at</Text>
              <Select 
                value={selectedAmPm} 
                onChange={(value) => setSelectedAmPm(value)}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </Flex>

            <div style={{ marginTop: '20px' }}>
              <Text>Timezone</Text>
              <Select 
                allowClear
                style={{ width: '100%', marginTop: '10px' }} 
                value={selectedTimezone} 
                onChange={(value) => setSelectedTimezone(value)}
                showSearch
                optionFilterProp="children"
              >
                 {timezones.map((timezone) => {
                    const offset = momenttmz.tz(timezone).format('Z');
                    return (
                      <Option key={timezone} value={`${timezone}`}>
                        {`${timezone} UTC${offset}`}
                      </Option>
                    );
                  })}
              </Select>
            </div>

          </Flex>
        </Flex>
      </Modal>

    </Modal>
  );
};

export default SendMessage;
