import React from 'react';
import { Layout, Row, Col, Typography, Image } from 'antd';
import { Link, useLocation } from "react-router-dom";
import useScreenSize from '../utils/useScreenSize';
import Logo from "../images/logo.svg";
import LogoMob from "../images/Logoicon.svg";
const { Header } = Layout;
const { Paragraph } = Typography;

const HeaderComponent = () => {
  const { isMobile } = useScreenSize();
  const location = useLocation();  
  return (
    <Header style={{ background: "#ffffff", paddingTop: isMobile ? 30 : 10, paddingBottom: isMobile ? 0 : 10, height: 'auto' }}>
      <Row justify="space-between" align="middle">
        <Col xs={24} sm={8}>
          <div className="logo">
          <Link to="/">
            {isMobile ?
              <Image width={40} src={LogoMob} preview={false} />
            : 
              <Image width={100} src={Logo} preview={false} />
            }
            </Link>
          </div>
        </Col>
        <Col xs={0} sm={8} style={{ textAlign: 'right' }}>
          {location.pathname === '/signup' ?
            <Paragraph>
              Already have an account? <Link to="/" style={{ fontWeight: 600 }}>Log in</Link>
            </Paragraph>
          : 
            <Paragraph>
              Don’t have an account? <Link to="/signup" style={{ fontWeight: 600 }}>Sign up</Link>
            </Paragraph>
           }
        </Col>
      </Row>
    </Header>
  );
}

export default HeaderComponent;
