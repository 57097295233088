import React,{useState} from 'react';
import { Flex, Avatar, Typography, Form, Input, Select, Checkbox, Button,Spin } from 'antd';
import UserImg from '../../../images/avatar-image.png';
import AvatarImage from '../../AvatarImage';
import './Payment.css';
import { post } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import statesOrProvinces from '../../../utils/statesOrProvinces';
import FooterComponent from '../../Footer';

const { Title, Text } = Typography;
const { Option } = Select;

const Payment = ({creatorData,SetUnLockHandler,data,phone,smsId}) => {
    const [isLoading,setIsLoading]=useState(false);
    const imageId = creatorData && creatorData?.uploadedIds && creatorData.uploadedIds.length > 0 ? creatorData.uploadedIds[0] : null;
    const Navigate = useNavigate();
    const onFinish =async (values) => {
        setIsLoading(true);
        try{
            const formData={...values,postdata:data,creatorData,user_phone:phone}
            const response = await post(`user/unlockPayment`,formData);
            if (response && response.status) {
                if(response?.token){
                    localStorage.setItem('token', response?.token);
                    Navigate(`/audience/fan-signup-unlock/${smsId}`);
                }
            }
        }catch(error){
                console.log("error",error);
        }finally{
            setIsLoading(false);
        }
      
    };

    return (
        <Flex vertical>
         <Spin spinning={isLoading}>
            <Flex vertical align="center" style={{ marginBottom: '25px' }}>
            <AvatarImage imageId={imageId} title={`${creatorData?.first_name} ${creatorData?.last_name}`} />
                <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px' }}>{creatorData?.first_name} {creatorData?.last_name}</Title>
                <Title level={3} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>Payment details</Title>
                <Text style={{ textAlign: 'center' }}>In order to see this user’s content you must complete the following details.</Text>
            </Flex>
            <Flex vertical>
                <Title level={3} style={{ textAlign: 'center', marginBottom: '10px' }}>Billing address</Title>
                <Form onFinish={onFinish}>
                    <Form.Item 
                        name="firstName" 
                        label="First Name" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item" 
                        rules={[{ required: true, message: 'Please enter your first name' }]}
                    >
                        <Input placeholder="Enter first name" />
                    </Form.Item>
                    <Form.Item 
                        name="lastName"
                        label="Last Name" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item" 
                        rules={[{ required: true, message: 'Please enter your last name' }]}
                    >
                        <Input placeholder="Enter last name" />
                    </Form.Item>
                    <Form.Item 
                        name="address1" 
                        label="Address 1" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item" 
                        rules={[{ required: true, message: 'Please enter your address' }]}
                    >
                        <Input placeholder="Enter address number" />
                    </Form.Item>
                    <Form.Item 
                        name="address2"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        label="Address 2 (Optional)"
                    >
                        <Input placeholder="Enter account number" />
                    </Form.Item>
                    <Flex horizontal="true" gap="middle">
                        <Form.Item 
                            name="city" 
                            label="City" 
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="custom-form-item"
                            style={{ width: '50%' }}
                            rules={[{ required: true, message: 'Please enter your city' }]}
                        >
                            <Input placeholder="Enter City" />
                        </Form.Item>
                        <Form.Item 
                            name="state" 
                            label="State/Province" 
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="custom-form-item"
                            style={{ width: '50%' }}
                            rules={[{ required: true, message: 'Please select your state/province' }]}
                        >
                            <Select placeholder="Select">
                            {statesOrProvinces.map(state => (
                                    <Option key={state.value} value={state.value}>
                                    {state.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Flex>
                    <Form.Item 
                        name="zip" 
                        label="Zip/Postal Code" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        style={{ width: '70%' }}
                        rules={[{ required: true, message: 'Please enter your zip/postal code' }]}
                    >
                        <Input placeholder="Enter Zip/Postal Code" />
                    </Form.Item>
                    <Form.Item 
                        name="nameOnCard"   
                        label="Name on card"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        rules={[{ required: true, message: 'Please enter the name on card' }]}
                    >
                        <Input placeholder="Enter name on card" />
                    </Form.Item>
                    <Form.Item 
                        name="cardNumber"
                        label="Card number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        rules={[{ required: true, message: 'Please enter your card number' }]}
                    >
                        <Input placeholder="1234 1234 1234 1234" />
                    </Form.Item>
                    <Flex horizontal="true" gap="middle">
                        <Form.Item
                            name="expiry"
                            label="Expiry"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="custom-form-item"
                            style={{ width: '120px' }}
                            rules={[{ required: true, message: 'Please enter expiry date' }]}
                        >
                            <Input placeholder="MM / YYYY" />
                        </Form.Item>
                        <Form.Item 
                            name="cvv"
                            label="CVV"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="custom-form-item"
                            style={{ width: '120px' }}
                            rules={[{ required: true, message: 'Please enter CVV' }]}
                        >
                            <Input type="password" placeholder="•••" />
                        </Form.Item>
                    </Flex>
                    <Form.Item
                      name="isSavecards"
                      valuePropName="checked"
                      wrapperCol={{ span: 24 }}
                      className="custom-form-item checkox-label"
                      style={{ marginTop: 10 }}
                      // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                        },
                      ]}
                    >
                      <Checkbox>
                        Save for future use
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="terms"
                      valuePropName="checked"
                      wrapperCol={{ span: 24 }}
                      className="custom-form-item checkox-label"
                      style={{  }}
                      // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                        },
                      ]}
                    >
                      <Checkbox>
                        I have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
                      </Checkbox>
                    </Form.Item>
        
                    <Flex vertical gap="middle" style={{ marginTop: 25 }}>
                        <Button type="primary" size="large" htmlType="submit" style={{ width: '100%', fontWeight: 600 }}>Accept</Button>
                        <Button onClick={()=>SetUnLockHandler(false)} type="text" size="large" style={{ width: '100%', fontWeight: 600 }}>Cancel</Button>
                    </Flex>
                </Form>
            </Flex>
            </Spin>
            <div className="billing-inner-footer" style={{ paddingTop: 20, paddingBottom: 10 }}>
              <FooterComponent />
            </div>
        </Flex>
    );
}

export default Payment;