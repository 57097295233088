import React from 'react';
import { Layout, Typography, Button, Flex, Image } from 'antd';
import Img from "../../images/application-img.png";
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Title, Paragraph } = Typography;


const Confirmation = () => {
    return (
        <Content style={{ paddingTop: 0, paddingBottom: 20 }}>
            <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
                <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>Application submitted!</Title>
                <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>Your application has been received and the our team will follow up with next steps for getting started. In the case that you need to contact us, please feel free to email us at talent@blonde.social.</Paragraph>
			    <Paragraph style={{ textAlign: 'center' }}>While you are waiting, check out who is trending on blonde.</Paragraph>
			    <Flex
		            vertical
		            gap="small"
		            style={{
		              width: '100%',
		            }}
		        >
			    	<Link to="/"><Button size="medium" block style={{ marginTop: '20px', paddingTop: '10px', paddingBottom: '10px', height: 'auto', fontWeight: '600' }}>Return Home</Button></Link>
			    </Flex>
			  	<Flex vertical gap="large" style={{ marginTop: '30px' }}>
				    <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={60}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
			        <Flex horizontal="true" style={{ border: '1px solid #D0D5DD', borderRadius: '10px', padding: '20px' }}>
					    <Flex vertical>
					    	<Image
			                    width={56}
			                    src={Img}
			                    preview={false}
			                />
					    </Flex>
					    <Flex vertical style={{ width: '100%', paddingLeft: '1rem' }}>
					        <Title level={4} style={{ fontWeight: '600', fontSize: '18px', marginTop: 0 , marginBottom: 5 }}>Olivia Rhye</Title>
						    <Flex horizontal="true" align="center" justify="space-between">
					        	<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>1,000 Fans</Paragraph>
					        	<Paragraph style={{ fontSize: '12px', marginBottom: 0 }}>Los Angeles, CA</Paragraph>
					        </Flex>
				        </Flex>
			        </Flex>
		        </Flex>
        	</Flex>
        </Content>
    );
}

export default Confirmation;
