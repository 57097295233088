import React, { useState,useEffect } from 'react';
import { Form, Input, Button, Steps, Typography, Image, Upload, Avatar, Layout, Checkbox, Flex, Select, Spin, message } from 'antd';
import { useNavigate, Link, useLocation,useParams } from 'react-router-dom';
import { useMask } from "@react-input/mask";
import dayjs from 'dayjs';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import '../../../components/StepFormSignup/StepForm.css';
import CodeInput from '../../../components/CodeInput';
import AccountCreated from '../../../components/AccountCreated';
import { post,get } from '../../../utils/api';
import useScreenSize from '../../../utils/useScreenSize';
import AvatarImage from '../../../components/AvatarImage';
import { extractImageIds,imageUrl ,getInitials} from '../../../utils/utils';
import VerifyStep from '../../../components/VerifyStep/VerifyStep';
import UploadIcon from '../../../images/upload-icon.svg';
import ShareIcon from "../../../images/share-icon.svg";
import CheckSMIcon from "../../../images/check-sm.svg";
import PhoneIcon from '../../../images/phone-icon.svg';
import CheckSMSuccess from '../../../images/check-sm-green.svg';
import CrossSMIcon from '../../../images/x-circle.svg';
import CheckIcon from '../../../images/check-icon.svg';

const { Step } = Steps;
const { Title, Paragraph } = Typography;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Actor', 'Activist', 'Agency', 'Automotive', 'Artist', 'Beauty', 'Business', 'Blogger', 'Celebrity', 'Consulting', 'Education', 'Entertainment', 'Fashion', 'Film', 'Finance', 'Fitness', 'Food', 'Gaming', 'Government', 'Health & Wellness', 'Hospitality', 'Lifestyle', 'Management', 'Manufacturing', 'Media', 'Model', 'Motivation', 'Musician', 'Photographer', 'Pets', 'Politics', 'Real estate', 'Social Work', 'Sports', 'Travel', 'Tech', 'Vlogger', 'Videographer'];

const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;


const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 50;
const years = Array.from({ length: 51 }, (_, i) => startYear + i);

const getDaysInMonth = (month, year) => {
  return dayjs(`${year}-${month}-01`).daysInMonth();
};


const TeamSignup = () => {
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const [code, setCode] = useState('');
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [hearAboutUs, sethearAboutUs] = useState(null);
  const [formData, setFormData] = useState({});
  const tenYearsAgo = dayjs().subtract(18, 'year');
  const navigate = useNavigate();
  const [ loginValue, setLoginValue ]=useState(null);
  const [date, setDate] = useState(null);
  const maxDate = dayjs();
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [formErrors, setFormErrors] = useState([]);
  const [ assignedUrl, setAssignedUrl ]=useState(null);
  const [ uploadedIds, setUploadedIds ] = useState([]);
  const [profileImage,setProfileImage]=useState(null);
  const [firstName,setFirstName]=useState(null);
  const [lastName,setLastName]=useState(null);
  const [userEmail,setUserEmail]=useState(null);
  const [ step, setStep ]=useState(0);

  const [isLessthenEight, setIsLessthenEight] = useState(false);
  const [isOneSpecial, setIsOneSpecial] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isConfirmValidPassword, setIsConfirmValidPassword] = useState(false);
  
  const { creatorId ,teamEmail} = useParams();
  const [data,setData]=useState(false);

  useEffect(()=>{
    const init=async()=>{
        try {
          setLoading(true);
          const response = await get(`auth/creatorInfo/${creatorId}`);
          if(response && response.data){
            setData(response.data);
          }
          const teamsresponse = await get(`teams/info/${teamEmail}`);
          if(teamsresponse && teamsresponse.data){
            
            setUserEmail(teamsresponse?.data?.email);
          }
        }catch(error){
          console.log('error',error);
        }finally {
          setLoading(false);
        }
      };
      init();
  },[creatorId]);


  const onChange = (list) => {
    setCheckedList(list);
  };
  const handleChange=(value)=>{
    sethearAboutUs(value);
  }
  const extractNumbers = (input) => {
    const numbersOnly = input.replace(/\D/g, ''); // Remove non-numeric characters
     return numbersOnly.slice(0, 10);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };



  const validatePhoneNumber = (_, value) => {
    const strippedValue = value.replace(/\D/g, '');
    console.log(strippedValue, 'value')
    if (strippedValue.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10-digit phone number!'));
  };

  const inputRef = useMask({
    mask: "(___) ___-____",
    replacement: { _: /\d/ },
  });

  const inputCalendarRef = useMask({
    mask: "dd-mm-yyyy",
    replacement: { _: /\d/ },
  });

  const checkUsernameAvailability = async (_, value) => {
    try {
      const response = await get(`auth/check-username/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };


  const checkEmailAvailability = async (_, value) => {
    try {
      const response = await get(`auth/checkemail/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };

  const handleDateChange = (date) => {
    if (date && dayjs(date).isBefore(maxDate, 'day')) {
      setDate(date);
      const formattedDate = dayjs(date).format('DD-MM-YYYY');
      form.setFieldsValue({ birthday: formattedDate });
    } else {
      message.error('The selected date cannot be in the future.');
    }
  };

  const handleDateInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length >= 2 && value.length <= 4) {
      value = value.replace(/(\d{2})(\d{1,2})/, '$1-$2');
    } else if (value.length > 4) {
      value = value.replace(/(\d{2})(\d{2})(\d{1,4})/, '$1-$2-$3');
    }

    const parsedDate = dayjs(value, 'DD-MM-YYYY', true).toDate();
    if (dayjs(parsedDate).isBefore(maxDate, 'day')) {
      setDate(parsedDate);
      form.setFieldsValue({ birthday: value });
    }
  }; 

  const clearDate = () => {
    setDate(null);
    form.setFieldsValue({ birthday: '' });
  };

  const validateDate = (_, value) => {
    if (!value || !value.day || !value.month || !value.year) {
      return Promise.reject(new Error('Please enter a valid date!'));
    }
    const { day, month, year } = value;
    const daysInMonth = getDaysInMonth(month, year);
    
    if (day < 1 || day > daysInMonth) {
      return Promise.reject(new Error(`Invalid day for the selected month! ${daysInMonth} days available.`));
    }
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      return Promise.reject(new Error('Date of birth cannot be in the future!'));
    }
    return Promise.resolve();
  };

  const verifictionHandller=async(values)=>{
    const currentStepData = await form.validateFields();
  
    if(currentStepData){
      setLoading(true);
      try{
        const otpCode = code.join("");
        const response = await post('auth/login-verify-email',{otp:otpCode,loginValue});
          if (response && response.status === 200) {
            const token = response.token;
            localStorage.setItem('token', token);
            setStep(3);
          }else {
            message.error(response.message || 'The code you entered is incorrect. Please try again.');
          }
      }catch(error){
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'The code you entered is incorrect. Please try again.');
        } else {
          message.error(error.message || 'The code you entered is incorrect. Please try again.');
        }
      }finally {
        setLoading(false);
      }
      
    }
  }

  const resendOtp= async()=>{
     
    setLoading(true);
    try {
      const response = await post('auth/login-send-email',{
        "email":userEmail
    });
      if (response && response.status === 200) {
        message.success('OTP has been sent to your registered email.');
        setStep(2);
      } else {
        message.error(response.message || 'Login failed');
      }

    }catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    }finally {
      setLoading(false);
    }
  }


  React.useEffect(() => {
    form.setFieldsValue({ email: userEmail });
  }, [userEmail, form]);



  const steps = [
    {
      title: 'Step 1',
      content: (<>
        <Form.Item
          name="firstname"
          label="First name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your first name!' }]}
          className="custom-form-item"
          
        >
          <Input placeholder="Enter your first name" onChange={(e)=>setFirstName(e.target.value)} style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your last name!' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter your last name" onChange={(e)=>setLastName(e.target.value)} style={{ padding: '7px 10px' }} />
        </Form.Item>
      
        <Form.Item
          name="email"
          label="Confirm Email"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, type: 'email' },
            { validator: checkEmailAvailability, message: 'Email is already registered.' }]}
          className="custom-form-item"
           
          
        >
          <Input placeholder="Enter your email"  disabled={true} onChange={(e)=>setUserEmail(e.target.value)}  style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your phone number!' },{ validator: validatePhoneNumber }]}
          className="custom-form-item"
        >
          <input className="ant-input phone-input" ref={inputRef} placeholder="(___) ___-__-__" />

        </Form.Item>
        <Form.Item
          name="birthday"
          label="Confirm your age"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="cst-datepicker"
          rules={[
            { required: true, message: 'Please enter your birthday!' },
            { validator: validateDate },
          ]}
        >
          <Flex gap={10}>
            <Form.Item name={['birthday', 'day']} style={{ flex: '1 1 25%', marginBottom: 0 }}>
              <Select placeholder="Day">
                {days.map(day => (
                  <Select.Option key={day} value={day}>{day}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'month']} style={{ flex: '1 1 45%', marginBottom: 0 }}>
              <Select placeholder="Month">
                {months.map((month, index) => (
                  <Select.Option key={index + 1} value={index + 1}>{month}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'year']} style={{ flex: '1 1 30%', marginBottom: 0 }}>
              <Select placeholder="Year">
                {years.map(year => (
                  <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item
            name="terms"
            valuePropName="checked"
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
              },
            ]}
          >
            <Checkbox>
              I agree to the <a href="/">Terms of Use</a>
            </Checkbox>
          </Form.Item>
      </>),
    },
    {
      title: 'Step 2',
      content: (<>
        <div className="avatar-container">
        {profileImage? <Avatar size={165} src={profileImage} />: <Avatar size={96} className="rounded-avatar">
                    {getInitials(`${firstName} ${lastName}`)}
                  </Avatar>}    
        </div>
        <Dragger
          multiple={false}
          name="singleImage"
          action={`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`}
          onChange={async (info)=>{
            const { status } = info.file;
            if (status !== 'uploading') {
              console.log(info.file, info.fileList);
            }
            if (status === 'done') {
              message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
              message.error(`${info.file.name} file upload failed.`);
            }
            const {fileList}=info;
            const getIds=extractImageIds(fileList);
            if(getIds){
              const url=await imageUrl(getIds[0]);
              setProfileImage(url);
              setUploadedIds(getIds);
            }
          }}
        >
            <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
              <Image src={UploadIcon} size={40} preview={false} />
            </p>
            <p className="ant-upload-text" style={{ fontSize: '14px' }}><span style={{ fontWeight: 600, color: '#6941C6' }}>Click to upload</span> or drag and drop</p>
            <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </Dragger>
        {assignedUrl && 
        <Form.Item
          name="blondehandle"
          label="Blonde handle"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your tiktok username!' }]}
          className="custom-form-item"
          initialValues={assignedUrl}
        >
          <Input 
            addonAfter={
              <Button type="text">
                  <Image 
                    width={16} 
                    src={ShareIcon} 
                    preview={false}
                  />
                  <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Share</span>
              </Button>} 
            placeholder="Blonde handle" 
            style={{ padding: '7px 0px' }} 
            className="share-input"
            defaultValue={assignedUrl}
            readOnly={true}
          />
        </Form.Item>}

        <Form.Item
              name="password"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="custom-form-password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                }
              ]}
              // hasFeedback
            >
              <Input.Password placeholder="Create a password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-password"
            // hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
        </Form.Item>

        <div className="password-criteria">
            {isLessthenEight ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CrossSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span style={{ color: '#d92d20' }}>Must be at least 8 characters</span>
              </div>
              : isValidPassword ?
                <div className="criteria-item">
                  <Image
                    width={25}
                    src={CheckSMSuccess}
                    preview={false}
                    style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                  />
                  <span>Must be at least 8 characters</span>
                </div>
              :
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must be at least 8 characters</span>
              </div>
            }
            {isOneSpecial ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CrossSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span style={{ color: '#d92d20' }}>Must contain one special character</span>
              </div> 
              : isConfirmValidPassword ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMSuccess}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must contain one special character</span>
              </div> 
              : 
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must contain one special character</span>
              </div> 
            }
        </div>
      </>),
    },
  ];



  const next = () => {
    form.validateFields().then((currentStepData) => {
      
      setFormData({ ...formData, ...currentStepData });
      setCurrent(current + 1);
     
    }).catch((info) => {
      console.log('Validation failed:', info);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const finalStepData = await form.validateFields();

      const formatDate = ({ day, month, year }) => {
        // Ensure day and month are always two digits
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        
        // Return formatted date string
        return `${formattedDay}-${formattedMonth}-${year}`;
      };

      // Example usage
      const birthday = formData?.birthday;
      const formattedDate = formatDate(birthday);

      const completeFormData = { ...formData, ...finalStepData, birthday:formattedDate, interests: checkedList,hearAboutUs,code:code,uploadedIds,creatorId };
    
      const response = await post('teams/register', completeFormData);
      if (response && response.status === 200) {
        setStep(1);
        setLoginValue({email:userEmail,password:values?.password});
      }else{
        message.error(response.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message || 'Error submitting form');
      } else {
        message.error(error.message || 'Error submitting form');
      }
    } finally {
      setLoading(false);
    }
  };
  const imageId = data && data.uploadedIds && data.uploadedIds.length > 0 ? data.uploadedIds[0] : null;
  return (
    <Spin size="small" spinning={loading}>
      <Content style={{ padding: 0 }}>
        <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
          {step==0 &&
          <Form form={form} onFinish={handleSubmit} onFieldsChange={(_, allFields) => {
            const passwordField = allFields.find(field => field.name[0] === 'password');
            const confirmPasswordField = allFields.find(field => field.name[0] === 'confirm');
            
            const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/;
            const passwordValue = passwordField?.value;
            const confirmPasswordValue = confirmPasswordField?.value;
    
            if ((passwordValue && passwordValue.length < 8) || (confirmPasswordValue && confirmPasswordValue.length < 8)) {
              setIsLessthenEight(true);
            } else {
              setIsLessthenEight(false);
            }
    
            const isPasswordValid = passwordValue && hasSpecialCharacter.test(passwordValue);
            if (passwordValue && passwordValue.length > 0 && !isPasswordValid) {
              setIsOneSpecial(true);
            } else {
              setIsOneSpecial(false);
            }
    
            if (passwordValue && passwordValue.length >= 8) {
              setIsValidPassword(true);
            } else {
              setIsValidPassword(false);
            }
    
            if (passwordValue && isPasswordValid) {
              setIsConfirmValidPassword(true);
            } else {
              setIsConfirmValidPassword(false);
            }
    
          }} 
          
          validateMessages={validateMessages}>

          {current === 0 && <>
            <Flex vertical align="center" style={{ marginBottom: 20, }}>
               <AvatarImage imageId={imageId} size={64} title={`${data?.first_name} ${data?.last_name}`} />
              <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px' }}>{data?.first_name} {data?.last_name}</Title>
            </Flex>
            <Title level={2} style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}>Join {data?.user_name} Team</Title>
          </>}
            {current === 0 && <>
              <Title level={5} style={{ textAlign: 'center', marginTop: isMobile ? '0px' : '40px', marginBottom: '10px', fontSize: '14px' }}>Referral Code (If you have one)</Title>
              <CodeInput setCode={setCode} />
            </>}
            {current === 1 &&
            <Title level={2} style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}>Welcome to Blonde!</Title>
            }
            <div className="steps-content">
              {steps[current].content}
            </div>
            <div className="steps-action" style={{ marginTop: '24px' }}>
              <Flex
                  vertical
                  gap="middle"
                  style={{
                    width: '100%',
                  }}
                >
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()} size="large" style={{ fontWeight: 600 }}>
                      Continue
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" htmlType="submit" size="large" style={{ fontWeight: 600 }}>
                      Continue
                    </Button>
                )}
                {current > 0 && (
                    <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => prev()} size="large" style={{ fontWeight: 600, fontSize: '14px' }}>
                      Back
                    </Button>
                )}
              </Flex>
            </div>

            {isMobile &&
                <Flex vertical style={{ marginTop: 20, textAlign: 'center' }}>
                  {location.pathname === '/signup' ?
                    <Paragraph>
                      Already have an account? <Link to="/" style={{ fontWeight: 600 }}>Log in</Link>
                    </Paragraph>
                  : 
                    <Paragraph>
                      Don’t have an account? <Link to="/signup" style={{ fontWeight: 600 }}>Sign up</Link>
                    </Paragraph>
                   }
                </Flex>
              }
          </Form>}
          {step===1 && (
             <div className="setpfrm">
                  <VerifyStep userEmail={userEmail} setStep={setStep}/>
             </div>
          )}
          {step===2 && (
            <div className="setpfrm">
               <Flex
                    vertical
                    align="center"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Image
                        width={60}
                        src={PhoneIcon}
                        preview={false}
                    />
                  </Flex>

                  <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>2-step verification</Title>
                  <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Enter the 2-step verification code we texted to your phone</Paragraph>

                  <Form
                    name="verificationStepOne"
                    form={form}
                    onFinish={verifictionHandller}
                  >
                    <CodeInput setCode={setCode} /> {/* Use the CodeInput component */}
                    <Form.Item>
                      <Button type="primary" size="large" htmlType="submit" block style={{ fontWeight: 600 }}>
                        Submit
                      </Button>
                    </Form.Item>

                  </Form>
                  <Button onClick={()=>{ setStep(1)}} size="large" block style={{ fontWeight: 600 }}>Back</Button>
                  <Paragraph style={{ textAlign: 'center', marginTop: '30px' }}>Didn’t receive the email? <Button loading={loading} className='resendOtp' onClick={resendOtp}>Click to resend</Button></Paragraph>

              </div>
            )}

             {step===3 && (
                <div className="setpfrm">
                  <Flex
                    vertical
                    align="center"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Image
                        width={60}
                        src={CheckIcon}
                        preview={false}
                    />
                  </Flex>
                  <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>Account created!</Title>
                   <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Text an invite to add a fellow creator or friend to your network.</Paragraph>
                    <Link to={'/'}><Button type="primary" size="large">Continue</Button></Link>
                </div>
             )}
        </Flex>
      </Content>
    </Spin>
  );
};

export default TeamSignup;
