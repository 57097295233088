import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import RouteHandler from './RouteHandler';

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#7f56d9',
          colorLink: '#7f56d9',
          colorText: '#475467',
          fontFamily: 'Inter, sans-serif',
          colorTextHeading: '#101828',
          colorBgContainer: '#ffffff',
          colorBgLayout: '#ffffff',
          colorError: '#D92D20',
        }
      }}
    >
      <Layout className="layout">
         <RouteHandler/>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
