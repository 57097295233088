import React, { useState } from 'react';
import { Layout, Flex, Typography, Button, Form, Input, Menu,AutoComplete, Checkbox, Divider, Radio, Slider, Dropdown } from 'antd';
import { CloseOutlined, EditOutlined, CloseCircleOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { get ,post} from '../../../utils/api';
import ChatInput from '../../GlobalComponents/ChatInput';
const { Title, Text } = Typography
const { Content } = Layout;

const NewMessage = ({ handleNewMessageClose,fetchData }) => {
  const [form] = Form.useForm();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [selectedRecipient, setSelectedRecipient] = useState('');
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const [optionValue, setOptionValue] = useState(null);
    const [optionId, setOptionId] = useState(null);
    const [message, setMessage] = useState('');
    const [isMessageSending, setIsMessageSendingg] = useState(false);  

    const sendMessageHandler = async (values) => {
      setIsMessageSendingg(true);
      try {
        const completeFormData = {selectedCreator:{id:optionId},message};
        const response = await post(`creator/my-network/sendMessage`, completeFormData);
        if (response && response.status) {
          await fetchData();
          form.resetFields();
          setMessage('');
          handleNewMessageClose();
        }
      } catch (error) {
        console.log("loading......",values);
      } finally {
        setIsMessageSendingg(false);
      }
    };

    const handleInputChange = (value) => {
      setMessage(value);
    };

    const handleFilterVisibleChange = (flag) => {
      setIsFilterVisible(flag);
    };

    const handleMenuClick = (e) => {
      setSelectedRecipient(e.key);
      setIsFilterVisible(false);
    };

    const clearSelectedRecipient = () => {
      setSelectedRecipient('');
    };

    const onSelect=async(value,option)=>{
      setOptionValue(option?.label);
      setOptionId(value);
    }

    const handleSearch = async (value) => {
      value=(typeof value === 'string')?value:'';
        const response = await get(`creator/my-network/autocomplete?s=${value}`);
       
        setOptions(response);
    };
  

   

    return (
      <Content className="new-message" style={{ backgroundColor: '#F9FAFB'}}>
        <Form  form={form} onFinish={sendMessageHandler} layout="vertical" style={{ height: '100vh', display: 'flex', flexDirection: 'column'  }}>
          <Flex horizontal="true" className="head" justify="space-between">
            <Title level={4}>New Message</Title>
            <Button
              type="link"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={handleNewMessageClose}
              style={{ alignSelf: 'flex-end' }}
            >
            </Button>
          </Flex>

          <Flex vertical style={{ flex: 1, overflowY: 'auto' }}>
            
              <Flex gap={15} vertical style={{ padding: '30px 30px' }}>
                <Form.Item className="custom-form-item">
                  <Flex gap="middle" horizontal>
                 
                    <AutoComplete
                        onFocus={handleSearch}
                        options={options}
                        onSearch={handleSearch}
                        onChange={(value) => setOptionValue(value)}
                        style={{ width: '100%' }}
                        onSelect={onSelect}
                        value={optionValue}
                      >
                        <Input.Search addonBefore={<Text>To:</Text>} placeholder="Enter creator name" />
                    </AutoComplete>
                  </Flex>
                </Form.Item>

                <Form.Item
                    name="messagebox"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: !message, message: 'Please input your message!' }]}
                    className="custom-form-item"
                  >
                     <ChatInput  isShow={false} onInputChange={handleInputChange} setPriceButton={false} setSendButton={false} setLoading={isMessageSending} />
                  </Form.Item>

              </Flex>
          </Flex>

          <Flex vertical className="form-footer">
            <Flex
                horizotanl
                justify="end"
                gap="middle"
                style={{
                  width: '100%',
                }}
              >
              <Button onClick={handleNewMessageClose} style={{ fontWeight: 600, border: '0 none' }} size="large">Cancel</Button>
              <Button htmlType='submit' type="primary" size="large" style={{ fontWeight: 600 }}>Send</Button>
            </Flex>
          </Flex>


        </Form>
      </Content>
    );
}

export default NewMessage;
