import React from 'react';
import { Layout, Typography, Flex, List, Avatar, Space } from 'antd';
import { RightOutlined, UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const notifications = [
  {
    name: 'Jessica Turner',
    time: '2 mins ago',
    content: 'Purchased a content',
    price: '$25.00',
  },
  {
    name: 'Candice Wu',
    time: '3 hours ago',
    content: 'Wants to Connect',
  },
  {
    name: 'John Doe',
    time: '3 hours ago',
    content: 'Joined your Team',
  },
  {
    name: 'Candice Wu',
    time: '3 hours ago',
    content: 'Joined your Team',
  },
  {
    name: '',
    phone: '+1 (999) 999-0000',
    time: '6 hours ago',
    content: 'Purchased a content',
    price: '$25.00',
  },
  {
    name: 'Jessica Turner',
    time: '6 hours ago',
    content: 'Joined Blonde',
  },
  {
    name: '',
    phone: '+1 (999) 999-0000',
    time: '6 hours ago',
    content: 'Purchased a content',
    price: '$25.00',
  },
];

const Notification = () => {
  return (
    <Layout>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Flex className="notification-head-area" horizontal justify="space-between" align="center">
            <Title level={4}>Notification Center</Title>
        </Flex>

        <List
          itemLayout="horizontal"
          dataSource={notifications}
          className="notification-list"
          renderItem={(item) => (
            <List.Item
              actions={[<RightOutlined />]}
            >
              <List.Item.Meta
                avatar={<Avatar size={48} style={{ background: '#98A2B3', fontSize: 20 }}>{item.name ? item.name.charAt(0) : <UserOutlined />}</Avatar>}
                title={
                  <Space>
                    <Text strong>{item.name ? item.name : item.phone }</Text>
                    <Text type="secondary">{item.time}</Text>
                  </Space>
                }
                description={
                  <div>
                    <Text>{item.content}</Text>
                    {item.price && <Text strong>, {item.price}</Text>}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Space>
    </Layout>
  );
};

export default Notification;
