import { Layout, Flex, Typography, Tabs } from 'antd';

import Notifications from '../../../components/Settings/Notifications';
import Password from '../../../components/Settings/Password';

const { Title } = Typography;

const TeamSettings = () => {

    const onChange = (key) => {
      console.log(key);
    };

    const items = [
      {
        key: '1',
        label: 'Notifications',
        children: <Notifications />,
      },
      {
        key: '2',
        label: 'Password',
        children: <Password />,
      }
    ];

    return(
        <Layout style={{ padding: 30 }}>
            <Flex className="content-head-area" horizontal justify="space-between" align="center">
                <Title level={2} style={{ marginBottom: '25px' }}>Settings</Title>
            </Flex>
            <Tabs className="settings-tabs" defaultActiveKey="1" items={items} onChange={onChange} />
        </Layout>
    );
};

export default TeamSettings;