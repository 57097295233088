// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkox-label .ant-checkbox {
    align-self: flex-start;
    margin-top: 2px;
}
.billing-inner-footer footer.ant-layout-footer {
    padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Audience/Payment/Payment.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".checkox-label .ant-checkbox {\n    align-self: flex-start;\n    margin-top: 2px;\n}\n.billing-inner-footer footer.ant-layout-footer {\n    padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
