import React, { useState,useEffect } from 'react';
import { Layout, Tabs, Card, Button, List, Flex, Typography, Input, Divider, Tooltip, Image } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CreditCardOutlined, MailOutlined, InfoCircleOutlined } from '@ant-design/icons';
import AddNewCard from './AddNewCard';
import { formatPrice } from '../../../utils/utils';
import { get } from '../../../utils/api';
import Icon1 from '../../../images/visa-icon.png';
import Icon2 from '../../../images/mastercard-icon.png';
import './Billing.css';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

const Billing = () => {
  const [showAddCard, setShowAddCard] = useState(false);

  const [myrequests,setMyrequests]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
    useEffect(()=>{
      const init=async()=>{
        setIsLoading(true);
        try{
          const response=await get('user/billingHistory');
          if(response.status){
           
            setMyrequests(response?.data);
          }
        }catch(error){
          console.log("error",error);
        }finally{
          setIsLoading(false);
        }
      }
      init();
    },[]);

  const cards = [
    {
      icon: <Image src={Icon1} width={46} />,
      type: 'Visa',
      ending: '1234',
      isPrimary: true,
      isDefault: false,
    },
    {
      icon: <Image src={Icon2} width={46} />,
      type: 'Mastercard',
      ending: '1234',
      isPrimary: false,
      isDefault: true,
    },
  ];


  const handleNewCard = () => {
    setShowAddCard(true);
  };

  if (showAddCard) {
    return <AddNewCard goBack={() => setShowAddCard(false)} />;
  }

  return (
    <Layout style={{ padding: '20px 20px' }}>
      <Flex vertical align="start">
        <Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>Billing</Title>
        <Text style={{ fontSize: '16px' }}>Update your plan payment details.</Text>
        <Button type="primary" icon={<CreditCardOutlined />} onClick={handleNewCard} size="large" style={{ marginBottom: 25, marginTop: 20, fontWeight: 600 }}>
          New
        </Button>
      </Flex>
      <Tabs className="billing-tabs" defaultActiveKey="1">
        <TabPane tab="Cards" key="1">
          {cards.map((card, index) => (
            <Card key={index} style={{ marginBottom: '10px' }}>
              <Flex horizontal="true" gap="small">
                <Flex vertical>
                  {card.icon}
                </Flex>
                <Flex vertical>
                  <Text strong>{card.type} ending in {card.ending}</Text>
                  <div style={{ float: 'right' }}>
                    {card.isPrimary && <Text type="secondary" style={{ color: '#475467', fontWeight: 600 }}>Primary</Text>}
                    {card.isDefault && <Text type="secondary" style={{ color: '#475467', fontWeight: 600 }}>Set as default</Text>}
                    <Button type="link" style={{ color: '#6941C6', fontWeight: 600 }}>Edit</Button>
                    <Button type="link" icon={<DeleteOutlined />} style={{ color: '#D92D20', position: 'absolute', top: 5, right: 5, fontSize: '20px' }}></Button>
                  </div>
                </Flex>
              </Flex>
            </Card>
          ))}
          <Flex justify="flex-end">
            <Button type="text" icon={<PlusOutlined />} style={{ padding: 0, color: '#6941C6', fontWeight: 600 }}>
              Add payment method
            </Button>
          </Flex>

          <Divider />

          <Flex vertical>
            <Text strong style={{ marginBottom: 15 }}>Billing contact</Text>
            <Flex vertical>
              <Text style={{ marginBottom: 7 }}>Email address</Text>
              <Input
                value="accounts@untitledui.com"
                className="custom-form-item billing-email"
                prefix={<MailOutlined />}
                suffix={
                  <Tooltip title="Contact your billing administrator for any changes.">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </Flex>
          </Flex>

        </TabPane>
        <TabPane tab="History" key="2">
          <List
            dataSource={myrequests}
            renderItem={item =>{
              const {revenueUser}=item;
              return (<List.Item>
                <List.Item.Meta
                  title={<Text strong style={{ color: '#101828', fontSize: '18px' }}>{revenueUser?.first_name} {revenueUser?.last_name}</Text>}
                  description={item?.message}
                />
                <Flex vertical>
                  <Text strong style={{ fontSize: '20px', color: '#000000' }}>{formatPrice(item?.amount)}</Text>
                   
                  {item.payment_status === "0" ? (
                      <Text type="secondary" style={{ backgroundColor: '#FFFAEB', color: '#B54708', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #FEDF89', textTransform: 'capitalize' }}>Pending</Text>
                    ) : item.payment_status === "1" ?(
                      <Text type="secondary" style={{ backgroundColor: '#ECFDF3', color: '#067647', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #ABEFC6', textTransform: 'capitalize' }}>Completed</Text>
                    ) : (
                      <Text type="secondary" style={{ backgroundColor: '#f1f1f1', color: '#9c9c9c', borderRadius: 30, padding: '3px 10px', fontSize: '12px', fontWeight: 600, border: '1px solid #9c9c9c', textTransform: 'capitalize' }}>Decline</Text>
                    )}
                </Flex>
              </List.Item>)
            }}
          />
        </TabPane>
      </Tabs>

    </Layout>
  );
};

export default Billing;
