import React, { useState,useContext,useEffect,useRef } from 'react';
import { Layout, Tabs, Button, Input, List, Avatar, Typography, Flex, Form, Card, Divider,Image,Spin } from 'antd';
import { SearchOutlined, EditOutlined , ArrowLeftOutlined, RightOutlined} from '@ant-design/icons';
import socket from '../../../services/socket';
import { Link } from 'react-router-dom';
import { get,post } from '../../../utils/api';
import NewMessage from '../../../components/Message/Network/NewMessage';
import ChatInput from '../../../components/GlobalComponents/ChatInput';
import { UserContext } from '../../../contexts/UserContext';
import AvatarImage from '../../../components/AvatarImage';
import TimeAgo from '../../../components/TimeAgo';
import { processHtmlContent } from '../../../utils/utils';
import moment from 'moment';
import useScreenSize from '../../../utils/useScreenSize';
import ArrowRight from "../../../images/arrow-right.svg";
import UnlockIcon from "../../../images/unlock-icon.svg";
import GroupUserIcon from "../../../images/user-icon-white.svg";
import hand from "../../../images/hand.svg"
import '../message.css';

const { TabPane } = Tabs;
const { Search } = Input;
const { Title, Text } = Typography;
const { Sider, Content } = Layout;


const MessageNetwork = () => {
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const { isMobile, isTablet } = useScreenSize();
  const messagesEndRef = useRef(null);
  const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
  const [isReplyMessageVisible, setIsReplyMessageVisible] = useState(false);  
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [selectedFan, setSelectedFan] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);  
  const [isMessageSending, setIsMessageSendingg] = useState(false);  
  const [chatHistory, setChatHistory] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const {user}  = useContext(UserContext);
  const [message, setMessage] = useState('');
  const incommingMessage = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const fetchData = async (params = {}) => {
    const response = await get(`creator/my-network/message`,{
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 10,
      q: params?.searchTerm ||'',
    });
   
    if (response && response.data) {
      console.log("response.data",response.data);
      setNetworkData(response.data);
      setPagination({
        ...params.pagination,
        total: response.pagination.total,
      });
    }
  };

    // Helper function to determine if we need to show the date
    const shouldShowDate = (index, date) => {
      if (index === 0) return true; // Always show date for the first message
      const prevDate = moment(chatHistory[index - 1].updatedAt).startOf('day');
      const currDate = moment(date).startOf('day');
      return !prevDate.isSame(currDate); // Show date if different from the previous message
    };
    const formatDate = (date) => {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      const messageDate = moment(date).startOf('day');
  
      if (messageDate.isSame(today)) return 'Today';
      if (messageDate.isSame(yesterday)) return 'Yesterday';
      return messageDate.format('MMMM D, YYYY'); // Format as needed
    };

  const handleClose = () => {
    setSelectedMessage(false);
  };
  const getUserDetails=async()=>{
    setSelectedFan(true);
  }


  const sendMessageHandler = async (values) => {
   
    setIsMessageSendingg(true);
    try {
      const completeFormData = {selectedCreator,message};
      const response = await post(`creator/my-network/sendMessage`, completeFormData);
      console.log("responseresponse",response);
      if (response && response.status) {
     
        const UpdateData = [...chatHistory, response?.data];
        setChatHistory(UpdateData);
        setMessage('');
        form.resetFields();
      }
    } catch (error) {
      console.log("loading......",values);
    } finally {
      setIsMessageSendingg(false);
    }
  };

  const handleSelectMessage = async(item) => {
    const creator = user?.id === item?.from_id ? item?.toUser : item?.fromUser;
    setSelectedCreator(creator);
    setSelectedMessage(item);
    setIsNewMessageVisible(false);
    setSelectedFan(false);
    setIsHistoryLoading(true);
      try{
          const response = await post(`creator/my-network/networkMessageLogs`,{
            ctr:item?.id
          });
          if(response && response.status){
            setChatHistory(response?.data?.rows);
          }
        }catch(error){
          console.log("loading......");
        } finally {
          setIsHistoryLoading(false);
      }
 };


 const updateOrAppendMessage = (data, newMessage) => {
  //Check if the user_phone exists in the data array

  const index = data.findIndex(item => item?.to_id === newMessage?.to_id);
  
  if (index !== -1) {
    // If user_phone exists, update the last_message
    data[index] = {
      ...data[index],
      last_message: newMessage.last_message,
      updatedAt: new Date().toISOString()
    };
  } else {
    // If user_phone does not exist, append the newMessage to the array
    data.push(newMessage);
  }
  data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  return [...data]; // Return a new array to trigger re-render
};

  useEffect(() => {
    if(selectedMessage){
      fetchData({searchTerm:searchText}); 
    }else{
      fetchData(); 
    }
  

    const handleNewMessage =async (message) => {
      if (message && message.status) {
        const { output } = message;

        if (output?.to_id === user?.id || output?.from_id === user?.id) {
          setNetworkData(prevData => updateOrAppendMessage(prevData, output));
        }
       
        if(selectedMessage && output?.id){
       
          const response = await post(`creator/my-network/networkMessageLogs`,{
            ctr:selectedMessage?.id
          });
          
          if(response && response.status){
            setChatHistory(response?.data?.rows);
          }
        }
      }
    };

    socket.on('networkMessage', handleNewMessage);

    // Clean up on component unmount
    return () => {
      socket.off('networkMessage', handleNewMessage);
    };
  }, [user,selectedMessage]);


  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleInputChange = (value) => {
    setMessage(value);
  };

  const showNewMessage = () => {
    setIsNewMessageVisible(true);
    setSelectedMessage(false);
    setIsReplyMessageVisible(false);
  };

  const handleNewMessageClose = () => {
    setIsNewMessageVisible(false);
  };





  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={370} style={{ backgroundColor: '#ffffff', borderRight: '1px solid #EAECF0', height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div className="head-area" style={{ padding: '20px' }}>
          <Title level={2}>Messages</Title>
        </div>

        <Flex horizontal="true" className="message-tabs">
          <Flex className="ant-tabs-nav">
            <Flex className="ant-tabs-nav-wrap">
              <Flex className="ant-tabs-nav-list">
                <Flex className="ant-tabs-tab">
                  <Link to="/message/audience"><Text>Audience</Text></Link>
                </Flex>
                {/*<Flex className="ant-tabs-tab ant-tabs-tab-active">
                  <Link to="/message/network"><Text>Network</Text></Link>
                </Flex>*/}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap={15} vertical style={{ paddingLeft: 20, paddingRight: 20, borderBottom: '1px solid #EAECF0' }}>
          <Button size="large" type="primary" style={{ textAlign: 'center', fontWeight: '600' }} icon={<EditOutlined />} onClick={showNewMessage}>New Message</Button>
          <Form.Item 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input 
              className="search-input"
              placeholder="Search audience messages" 
              //onSearch={handleSearch}
              prefix={<SearchOutlined />} 
              style={{ marginBottom: 0 }} 
            />
          </Form.Item>
        </Flex>
        <List
          itemLayout="horizontal"
          dataSource={networkData}
          className="message-data"
          style={{ flex: 1, overflowY: 'auto' }}
          renderItem={(item) => {
            const creator = user?.id === item?.from_id ? item?.toUser : item?.fromUser;
            const imageId = creator?.uploadedIds?.length > 0 ? creator.uploadedIds[0] : null;
        
            return (
              <List.Item
                onClick={() => handleSelectMessage(item)}
                style={{
                  cursor: 'pointer',
                  background: item.id === selectedMessage?.id ? '#F9FAFB' : 'transparent',
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <List.Item.Meta
                  avatar={
                    <AvatarImage
                      imageId={imageId}
                      title={`${creator?.first_name || ''} ${creator?.last_name || ''}`}
                    />
                  }
                  title={
                    <Flex horizontal="true">
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {creator?.first_name || ''} {creator?.last_name || ''}
                      </span>
                      <span
                        style={{
                          color: '#475467',
                          marginLeft: 'auto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          minWidth: '120px',
                          textAlign: 'right',
                        }}
                      >
                        <TimeAgo timestamp={item.updatedAt} />
                      </span>
                    </Flex>
                  }
                  description={processHtmlContent(item.last_message)}
                />
              </List.Item>
            );
          }}
        />

      </Sider>

      {selectedMessage &&
        <Content style={ isMobile ? { backgroundColor: '#ffffff', position: 'absolute', width: '100%', left: 0, height: '100vh', display: 'flex', flexDirection: 'column' } : { height: '100vh', display: 'flex', flexDirection: 'column' }}>

          <Flex vertical align="start" style={{ backgroundColor: '#F9FAFB', padding: '20px 25px', borderBottom: '1px solid #EAECF0' }}>
            {isMobile && 
              <Button
                type="text"
                icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                onClick={handleClose}
                style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
              >
              Back
              </Button>
            }
            <Flex>
                <span onClick={getUserDetails} style={{ cursor: 'pointer' }}>
                  <Flex horizontal="true" gap="middle" align="center">
                    <AvatarImage  imageId={selectedCreator?.uploadedIds?.length > 0 ? selectedCreator.uploadedIds[0] : null} title={`${selectedCreator?.first_name || ''} ${selectedCreator?.last_name || ''}`} />
                    <Flex vertical>
                      <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                        {selectedCreator?.first_name} {selectedCreator?.last_name}
                      </Title>
                    </Flex>
                      <Image
                        width={20}
                        src={ArrowRight}
                        preview={false}
                      />
                  </Flex>
                </span>
            </Flex>
          </Flex>

          <>
            <div className='message-box' style={{ padding: '20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isHistoryLoading}>
                  {chatHistory && chatHistory.map((message,index) => ( 
                     <Flex vertical key={`message_${message.id}`} style={{ marginBottom: '10px' }}>
                        {shouldShowDate(index, message.updatedAt) && (
                          <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                            {formatDate(message.updatedAt)}
                          </Text>
                        )}
                          {message?.from_id===user?.id?
                            <Flex className="message-response" justify="flex-end">
                              <Text style={{ fontSize: '17px', backgroundColor: '#7F56D9', padding: '6px 14px', color: '#ffffff', borderRadius: 18, maxWidth: '75%' }}><div dangerouslySetInnerHTML={{ __html: message.message }} style={{ whiteSpace: 'pre-wrap' }} /></Text>
                            </Flex>
                            :
                            <Flex justify="flex-start" className='fan-message'>
                            <Text style={{ fontSize: '17px', backgroundColor: '#E5E5EA', padding: '6px 14px', color: '#000000', borderRadius: 18, maxWidth: '75%' }}> <div dangerouslySetInnerHTML={{ __html: message.message }} style={{ whiteSpace: 'pre-wrap' }} /></Text>
                          </Flex>
                          }
                     </Flex>
                  ))}
                   <div ref={messagesEndRef} />
                   {/* <audio ref={incommingMessage} src="/mp3/chatbeep.mp3" preload="auto"></audio> */}
              </Spin>
            </div>
            <Flex vertical style={{ padding: '12px 20px' }}>
              <Spin spinning={isMessageSending}>
                <Form form={form} onFinish={sendMessageHandler} className="messagebox-form">
                  <Form.Item
                    name="messagebox"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: !message, message: 'Please input your message!' }]}
                    className="custom-form-item"
                  >
                     <ChatInput  isShow={false} onInputChange={handleInputChange} setPriceButton={false} setSendButton={true} setLoading={isMessageSending} />
                  </Form.Item>
                  {/*<Button loading={isMessageSending} type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>Send</Button>*/}
                </Form>
              </Spin>
            </Flex>
          </>
          
        </Content>
      }

      {isNewMessageVisible &&
        <NewMessage fetchData={fetchData} handleNewMessageClose={handleNewMessageClose} />
      }

    </Layout>
  );
};

export default MessageNetwork;
