// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-area {
    display: flex;
    align-items: center;
}
.head-area h2{
	margin: 0;
}
.head-area .count {
    border: 1px solid #D0D5DD;
    border-radius: 7px;
    margin-left: 10px;
    width: 32px;
    text-align: center;
}
.request-card .ant-card-body h4 {
    margin: 0;
}
.request-card {
    height: 100%;
    background: transparent;
}
.request-card .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.request-card .head {
    background: #fff;
    padding: 20px 20px;
    border-bottom: 1px solid #D0D5DD;
    align-items: center;
}

.message-tabs.request-tabs .ant-tabs-nav {width: calc(100% - 40px);}`, "",{"version":3,"sources":["webpack://./src/pages/PrivateRequests/privaterequests.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;CACC,SAAS;AACV;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,SAAS;AACb;AACA;IACI,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA,0CAA0C,wBAAwB,CAAC","sourcesContent":[".head-area {\n    display: flex;\n    align-items: center;\n}\n.head-area h2{\n\tmargin: 0;\n}\n.head-area .count {\n    border: 1px solid #D0D5DD;\n    border-radius: 7px;\n    margin-left: 10px;\n    width: 32px;\n    text-align: center;\n}\n.request-card .ant-card-body h4 {\n    margin: 0;\n}\n.request-card {\n    height: 100%;\n    background: transparent;\n}\n.request-card .ant-card-body {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    padding: 0;\n}\n\n.request-card .head {\n    background: #fff;\n    padding: 20px 20px;\n    border-bottom: 1px solid #D0D5DD;\n    align-items: center;\n}\n\n.message-tabs.request-tabs .ant-tabs-nav {width: calc(100% - 40px);}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
