import React, { useState } from 'react';
import { Layout } from 'antd';
const UserPublicLayout=({ children })=>{

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
                {children}
            </div>
        </Layout>
      );
}

export default UserPublicLayout;