// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page:before {
    content: "";
    background: #F9FAFB;
    height: 99px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    border-bottom: 1px solid #EAECF0;
}

.profile-page {
    position: relative;
    z-index: 1;
}
.avatar-grid .ant-avatar {
    border: 4px solid #fff;
    box-shadow: 0 12px 16px rgb(16 24 40 / 8%);
}
.copy-paste-input span.ant-input-group-addon {
    padding: 0 4px;
    background: transparent;
}
.avatar-with-upload .avatar-upload-area {
    flex: 1 1;
}
.avatar-with-upload .avatar-upload-area .ant-form-item {
    width: 100%;
}
.copy-paste-input span.ant-input-group-addon button.ant-btn {
    padding-left: 9px;
    padding-right: 9px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MyProfile/profile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,WAAW;IACX,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,0CAA0C;AAC9C;AACA;IACI,cAAc;IACd,uBAAuB;AAC3B;AACA;IACI,SAAO;AACX;AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".profile-page:before {\n    content: \"\";\n    background: #F9FAFB;\n    height: 99px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: -1;\n    border-bottom: 1px solid #EAECF0;\n}\n\n.profile-page {\n    position: relative;\n    z-index: 1;\n}\n.avatar-grid .ant-avatar {\n    border: 4px solid #fff;\n    box-shadow: 0 12px 16px rgb(16 24 40 / 8%);\n}\n.copy-paste-input span.ant-input-group-addon {\n    padding: 0 4px;\n    background: transparent;\n}\n.avatar-with-upload .avatar-upload-area {\n    flex: 1;\n}\n.avatar-with-upload .avatar-upload-area .ant-form-item {\n    width: 100%;\n}\n.copy-paste-input span.ant-input-group-addon button.ant-btn {\n    padding-left: 9px;\n    padding-right: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
