import React,{useState,useEffect,useContext} from 'react';
import { Layout, Flex, Typography, Tabs } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import Network from '../../components/Network';
import Team from '../../components/Team';
import './my-network.css';

const { Title, Text } = Typography;

const MyNetwork = () => {
	const {user}  = useContext(UserContext);
	const onChange = (key) => {
	  console.log(key);
	};

	const items = [
	  {
	    key: '1',
	    label: 'My Network',
	    children: <Network />,
	  },
	  {
	    key: '2',
	    label: 'My Team',
	    children: <Team />,
	  }
	];


	return(
		<Layout style={{ padding: 30 }}>
			<Flex className="content-head-area" horizontal justify="space-between" align="center">
		    	<Title level={2} style={{ marginBottom: '25px' }}>Network</Title>
		    </Flex>
			{user?.role==="Team"?
			<Network />
			:<Tabs className="network-tabs" defaultActiveKey="1" items={items} onChange={onChange} />}
		
		    
		</Layout>
	);
};

export default MyNetwork;