import React,{useState,useContext,useEffect} from 'react';
import {Switch, Row, Col, Button, Flex, Form, Input, Divider, Typography, message,Spin } from 'antd';
import ImageUpload from '../ImageUploads/ImageUpload';
import { UserContext } from '../../contexts/UserContext';
import { put } from '../../utils/api';
import { convertStringsToBooleans } from '../../utils/utils';
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const Automation = ({settinginfo,photo,photoId}) => {
    const [form] = Form.useForm();
    const [imageId,setImageId]=useState(null);
    const [loading, setLoading]=useState(false);
    const [textboxMessage, setTextboxMessage] = useState("");
    const {user}  = useContext(UserContext);
    const maxLength = 500;

    const onChange = (checked) => {
      console.log(`switch to ${checked}`);
    };
    const updatedSettings = convertStringsToBooleans(settinginfo);
    useEffect(()=>{
      if(updatedSettings){
        setTextboxMessage(updatedSettings.message);
      }
     
    },[updatedSettings]);
   
    const onSubmit =async (values) => {
      const data = { ...values,photo:imageId };
     
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,data);
        if (response && response.status === 200) {
          message.success(response.message);
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    if(!settinginfo){
     return false;
    }
  
    const handleChange = (e) => {
      if (e.target.value.length <= maxLength) {
        setTextboxMessage(e.target.value);
      }
    };

  
   
    return(
        <div>
          <Spin spinning={loading}>
          <Flex className="head-inner" horizotanl justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Automation</Title>
                <Text>Write out automation to your fans to make your work easier</Text> 
            </Flex>
          </Flex>
          <Divider />
          <Form form={form} initialValues={updatedSettings} onFinish={onSubmit}  className="setting-form">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Flex gap={18} style={{ marginBottom: 20 }}>
                <Form.Item
                  name="isAutoMessage"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                >
                  <Switch onChange={onChange} style={{ marginTop: 4 }} />
                </Form.Item>
                <Flex vertical>
                  <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>New Fans Greeting</Text>
                  <Text style={{ fontSize: '16px' }}>Write a short introduction to new fans upon the first text.</Text>
                </Flex>
              </Flex>

              <Form.Item
                label="Image (Optional)"
                name="photo"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false }]}
                default
              >
                <ImageUpload setImageId={setImageId} photoId={photoId} imageUrl={photo}/>
              </Form.Item>

              <Form.Item
                label="Message"
                name="message"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please enter your message' }]}
                className="message-textarea"
              >
                <TextArea label="Message" onChange={handleChange} maxLength={maxLength} rows={6} />
              </Form.Item>
              
              <Paragraph style={{color:(textboxMessage?.length>499)?"red":""}}>
                {textboxMessage ? (<>
                  {maxLength - textboxMessage.length} characters left
                </>) : (<>
                  {maxLength} characters left
                </>)
                }
              </Paragraph>

              </Col>
            </Row>
            <Divider />
            <Flex
                horizotanl
                justify="end"
                gap="middle"
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
              <Button type="text" size="large">
                Cancel
              </Button>
                <Button type="primary" htmlType='submit' size="large">
                  Save
                </Button>
            </Flex>

          </Form>
          </Spin>
        </div>
    );
};

export default Automation;