// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    text-align: center;
}

.footer-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    flex-wrap: wrap;
}

.footer-menu-item {
    padding: 0 0px !important;
    order: inherit !important;
    width: auto !important;
    margin: 0 !important;
}

.footer-menu-separator {
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
}

.footer-menu {
    display: flex;
}

.footer-menu li.ant-menu-item {
    display: inline-block;
    width: auto;
    background: transparent !important;
    padding: 0;
    margin: 0 0px 10px 5px;
    line-height: normal;
    height: auto;
    font-size: 12px;
}

.footer-menu li.ant-menu-item:after {
    content: "|";
    margin-left: 5px;
    color: #D0D5DD;
    font-size: 20px;
    vertical-align: middle;
}

.footer-menu li.ant-menu-item:last-child:after {
    display: none;
}


@media (max-width: 767px){
    .footer-menu li.ant-menu-item {
        font-size: 12px;
        margin: 0 0px 7px 5px;
    }
    .footer-menu li.ant-menu-item:after {
        font-size: 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/FooterComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,kCAAkC;IAClC,UAAU;IACV,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,eAAe;QACf,qBAAqB;IACzB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".footer {\n    text-align: center;\n}\n\n.footer-menu {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: none !important;\n    flex-wrap: wrap;\n}\n\n.footer-menu-item {\n    padding: 0 0px !important;\n    order: inherit !important;\n    width: auto !important;\n    margin: 0 !important;\n}\n\n.footer-menu-separator {\n    padding: 0 8px;\n    color: rgba(0, 0, 0, 0.45);\n    font-size: 16px;\n}\n\n.footer-menu {\n    display: flex;\n}\n\n.footer-menu li.ant-menu-item {\n    display: inline-block;\n    width: auto;\n    background: transparent !important;\n    padding: 0;\n    margin: 0 0px 10px 5px;\n    line-height: normal;\n    height: auto;\n    font-size: 12px;\n}\n\n.footer-menu li.ant-menu-item:after {\n    content: \"|\";\n    margin-left: 5px;\n    color: #D0D5DD;\n    font-size: 20px;\n    vertical-align: middle;\n}\n\n.footer-menu li.ant-menu-item:last-child:after {\n    display: none;\n}\n\n\n@media (max-width: 767px){\n    .footer-menu li.ant-menu-item {\n        font-size: 12px;\n        margin: 0 0px 7px 5px;\n    }\n    .footer-menu li.ant-menu-item:after {\n        font-size: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
