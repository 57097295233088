import React, { createContext,useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children,initialUser }) => {
  const [user, setUser] = useState(initialUser);
 
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};