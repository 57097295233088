import React, { useState } from 'react';
import { Layout, Col, Row, Flex, Typography, Button, Avatar, Modal } from 'antd';
import { InfoCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import UserImg from '../../../images/avatar-image.png';
import DeleteImg from '../../../images/delete-icon-bg.svg';
import CheckImg from '../../../images/custom-check-icon.svg';
import './CustomRequest.css';

const { Title, Text } = Typography;
const { Content } = Layout;

const CustomRequest = () => {
    const [isConfirmModal, SetConfirmModal] = useState(false);
    const [confirmed, ConfirmedRequest] = useState(false);

    const [isCancelModal, SetCancelModal] = useState(false);
    const [cancelled, CancelledRequest] = useState(false);

    const handleConfirmedRequest = () => {
        ConfirmedRequest(true);
        SetConfirmModal(false);
    }
    const handleConfirmModalCancel = () => {
        SetConfirmModal(false);
    };
    const handleCanceledRequest = () => {
        CancelledRequest(true);
        SetCancelModal(false);
    }
    const handleModalCancel = () => {
        SetCancelModal(false);
    };

    const handleBack = () => {
        ConfirmedRequest(false);
        CancelledRequest(false);
    }

    return (
        <Layout style={{ minHeight: 'calc(100vh - 59px)', backgroundColor: '#F2F2F7' }}>
            <Content style={{ padding: '16px 20px', display: 'flex', flexDirection: 'column' }}>
                <Flex vertical style={{ maxWidth: '416px', width: '100%', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                    <Flex vertical align="center">
                        <Avatar size={64} src={UserImg} />
                        <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px', color: '#344054' }}>Olivia Rhye</Title>
                        <Title level={2} style={{ textAlign: 'center', marginTop: 20, marginBottom: 10 }}>Custom request</Title>
                        <Text style={{ marginBottom: 30 }}>Description overview</Text>
                        {confirmed || cancelled ? (<>
                                {cancelled ? (<>
                                    <Avatar size={48} src={CheckImg} />
                                    <Title level={4} style={{ marginTop: 10, marginBottom: 10, fontSize: '18px', color: '#101828' }}>Cancelled</Title>
                                </>) : (<>
                                    <Avatar size={48} src={CheckImg} />
                                    <Title level={4} style={{ marginTop: 10, marginBottom: 10, fontSize: '18px', color: '#101828' }}>Confirmed!</Title>
                                </>)}
                                <Button type="text" size="large" onClick={handleBack} icon={<ArrowLeftOutlined />} style={{ fontWeight: 600, paddingTop: 11, paddingBottom: 11, height: 'auto' }}>Back</Button>
                            </>) : (
                            <Flex vertical gap={10} style={{ width: '100%' }}>
                                <Button type="primary" size="large" onClick={() => SetConfirmModal(true)} style={{ fontWeight: 600, paddingTop: 11, paddingBottom: 11, height: 'auto' }}>Book for $10</Button>
                                <Button type="text" size="large" onClick={() => SetCancelModal(true)} style={{ fontWeight: 600, paddingTop: 11, paddingBottom: 11, height: 'auto' }}>Cancel</Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                <Flex vertical align="center" style={{ marginTop: 'auto' }}>
                    <Text style={{ color: '#6941C6', fontWeight: 600, marginTop: 20 }}>
                        <InfoCircleOutlined style={{ marginRight: 8, fontSize: '18px', verticalAlign: 'text-bottom' }} />
                        How do refunds work?
                    </Text>
                </Flex>
                {isConfirmModal &&
                    <Modal
                        open={isConfirmModal}
                        onCancel={handleConfirmModalCancel}
                        footer={null}
                        width={560}
                        className="custom-request-modal"
                    >
                        <Flex vertical style={{ padding: 20 }}>
                            <Avatar size={48} src={CheckImg} />
                            <Title level={4} style={{ marginTop: 10, marginBottom: 10, fontSize: '18px', color: '#101828' }}>Book custom request?</Title>
                            <Text>Are you sure you want to book your custom request? This action cannot be undone.</Text>
                        </Flex>
                        <Flex gap={12} vertical style={{ padding: '0px 20px 20px 20px' }}>
                          <Button size="large" type="primary" danger onClick={handleConfirmedRequest} style={{ fontWeight: 600, backgroundColor: '#17B26A' }}>Confirm</Button>
                          <Button size="large" type="default" onClick={handleConfirmModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                        </Flex>
                    </Modal>
                }
                {isCancelModal &&
                    <Modal
                        open={isCancelModal}
                        onCancel={handleModalCancel}
                        footer={null}
                        width={560}
                        className="custom-request-modal"
                    >
                        <Flex vertical style={{ padding: 20 }}>
                            <Avatar size={48} src={DeleteImg} />
                            <Title level={4} style={{ marginTop: 10, marginBottom: 10, fontSize: '18px', color: '#101828' }}>Cancel custom request?</Title>
                            <Text>Are you sure you want to cancel your custom request? This action cannot be undone.</Text>
                        </Flex>
                        <Flex gap={12} vertical style={{ padding: '0px 20px 20px 20px' }}>
                          <Button size="large" type="primary" danger onClick={handleCanceledRequest} style={{ fontWeight: 600 }}>Confirm</Button>
                          <Button size="large" type="default" onClick={handleModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                        </Flex>
                    </Modal>
                }
            </Content>
        </Layout>
    );
}

export default CustomRequest;