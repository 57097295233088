
import React, { useState, useEffect } from 'react';
import Giphy from 'giphy-js-sdk-core';
import {Image, Modal, Spin, Flex, Input, message} from 'antd';
import './Gifs.css'
import PoweredLogo from '../../images/PoweredBy.png';
const giphy = Giphy('fFck9qXEmPT9bNziuxR3aLYtXOncdxKC');

const { Search } = Input;
const Gifs=({gifsVisible,setGifsVisible,onSelectGifs})=>{
    const [gifs, setGifs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState(null);
    

    const onSearch=async(value)=>{
        const searchvalue=value.target.value;
        setIsLoading(true);
        setSearchValue(searchvalue);
       
    }
   
    useEffect(() => {
        const fetchGifs = async () => {
          try {
            setIsLoading(true); // Set loading to true before search begins
      
            const searchQuery = searchValue || 'reactions'; // Default to 'reaction' if no search value
         //   const response = await giphy.search('gifs', { q: searchQuery,rating:"g",limit:10,lang:"en" });
            const result = searchValue
            ? await giphy.search('gifs', { q: searchQuery,rating:"g",limit:10,lang:"en" })
            : await giphy.trending('gifs',{ limit: 12 });

            setGifs(result.data); // Set the gifs data
            setIsLoading(false); // Stop loading after gifs are loaded
          } catch (error) {
            setIsLoading(false); // Ensure loading stops even if there's an error
            message.error('Gifs API rate limit exceeded.'); // Error message on failure
          }
        };
      
        fetchGifs(); // Call the async function to fetch gifs
      
        // Cleanup: Optional, if needed for aborting the request
        return () => {
          setIsLoading(false); // Cleanup loading state if component unmounts
        };
      }, [searchValue]); 

      return (
        <Modal
            key="GIFs"
            title="GIFs"
            open={gifsVisible}
            onCancel={() => setGifsVisible(false)}
            footer={null}
        >
            <Flex vertical={true} style={{ marginBottom: 20 }}>
                <Search
                    placeholder="input search text"
                    allowClear
                    onChange={onSearch}
                />
            </Flex>
            <Spin spinning={isLoading}>
                <div className='giphyMain'>
                    {gifs.map((gif,index) => (
                        <div key={index} className='item'>
                                <Image  onClick={()=>onSelectGifs(gif.images.fixed_height.url)} preview={false}   src={gif.images.fixed_height.url} alt={gif.title} />
                        </div>
                    ))}
                </div>

                <Flex justify="end" style={{ marginTop: 10 }}>
                    <Image src={PoweredLogo} width={120} preview={false} alt="Powered By Giphy"/>
                </Flex>

            </Spin>
      </Modal>
      );

}
export default Gifs;