import React from 'react';
import { Form, Input, Select, Checkbox, Button, Divider, Flex, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

const AddNewCard = ({ goBack }) => {
  const onFinish = (values) => {
    console.log('Received values:', values);
    goBack();
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack} size="large" style={{ fontWeight: 600, fontSize: '14px', padding: 0 }}>
        Back
      </Button>
      <Title level={3} style={{ marginBottom: '10px', fontWeight: 700, marginTop: 15 }}>Add New Card</Title>
      <Form onFinish={onFinish}>
        <Title level={4}>Billing Details</Title>
         <Form.Item 
            name="address1" 
            label="Address 1" 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item" 
            rules={[{ required: true, message: 'Please enter your address' }]}
        >
            <Input placeholder="Enter address number" />
        </Form.Item>
        <Form.Item 
            name="address2"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            label="Address 2 (Optional)"
        >
            <Input placeholder="Enter account number" />
        </Form.Item>
        <Form.Item 
            name="city" 
            label="City" 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your city' }]}
        >
            <Input placeholder="Enter City" />
        </Form.Item>
        <Form.Item 
            name="state" 
            label="State/Province" 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[{ required: true, message: 'Please select your state/province' }]}
        >
            <Select placeholder="Select">
                <Option value="state1">State 1</Option>
                <Option value="state2">State 2</Option>
            </Select>
        </Form.Item>
        <Form.Item 
            name="zip" 
            label="Zip/Postal Code" 
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your zip/postal code' }]}
        >
            <Input placeholder="Enter Zip/Postal Code" />
        </Form.Item>

        <Divider />
        
        <Title level={4}>Card Details</Title>

        <Form.Item 
            name="nameOnCard"   
            label="Name on card"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter the name on card' }]}
        >
            <Input placeholder="Enter name on card" />
        </Form.Item>
        <Form.Item 
            name="cardNumber"
            label="Card number"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your card number' }]}
        >
            <Input placeholder="1234 1234 1234 1234" />
        </Form.Item>

        <Flex horizontal="true" gap="middle">
            <Form.Item
                name="expiry"
                label="Expiry"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="custom-form-item"
                style={{ width: '120px' }}
                rules={[{ required: true, message: 'Please enter expiry date' }]}
            >
                <Input placeholder="MM / YYYY" />
            </Form.Item>
            <Form.Item 
                name="cvv"
                label="CVV"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="custom-form-item"
                style={{ width: '120px' }}
                rules={[{ required: true, message: 'Please enter CVV' }]}
            >
                <Input type="password" placeholder="•••" />
            </Form.Item>
        </Flex>

        <Form.Item
          name="terms"
          valuePropName="checked"
          wrapperCol={{ span: 24 }}
          className="custom-form-item checkox-label"
          style={{ marginTop: 10 }}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
            },
          ]}
        >
          <Checkbox>
            Save for future use
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="terms"
          valuePropName="checked"
          wrapperCol={{ span: 24 }}
          className="custom-form-item checkox-label"
          style={{  }}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
            },
          ]}
        >
          <Checkbox>
            I agree I am 18 years or older and have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
          </Checkbox>
        </Form.Item>
    
        <Divider />

        <Flex horizontal="true" gap="middle" justify="end">
          <Form.Item>
            <Button type="default" size="large" style={{ width: '100%', fontWeight: 600 }} onClick={goBack}>Cancel</Button>
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" style={{ width: '100%', fontWeight: 600 }}>Submit</Button>
          </Form.Item>
        </Flex>

      </Form>
    </div>
  );
};

export default AddNewCard;
