import React from 'react';
import { Layout } from 'antd';

const Dashboard = () => {
  return (
    <Layout style={{ padding: 30 }}>
      Dashboard
    </Layout>
  );
};

export default Dashboard;
