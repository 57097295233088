import React, { useContext,useState } from 'react';
import { Form, Button, Typography, Image, Flex ,Spin,message} from 'antd';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import CodeInput from '../CodeInput';
import PhoneIcon from '../../images/phone-icon.svg';
import { post } from '../../utils/api';
import { AuthContext } from '../../contexts/AuthContext';
import useScreenSize from '../../utils/useScreenSize';
import { jwtDecode } from "jwt-decode";

const { Title, Paragraph } = Typography;

const VerifyTwoStep = ({setIsOtpSend,loginValue}) => {
  const [code, setCode] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const {email}=loginValue||{};
  const onFinish = async (values) => {
    const currentStepData = await form.validateFields();
    
    if(currentStepData){
      setLoading(true);
      try{
        const otpCode = code.join("");
        const response = await post('auth/login-verify-email',{otp:otpCode,loginValue});
          if (response && response.status === 200) {
            const token = response.token;
            localStorage.setItem('token', token);

            const decodedToken = jwtDecode(token);
            setAuth(true);
            if(decodedToken && decodedToken.role.includes('Admin')){
              navigate('/admin/dashboard');
            }else if(decodedToken && decodedToken.role.includes('Creator')){
              navigate('/message/audience');
            }else if(decodedToken && decodedToken.role.includes('Team')){
              navigate('/team/dashboard');
            }else{
              navigate('/');
            }
          }else {
            message.error(response.message || 'The code you entered is incorrect. Please try again.');
          }
      }catch(error){
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'The code you entered is incorrect. Please try again.');
        } else {
          message.error(error.message || 'The code you entered is incorrect. Please try again.');
        }
      }finally {
        setLoading(false);
      }
      
    }
    
  };


  const resendOtp= async()=>{
     
    setLoading(true);
    try {
      const response = await post('auth/login-send-email',{
        "email":email
    });
      if (response && response.status === 200) {
        message.success('OTP has been sent to your registered email.');
      } else {
        message.error(response.message || 'Login failed');
      }

    }catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    }finally {
      setLoading(false);
    }
  }

  return (
    <Spin size="small" spinning={loading}>
        <Flex
          vertical
          align="center"
          style={{
            width: '100%',
          }}
        >
          <Image
              width={60}
              src={PhoneIcon}
              preview={false}
          />
        </Flex>

        <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: '600' }}>2-step verification</Title>
        <Paragraph style={{ textAlign: 'center', fontSize: '16px', marginBottom: '40px' }}>Enter the 2-step verification code we texted to your phone</Paragraph>

        <Form
          name="verificationStepOne"
          form={form}
          onFinish={onFinish}
        >
          <CodeInput setCode={setCode} /> {/* Use the CodeInput component */}
          <Form.Item style={{ marginBottom: 10 }}>
            <Button type="primary" size="large" htmlType="submit" block style={{ fontWeight: 600 }}>
              Submit
            </Button>
          </Form.Item>

        </Form>
        <Button type="text" onClick={()=>{ setIsOtpSend(false)}} size="large" block icon={<ArrowLeftOutlined />} style={{ fontWeight: 600, fontSize: 14 }}>Back to log in</Button>
        <Paragraph style={{ textAlign: 'center', marginTop: 15 }}>Didn’t receive the email? <Link loading={loading} onClick={resendOtp} style={{ fontWeight: 600 }}>Click to resend</Link></Paragraph>

        {isMobile &&
            <Flex vertical style={{ marginTop: 20, textAlign: 'center' }}>
              {location.pathname === '/signup' ?
                <Paragraph>
                  Already have an account? <Link to="/" style={{ fontWeight: 600 }}>Log in</Link>
                </Paragraph>
              : 
                <Paragraph>
                  Don’t have an account? <Link to="/signup" style={{ fontWeight: 600 }}>Sign up</Link>
                </Paragraph>
               }
            </Flex>
          }

    </Spin>
  );
};

export default VerifyTwoStep;