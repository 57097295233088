// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    gap: 10px;
}

.code-input {
    width: 64px !important;
    height: 64px;
    text-align: center;
    font-size: 48px;
    border-radius: 8px;
    color: #1570EF;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/CodeInput/CodeInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".code-input-container {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 24px;\n    gap: 10px;\n}\n\n.code-input {\n    width: 64px !important;\n    height: 64px;\n    text-align: center;\n    font-size: 48px;\n    border-radius: 8px;\n    color: #1570EF;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
