// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.private-head {
    margin-bottom: 20px;
    position: relative;
}
.private-head .back-btn {
    position: absolute;
    left: 0;
    top: 0;
}
.private-head .cancel-btn {
    position: absolute;
    right: 0;
    top: 0;
}
.ant-modal.occasion-modal {
    max-width: calc(100vw - 30px);
}
.occasion-modal .ant-modal-content {
    padding: 0;
}
.occasion-checkbox span.ant-radio-inner {
    width: 20px;
    height: 20px;
}
.occasion-checkbox span.ant-radio {
    margin-right: 5px;
}
.options-checkbox .ant-radio {
    align-self: flex-start;
    margin-top: 3px;
}
.options-checkbox .ant-radio-wrapper {
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Audience/PrivateRequests/PrivateRequests.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;AACV;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;AACV;AACA;IACI,6BAA6B;AACjC;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".private-head {\n    margin-bottom: 20px;\n    position: relative;\n}\n.private-head .back-btn {\n    position: absolute;\n    left: 0;\n    top: 0;\n}\n.private-head .cancel-btn {\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n.ant-modal.occasion-modal {\n    max-width: calc(100vw - 30px);\n}\n.occasion-modal .ant-modal-content {\n    padding: 0;\n}\n.occasion-checkbox span.ant-radio-inner {\n    width: 20px;\n    height: 20px;\n}\n.occasion-checkbox span.ant-radio {\n    margin-right: 5px;\n}\n.options-checkbox .ant-radio {\n    align-self: flex-start;\n    margin-top: 3px;\n}\n.options-checkbox .ant-radio-wrapper {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
