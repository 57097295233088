import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const TimeAgo=({ timestamp })=>{
  const [timeAgo, setTimeAgo] = useState('');
  const datTimeFomated=dayjs(timestamp).format('D/M/YY');;
  useEffect(() => {
    const calculateTimeAgo = () => {
      const givenTime = new Date(timestamp);
      const currentTime = new Date();
      const timeDifference = currentTime - givenTime; // Time difference in milliseconds

      const minutesAgo = Math.floor(timeDifference / 1000 / 60);

      setTimeAgo(`${minutesAgo} min ago`);
    };

    calculateTimeAgo();

    const intervalId = setInterval(calculateTimeAgo, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [timestamp]);
  return (
    <div>
      {parseInt(timeAgo)<59 ?<span>{timeAgo}</span> : <span>{datTimeFomated}</span>}
    </div>
  );
}

export default TimeAgo;