import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Calendar, Spin, Button, Typography, Space, Flex, Divider, Select, Dropdown, Image,Popconfirm,message,Modal } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, EditOutlined, DeleteOutlined,EditFilled } from '@ant-design/icons';
import moment from 'moment';
import momenttmz from 'moment-timezone';
import dayjs from 'dayjs';
import NewMessage from '../../components/GlobalComponents/NewMessage';
import { post,get } from '../../utils/api';
import './calendar.css';
import EllipsisIcon from "../../images/ellipsis-icon.svg";
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text } = Typography;
const { Content, Sider } = Layout;
const { Option } = Select;
const { confirm } = Modal;

const ScheduleMessage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSendMessage, setSendMessage] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [posts, setPosts] = useState({});
  const { isMobile, isTableToDesktop } = useScreenSize();

  const fetchPosts = async () => {
    const finaldata = {};
    setIsLoading(true);
    try {
      const mockPosts = {};
      const response = await post('creator/getAllscheduleMessage/', finaldata);
      if (response.status) {
        response.data.forEach((item) => {
          const { schedule_date, message, schedule_time,timezone } = item;
          const content = message;
          const formattedDate = moment(schedule_time, 'MM/DD/YYYY h:mm A').format('YYYY-MM-DD');
         
          const convertedDate = moment.utc(schedule_time).tz('America/Los_Angeles').format('h:mm A');
          if (!mockPosts[formattedDate]) {
            mockPosts[formattedDate] = [];
          }
          mockPosts[formattedDate].push({
            ...item,
            content,
            schedule_time:convertedDate,
          });
        });
        setPosts(mockPosts);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const loadDates = async () => {
      await fetchPosts();
    }
    loadDates();
  }, []);

  const handleDateSelect = (value) => {
    setSelectedDate(value);
  };

  const handleSendMessage = () => {
    setSendMessage(true);
  };

  const handleCloseMessage = async() => {
    setSendMessage(false);
    await fetchPosts();
  };

  const handleClose = () => {
    setSelectedDate(null);
  };

  const dateCellRender = (value) => {
    const dateStr = value.format('YYYY-MM-DD');
   // console.log("dateStr",dateStr);
    return posts[dateStr] ? (
      <Text>
        {posts[dateStr].length} Posts
      </Text>
    ) : null;
  };


  const showDeleteConfirm = () => {
    confirm({
      title: 'Delete Scheduled Message?',
      icon: <DeleteOutlined />,
      content: 'Are you sure you want to delete the scheduled message? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk:deleteconfirm,
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteconfirm = async() => {
    setIsLoading(true);
        try{
          const response = await get(`creator/deleteScheduled/${selectedPost?.id}`);
            if(response && response.status){
              message.success('Successfully deleted.');
              await fetchPosts();
            }
        }catch(error){
          console.log(error);
        }finally{
          setIsLoading(false);
        }
  };
  const cancel=()=>{
    console.log("cancel");
  }
  const editPost=async()=>{

  }

  const editpostitems = (
    <Flex vertical align="start" style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0', borderRadius: '8px', padding: 0 }}>     
        <Button onClick={showDeleteConfirm}  block type="text" size="large" style={{ textAlign: 'left', color: '#344054' }} icon={<DeleteOutlined style={{ color: '#D92D20' }} />}>Delete</Button>
    </Flex>
  );

  const disabledDate = (current) => {
    const today = dayjs().startOf('day');
    const dateStr = current.format('YYYY-MM-DD');
    const hasPosts = !!posts[dateStr];
    return current.isBefore(today) && !hasPosts;
  };

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(null); // Reset selectedDate when month changes
  };

  const handleMonthSelect = (value) => {
    const newMonth = currentMonth.month(value);
    setCurrentMonth(newMonth);
    setSelectedDate(null); // Reset selectedDate when a new month is selected
  };

  const selectedFanStyles = useMemo(() => 
    isMobile ? { 
      position: 'absolute',
      right: 0,
      background: '#ffffff', 
      padding: '15px', 
      borderLeft: '1px solid #e3e3e3',
      height: '100%',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      zIndex: 2,
    } : isTableToDesktop ? { 
      position: 'absolute',
      right: 0,
      background: '#ffffff', 
      padding: '20px', 
      borderLeft: '1px solid #e3e3e3',
      height: '100%',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      zIndex: 2,
    } : { 
      background: '#ffffff', 
      padding: '20px', 
      borderLeft: '1px solid #e3e3e3'
    }
  , [isMobile, isTableToDesktop]);

  const months = dayjs.months();

  return (
    <Layout style={{ backgroundColor: '#F9FAFB', minHeight: '100vh', position: 'relative' }}>
      <Spin spinning={isLoading}>
        <Content style={{ padding: isMobile ? '30px 15px' :'30px 35px' }}>
          <Space className="calendar-main" style={{ marginBottom: 20, display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>

            <Space size="middle" className="calendar-head" style={ isMobile && { justifyContent: 'space-between', width: '100%' }}>
              <Button icon={<ArrowLeftOutlined />} onClick={() => handleMonthChange(false)} />
              <Title level={4}>{currentMonth.format('MMMM YYYY')}</Title>
              <Button icon={<ArrowRightOutlined />} onClick={() => handleMonthChange(true)} />
            </Space>

            <Space className="calendar-select-main" style={ isMobile && { justifyContent: 'space-between', width: '100%', marginTop: 10 }}>
              <Select
                value={currentMonth.month()}
                onChange={handleMonthSelect}
                className="select-month-dropdown"
                style={{ width: '100%' }}
              >
                {months.map((month, index) => (
                  <Option key={index} value={index}>
                    {month}
                  </Option>
                ))}
              </Select>
              <Button type="primary" size="large" icon={<EditOutlined />} onClick={handleSendMessage} style={{ fontWeight: 600 }}>
                New
              </Button>
            </Space>

          </Space>
          <Calendar
            fullscreen={true}
            value={selectedDate || currentMonth} // Use selectedDate if available, otherwise fallback to currentMonth
            onSelect={handleDateSelect}
            cellRender={dateCellRender}
            disabledDate={disabledDate} // Disable past dates without posts
            className="calendar-admin"
            headerRender={() => null}
          />
        </Content>
      </Spin>

       {selectedDate && 
        <Sider width={ isMobile ? 250 : isTableToDesktop ? 300 : 375} style={selectedFanStyles} collapsed={!selectedDate}>
          <Flex vertical gap="middle">
            <Button
              type="link"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={handleClose}
              style={{ position: 'absolute', paddingTop: 0, marginBottom: '20px', alignSelf: 'flex-end' }}
            >
            </Button>
            <Title style={{ marginTop: 0 }} level={ isMobile ? 5 : 4}>{selectedDate.format('dddd MM, YYYY')}</Title>
            <Divider style={{ margin: 0 }} />
            {posts[selectedDate.format('YYYY-MM-DD')] ? (
              posts[selectedDate.format('YYYY-MM-DD')].map((post, index) => (
                <div className="schedule-post" key={index} style={{ marginBottom: 20 }}>
                  <div style={{ alignItems: 'center' }}>
                    <p style={{ marginTop: 0 }}>{post?.schedule_time}</p>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} style={{ whiteSpace: 'pre-wrap' }} />
                  </div>
                  <Dropdown overlay={editpostitems} autoFocus={true} trigger={['click']}>
                    <a onClick={(e) => {setSelectedPost(post);e.preventDefault()}}>
                      <Image src={EllipsisIcon} width={20} preview={false} />
                    </a>
                  </Dropdown>
                </div>
              ))
            ) : (
              <p>No posts for this date</p>
            )}
          </Flex>
        </Sider>
      }
      <NewMessage visible={openSendMessage} handleCancel={handleCloseMessage}  />

    </Layout>
  );
};

export default ScheduleMessage;
