import React from 'react';
import { Layout, Typography, Button, Flex, Image } from 'antd';
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import CheckIcon from '../../images/check-icon.svg';
const { Title, Paragraph } = Typography;
const { Content } = Layout;
const PasswordReset = () => {
  return (
    <Content style={{ paddingTop: 40, paddingBottom: 40 }}>
      <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
          <Flex
            vertical
            align="center"
            style={{
              width: '100%',
            }}
          >
            <Image
                width={60}
                src={CheckIcon}
                preview={false}
            />
          </Flex>

          <Title level={2} style={{ textAlign: 'center', marginTop: '40px', marginBottom: '15px' }}>Password reset</Title>
          <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>Your password has been successfully reset. Click below to log in magically.</Paragraph>
            <Link to="/">
            <Button type="primary" size="large" block style={{ fontWeight: 600, marginTop: 10 }}>
              Continue
            </Button>
          </Link>
          <Link to="/">
            <Button type="text" size="large" icon={<ArrowLeftOutlined />} block style={{ fontWeight: 600, marginTop: '20px' }}>
                Back to log in
            </Button>
          </Link>
      </Flex>
    </Content>
  );
};

export default PasswordReset;