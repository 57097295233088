import React, { useState, useEffect,useContext } from 'react';
import { Button, Modal, Upload, Layout,Typography, Space, Tabs, Image, Flex, message,Spin,Pagination } from 'antd';
import { UploadOutlined, CloudUploadOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import Phototab from '../../components/ContentVault/Phototab';
import Videotab from '../../components/ContentVault/Videotab';
import { UserContext } from '../../contexts/UserContext';
import DeleteIcon from "../../images/delete-icon-bg.svg";
import UploadIcon from '../../images/upload-icon.svg';
import '../../pages/ContentVault/contentvault.css';
import { del, get, post } from '../../utils/api';

const { Title, Text } = Typography;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const ContentVaultUpload = ({contentVaultData,setContentVaultVisible,isContentVaultVisible,handleAddContentVault}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileType, setFileType] = useState('Photos');
  const [selectedFileIndices, setSelectedFileIndices] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 9, total: 0 });
  const {user}  = useContext(UserContext);
  const [filesToRender, setFilesToRender] = useState([]);
  const filesLoader = async (current, pageSize) => {
    const formData = {
      user_id: user?.id,
      fileType: fileType,
      current: current,
      pageSize: pageSize,
    };
    setIsLoading(true);
    try {
      const response = await get('creator/content-vault', formData);
      if (response.status) {
        setFilesList(response.images);
        setPagination(prevPagination => ({
          ...prevPagination,
          total: response.pagination.total,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const onConfrimDelete = async () => {
    try {
      setIsLoading(true);
      const deletePromises = selectedFileIndices.map(async (fileIndex) => {
        return await del(`creator/content-vault/${fileIndex}`);
      });
      const responses = await Promise.all(deletePromises);
      const successMessages = new Set();
      const errorMessages = new Set();
      responses.forEach(response => {
        if (response.status) {
          successMessages.add(response.message);
        } else {
          errorMessages.add(response.message);
        }
      });
      if (successMessages.size > 0) {
        await filesLoader();
        setSelectedFileIndices([]);
        setDeleteModalVisible(false);
        message.success([...successMessages].join(", "));
      }
      if (errorMessages.size > 0) {
        message.error([...errorMessages].join(", "));
      }
    } catch (error) {
      console.log("error", error);
      message.error("An error occurred while deleting files.");
    } finally {
      setIsLoading(false);
    }
  };



  const showUploadModal = () => {
    setIsModalVisible(true);
  };

  const handleCheckboxChange = (index,image) => {
    setSelectedFileIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
    contentVaultData((prevData) => {
      if (prevData.includes(image)) {
        return prevData.filter((i) => i !== image);
      } else {
        return [...prevData, image];
      }
    });
  };

  const handleOk = async () => {

  }

  const uploadHandel = async () => {
    try {
      setIsLoading(true);
      const uniqueFiles = Array.from(new Set(fileList.map(file => file.originFileObj)));
      const base64Files = await Promise.all(uniqueFiles.map(file => getBase64(file)));
      const newUploadedFiles = [...uploadedFiles, ...base64Files];
      setUploadedFiles(newUploadedFiles);
      
      const formData = new FormData();
      uniqueFiles.forEach((file, index) => {
        formData.append(`multiImage[${index}]`, file);
      });
      formData.append('user_id', user?.id);
      
      const response = await post('creator/content-vault', formData);
      if (response) {
        setIsModalVisible(false);
        setFilesToRender([]);
        setFileList([]);
        filesLoader(pagination.current, pagination.pageSize);
        message.success('Your file has been uploaded successfully.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };


  const handleCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };
  const uploadProps = {
    fileList,
    onChange: async({ fileList }) => {
      setFileList(fileList);
      const renderedFiles = await renderFiles(fileList);
      setFilesToRender(renderedFiles);
    },
    multiple: true,
    beforeUpload: () => false,
    listType:"picture-card",
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const processFile = (file) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(file);
      }, 1000);
    });
  };

  const renderFiles = async (fileList) => {
   
    const renderedFiles = await Promise.all(
      fileList.map(async (file) => {
        const processedFile = await processFile(file);
        return { ...file, processedFile };
      })
    );
    return renderedFiles;
  };

  const handleClose = () => {
    setDeleteModalVisible(false);
  };

  const tabsHandller=async (key)=>{
    setFilesList([]);
    setPagination({ current: 1, pageSize: 9, total: 0 });
    setFileType(key);
  }

  useEffect(() => {
    const init = async () => {
      await filesLoader(pagination.current, pagination.pageSize);
    };
  
    init();
  }, [fileType, pagination.current, pagination.pageSize]);

  const changePagination = (page, pageSize) => {
    setPagination(prev => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));
  }

  const handleDeleteSelected = () => {
    setDeleteModalVisible(true);
  };

  const cancelSelected=()=>{
    setSelectedFileIndices([]);
  }

  // useEffect(()=>{
  //   setSelectedFileIndices([]);
  // },[isModalOpen]);

// useEffect(()=>{
//   const initdata = async () => {
//   console.log("selectedFileIndices",selectedFileIndices);
//   contentVaultData(selectedFileIndices);
//   }
//   initdata();
// },[selectedFileIndices]);

  return (
  <>
    <Modal
        key="Content Vault"
        title="My Gallery"
        style={{ top: 10 }}
        width="large"
        open={isContentVaultVisible}
        onCancel={() =>{setSelectedFileIndices([]);setContentVaultVisible(false)}}
        footer={[
        <Flex gap={14} justify="end" style={{ padding: '16px 0px', borderTop: '1px solid #EAECF0' }}>
          <Button key="cancel" size="large" onClick={() => {setSelectedFileIndices([]);setContentVaultVisible(false)}} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={() => {
            handleAddContentVault();
            setSelectedFileIndices([]);
            contentVaultData([]);
          }}
          style={{ fontWeight: 600 }}
          >Add</Button>
        </Flex>]}
      >
      <div className="content-vault-main" style={{ marginTop: 20, marginBottom: 10 }}>
        <Spin spinning={isLoading}>

          {selectedFileIndices.length > 0 ? (
            <Flex horizontal="true" justify="end" style={{ marginBottom: 10 }}>
              <Space>
                <Text type="primary" style={{ fontWeight: 600 }}>{selectedFileIndices.length} selected</Text>
                <Text style={{ fontWeight: 600, color: '#7f56d9',cursor: 'pointer' }} onClick={cancelSelected}>Cancel</Text>
              </Space>
            </Flex>
          ) : (
            <Flex horizontal="true" justify="end" style={{ marginBottom: 10 }}>
              <Space>
                <Text style={{ fontWeight: 600, color: '#7f56d9' }}>Select</Text>
              </Space>
            </Flex>
          )}

          <Tabs className="content-vault-tabs" defaultActiveKey="Photos" onChange={tabsHandller}>
            <TabPane tab="Photos" key="Photos">
                <Phototab selectedFileIndices={selectedFileIndices} showUploadModal={showUploadModal} uploadedFiles={filesList} handleCheckboxChange={handleCheckboxChange}/>
                <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
                {pagination.total > 0 &&
                    (<Pagination
                      onChange={changePagination}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      showSizeChanger
                      onShowSizeChange={changePagination}
                    />)}
                </div>
            </TabPane>
            <TabPane tab="Videos" key="Videos">
              <Videotab selectedFileIndices={selectedFileIndices} showUploadModal={showUploadModal} uploadedFiles={filesList} handleCheckboxChange={handleCheckboxChange}/>
              <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
              {pagination.total > 0 &&
                    (<Pagination
                      onChange={changePagination}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      showSizeChanger
                      onShowSizeChange={changePagination}
                    />)}
              </div>
            </TabPane>
          </Tabs>

        </Spin>
      </div>
    </Modal>

      <Modal keyboard={false} maskClosable={false} className="media-upload" open={isModalVisible} onOk={uploadHandel} onCancel={handleCancel} width={480} footer={[
        <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
        <Button key="add" size="large" 
        type="primary" 
        onClick={uploadHandel} 
        disabled={!filesToRender.length} 
        style={{ fontWeight: 600 }}
        >Add files</Button>,
      ]}>
         <Spin spinning={isLoading}>
        <Title level={4}>Gallery Media Upload</Title>
        <Text style={{ marginBottom: '20px', display: 'block' }}>Gallery stores all of your photos, videos, and content in a secure place.</Text>
        {/* <UploadComponent/> */}
        <Dragger {...uploadProps} style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
          <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
            <Image src={UploadIcon} preview={false} size={40} />
          </p>
          <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
          <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800×400px)</p>
        </Dragger>
        </Spin>
      </Modal>

      <Modal className="media-upload" open={isDeleteModalVisible} onOk={handleOk} onCancel={handleClose} width={480} footer={[
        <Button key="cancel" size="large" onClick={handleClose} style={{ fontWeight: 600 }}>Cancel</Button>,
        <Button key="delete" size="large" type="primary" danger onClick={onConfrimDelete} style={{ fontWeight: 600 }}>Delete</Button>,
      ]}>
        <Image
            width={48}
            src={DeleteIcon}
            preview={false}
            style={{ marginTop: '10px', marginBottom: '10px' }}
        />
        <Title level={4}>Remove selected content?</Title>
        <Text style={{ marginBottom: '20px', display: 'block' }}>Are you sure you want to remove selected item(s) from the vault? This action cannot be undone.</Text>
      </Modal>
    </>
  );
}
export default ContentVaultUpload;
