import React, { useState, useEffect } from 'react';
import { Layout,Form, DatePicker, Input,Checkbox,Button,Spin,message,Select} from 'antd';
import { useParams } from 'react-router-dom';
import { get, put } from '../../../utils/api';
import dayjs from 'dayjs';
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Actor', 'Activist', 'Agency', 'Automotive', 'Artist', 'Beauty', 'Business', 'Blogger', 'Celebrity', 'Consulting', 'Education', 'Entertainment', 'Fashion', 'Film', 'Finance', 'Fitness', 'Food', 'Gaming', 'Government', 'Health & Wellness', 'Hospitality', 'Lifestyle', 'Management', 'Manufacturing', 'Media', 'Model', 'Motivation', 'Musician', 'Photographer', 'Pets', 'Politics', 'Real estate', 'Social Work', 'Sports', 'Travel', 'Tech', 'Vlogger', 'Videographer'];
const EditUsers = () => {
    const { id } = useParams(); 
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const eighteenYearsAgo = dayjs().subtract(18, 'year');
    const [hearAboutUs, sethearAboutUs] = useState(null);
    const [checkedList, setCheckedList] = useState([]);

    const handleAboutUsChange=(value)=>{
        sethearAboutUs(value);
      }

    const onChange = (list) => {
        setCheckedList(list);
      };

    useEffect(() => {
        const fetchUser = async () => {
          if (id) {
            setLoading(true);
            try {
              const response = await get(`admin/users/edit/${id}`);
              const user = response.data;
              setInitialValues({
                ...user,
                dob: user.dob ? dayjs(user.dob) : null,
              });
              form.setFieldsValue({
                ...user,
                dob: user.dob ? dayjs(user.dob) : null,
              });
            } catch (error) {
              console.error('Error fetching user:', error);
              message.error('Error fetching user data');
            } finally {
              setLoading(false);
            }
          }
        };
    
        fetchUser();
      }, [id, form]);

    const onFinish = async (values) => {
        setLoading(true);
        try{
            const completeFormData = { ...values, interests: checkedList,hearAboutUs };
            const response = await put(`admin/users/update/${id}`, completeFormData);
            if (response && response.status === 200) {
                message.success('Update successful');
                setCheckedList([]);
                sethearAboutUs(null);
              }else{
                message.error(response.message);
              }
        }catch (error) {
            if (error.response && error.response.data) {
              message.error(error.response.data.message || 'Error submitting form');
            } else {
              message.error(error.message || 'Error submitting form');
            }
          } finally {
            setLoading(false);
          }
    }
  return (
    <Layout style={{ padding: 30 }}>
    <Spin spinning={loading}>
         <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
      <Form.Item name="referral_code" label="Referral Code">
        <Input />
      </Form.Item>
      <Form.Item name="status" label="Status"  rules={[{ required: true, message: 'Please select a status' }]}>
        <Select options={[
        {
          value: 0,
          label: 'Pending',
        },
        {
          value: 1,
          label: 'Approved',
        },
        {
          value: 3,
          label: 'Rejected',
        },
      ]}/>
    </Form.Item>

      <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'First name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="last_name" label="Last Name">
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="phone_number" label="Phone Number">
        <Input />
      </Form.Item>
      <Form.Item name="assign_number" label="Assign Number">
        <Input />
      </Form.Item>
      <Form.Item name="dob" label="Date of Birth">
        <DatePicker format="DD-MM-YYYY" maxDate={eighteenYearsAgo} />
      </Form.Item>

      <Form.Item name="is_agree" valuePropName="checked">
        <Checkbox>Is Agree</Checkbox>
      </Form.Item>
      <Form.Item name="is_twofa" valuePropName="checked">
        <Checkbox>Is Two-Factor Authentication Enabled</Checkbox>
      </Form.Item>
   
      <Form.Item name="user_name" label="Username">
        <Input />
      </Form.Item>
      <Form.Item name="instagram_uri" label="Instagram URI">
        <Input />
      </Form.Item>
      <Form.Item name="facebook_uri" label="Facebook URI">
        <Input />
      </Form.Item>
      <Form.Item name="youtube_uri" label="YouTube URI">
        <Input />
      </Form.Item>
      <Form.Item name="ticktok_uri" label="TikTok URI">
        <Input />
      </Form.Item>
      <Form.Item name="other" label="Other">
        <Input />
      </Form.Item>
      <Form.Item name="about_yourself" label="About Yourself">
        <CheckboxGroup className="checkbox-list" options={plainOptions} value={checkedList} onChange={onChange} />
      </Form.Item>
      <Form.Item name="hear_about_us" label="How Did You Hear About Us">
       <Select
          showSearch
          style={{
            width: '100%',
          }}
          placeholder="Select one"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={handleAboutUsChange}
          options={[
            {
              value: 'Not Identified',
              label: 'Not Identified',
            },
            {
              value: 'Closed',
              label: 'Closed',
            },
            {
              value: 'Communicated',
              label: 'Communicated',
            },
            {
              value: 'Identified',
              label: 'Identified',
            },
            {
              value: 'Resolved',
              label: 'Resolved',
            },
            {
              value: 'Cancelled',
              label: 'Cancelled',
            },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {id ? 'Update User' : 'Create User'}
        </Button>
      </Form.Item>
    </Form>
    </Spin>
    </Layout>
  );
};

export default EditUsers;
