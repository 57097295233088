import React from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const { Content } = Layout;

const PublicLayout = ({ children }) => {
  const location = useLocation();
  const hideHeaderRoutes = ['/forgot-password', '/email-submitted', '/password-reset', '/new-password'];
  const shouldHideHeader = hideHeaderRoutes.includes(location.pathname);

  return(
    <Layout className="layout" style={{ background: "#ffffff" }}>
      {!shouldHideHeader && <Header />}
      <Content>
        <div className="site-layout-content">
          {children}
        </div>
      </Content>
      <Footer />
    </Layout>
  )
};

export default PublicLayout;
