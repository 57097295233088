import React, { useState,useContext } from 'react';
import { Card, Button, List, Avatar, Flex, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import AvatarImage from '../../AvatarImage';
import { formatPrice } from '../../../utils/utils';
import useScreenSize from '../../../utils/useScreenSize';

const { Title, Text } = Typography;

const Engagement = ({data}) => {
   const [position, setPosition] = useState('end');
   const navigate = useNavigate();
   const {user}  = useContext(UserContext);
   const { isMobile } = useScreenSize();

   const navigateToReport = () => {
    const redirectLink=user?.role==="Team"?'/team/engagement-report':'/engagement-report';
      navigate(redirectLink);
   };

  //  const data = [
  //   {
  //     title: 'John Doe',
  //     spent: '$1000',
  //     interactions: 30,
  //   },
  //   {
  //     title: 'Melissa Korne',
  //     spent: '$900',
  //     interactions: 26,
  //   },
  //   {
  //     title: 'Lisa Johnson',
  //     spent: '$800',
  //     interactions: 21,
  //   },
  //   {
  //     title: 'Ann Hawthorne',
  //     spent: '$450',
  //     interactions: 14,
  //   },
  //   {
  //     title: 'Timmy Turner',
  //     spent: '$300',
  //     interactions: 10,
  //   },
  // ];

   return (
    <Card className="insight-card">
      <Flex className="top-content" vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
        <Title level={4} style={{ marginTop: 0, fontSize: '18px' }}>Engagement</Title>

        <List
          itemLayout="horizontal"
          dataSource={data?.revenueList || []}
          renderItem={(item) =>{
            const imageId = item && item?.revenueUser?.uploadedIds && item?.revenueUser?.uploadedIds.length > 0 ? item?.revenueUser?.uploadedIds[0] : null;
            return (<List.Item>
              <List.Item.Meta
                avatar={<AvatarImage size={40} imageId={imageId} title={`${item?.revenueUser?.first_name} ${item?.revenueUser?.last_name}`} />}
                title={`${item?.revenueUser?.first_name} ${item?.revenueUser?.last_name}`}
                description={`${formatPrice(item?.totalAmount || 0)} | ${item?.itemCount} interactions`}
              />
            </List.Item>)
          }}
        />

      </Flex>
      <Flex vertical align="end" style={ isMobile ? { padding: '10px 20px', borderTop: '1px solid #EAECF0' } : { padding: '15px 30px', borderTop: '1px solid #EAECF0' }}>
        <Button type="text" icon={<RightOutlined />} iconPosition={position} style={{ color: '#6941C6', fontWeight: 600, padding: 0, background: 'transparent' }} onClick={navigateToReport}>View report</Button>
      </Flex>
    </Card>
  );
}

export default Engagement;
