// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unclock-content {
    position: relative;
}
.unclock-content .button-group {
    position: absolute;
    bottom: 40px;
    width: 100%;
    padding: 0 30px;
}
.unclock-content .ant-image:before {
    content: "";
    background: #0C111D;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
}
.unlock-wrapper img {
    max-width: 100%;
    border-radius: 6px;
}
.unlock-wrapper p {
    margin: 0;
}
.unlock-wrapper audio {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Audience/FanSignup/FanSignup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,SAAS;AACb;AACA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".unclock-content {\n    position: relative;\n}\n.unclock-content .button-group {\n    position: absolute;\n    bottom: 40px;\n    width: 100%;\n    padding: 0 30px;\n}\n.unclock-content .ant-image:before {\n    content: \"\";\n    background: #0C111D;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    opacity: 0.7;\n}\n.unlock-wrapper img {\n    max-width: 100%;\n    border-radius: 6px;\n}\n.unlock-wrapper p {\n    margin: 0;\n}\n.unlock-wrapper audio {\n    margin-top: 0px;\n    margin-bottom: 20px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
