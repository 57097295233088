import React,{ useState } from 'react';
import { Layout, Menu, Flex } from 'antd';

import './FooterComponent.css';

const { Footer } = Layout;

const FooterComponent = () => {
  const items = [
    { label: 'Terms of Service', key: 'terms' },
    { label: 'Privacy Policy', key: 'policy' },
    { label: 'Content Guidelines', key: 'content' },
    { label: 'Creator Terms of Use', key: 'creator' },
    { label: 'Fan Terms of Use', key: 'fan' },
    { label: 'Agency Terms of Use', key: 'agency' },
    { label: 'Payment Terms and Conditions', key: 'payment' },
    { label: 'General Terms of Service', key: 'general' },
  ];

  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Footer className="footer" style={{ textAlign: 'center', background: '#ffffff', padding: '0 20px' }}>
      <Flex vertical style={{ maxWidth: '576px', margin: '0 auto' }}>
          <Menu className="footer-menu" onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} />
      </Flex>
    </Footer>
  );
}

export default FooterComponent;
