import React, { useState, useRef, useEffect,useContext } from 'react';
import { Layout, Card, Typography, Breadcrumb, Button, Tabs, DatePicker, Table, Input, Row, Col, Flex, Radio, Divider, Avatar, Form ,Spin} from 'antd';
import { ArrowUpOutlined, RightOutlined, BarChartOutlined, ArrowLeftOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { UserContext } from '../../../contexts/UserContext.js';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Analytics from '../../Graph/Analytics';
import dataSet  from "../../Graph/data.js";
import DropdownSelector from "../../Graph/menu.js";
import { get } from '../../../utils/api.js';
import useScreenSize from '../../../utils/useScreenSize';
import { calculateAge,formatPrice,formatNumber } from '../../../utils/utils.js';
import AvatarImage from '../../AvatarImage.js';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { Title: AntTitle, Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;

const initialChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Total Fans',
      data: [0, 400, 400, 600, 800, 1000],
      fill: false,
      backgroundColor: '#6941C6',
      borderColor: '#6941C6',
    },
  ],
};

const columnsAllFan = [
  {
    title: 'Name & Number',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      const imageId = record && record?.info?.uploadedIds && record?.info?.uploadedIds.length > 0 ? record?.info?.uploadedIds[0] : null;
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
             <AvatarImage size={40} imageId={imageId} title={`${record?.info?.first_name} ${record?.info?.last_name}`}   />
          <div>
            <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}>{record?.info?.first_name} {record?.info?.last_name}</AntTitle>
            <div style={{ fontSize: '14px', color: '#101828' }}>+1{record?.info?.phone_number}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    responsive: ['md'],
    render: (text, record) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{record?.info?.gender}</div>
      );
    }
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    responsive: ['md'],
    render: (text, record) => {
      const value =calculateAge(record?.info?.dob);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
      );
    }
  },
  {
    title: 'Location',
    dataIndex: 'location',
    responsive: ['md'],
    key: 'location',
    render: (text, record) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{record?.info?.city}</div>
      );
    }
  },
  {
    title: 'Interactions',
    dataIndex: 'interactions',
    key: 'interactions',
    responsive: ['md'],
    render: (text, record) => {
      const totalAmount = record?.revenueInfo.reduce((acc, item)=>{
            if(item.amount){
              return  acc + parseFloat(item.amount);
            }else{
              return  acc + 0;
            }
      },0);
      console.log("totalAmount",totalAmount);
      const itemCount = record?.revenueInfo?.length || 0;
    
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{itemCount} Interactions {formatPrice(totalAmount || 0)} Spent</div>
      );
    }
  },
];

const columnsTopFan = [
  {
    title: 'Name & Number',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      const {revenueUser}=record;
      const imageId = revenueUser && revenueUser?.uploadedIds && revenueUser?.uploadedIds.length > 0 ? revenueUser?.uploadedIds[0] : null;
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
             <AvatarImage size={40} imageId={imageId} title={`${revenueUser?.first_name} ${revenueUser.last_name}`}   />
          <div>
            <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}>{revenueUser?.first_name} {revenueUser?.last_name}</AntTitle>
            <div style={{ fontSize: '14px', color: '#101828' }}>+1{revenueUser?.phone_number}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    responsive: ['md'],
    render: (text, record) => {
      const {revenueUser}=record;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.gender}</div>
      );
    }
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    responsive: ['md'],
    render: (text, record) => {
      const {revenueUser}=record;
      const value =calculateAge(revenueUser?.dob);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
      );
    }
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    responsive: ['md'],
    render: (text, record) => {
      const {revenueUser}=record;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.city}</div>
      );
    }
  },
  {
    title: 'Interactions',
    dataIndex: 'interactions',
    key: 'interactions',
    responsive: ['md'],
    render: (text, record) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>{record?.itemCount} Interactions {formatPrice(record?.totalAmount || 0)} Spent</div>
      );
    }
  },
];

const TotalFansDetails = () => {
  const [selectedRange, setSelectedRange] = useState('12 months');
  const [graphData, setGraphData] = useState(initialChartData);
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [allFansData, setAllFansData] = useState([]);
  const [topFansData, setTopFansData] = useState([]);
  const {user}  = useContext(UserContext);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [topPagination, setTopPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [loading, setLoading] = useState(false);
	const [totalUsers, setTotalUsers] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [growthString, setGrowthString] = useState(null);
  const [weeks, setweeks] = useState({});
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  const fetchUsers = async (params = {}) => {
		setLoading(true);
		try {
		  const response = await get('creator/allFans', {
			_page: params.pagination?.current || 1,
			_limit: params.pagination?.pageSize || 10,
			q: params?.searchTerm ||'',
		  });
		  setAllFansData(response.data);
		  setPagination({
			...params.pagination,
			total: response.pagination.total,
		  });
		} catch (error) {
		  console.error('Error fetching users:', error);
		} finally {
		  setLoading(false);
		}
	  };

  
    const fetchChartData=async(keyName="Last_12_months")=>{
      setLoading(true);
      try{
        const response = await get('creator/fansChart', {dataKey:keyName});
        if(response && response.status){
            setData(response?.data);
            setweeks(response?.weeks);
        }
      }catch (error) {
        console.error('Error fetching users:', error);
      }finally {
        setLoading(false);
      }
    }

    const compareWeeks=(data)=> {
      const { currentWeekFans, previousWeekFans } = data;
      let ctl=0;
      let changeStatus;
      // Calculate the percentage change
      const percentageChange = ((currentWeekFans - previousWeekFans) / previousWeekFans) * 100;
      
      if(percentageChange===Infinity){
        ctl=100;
      }else{
         ctl = isNaN(percentageChange) ? 0 : percentageChange;
        if (percentageChange >=0) {
            changeStatus = 'increased';
        } else if (percentageChange < 0) {
            changeStatus = 'decreased';
        } 
      }
     return {ctl,changeStatus};
  }

    useEffect(()=>{
      if(weeks){
        const p=compareWeeks(weeks);
        setGrowth(p?.ctl);
        setGrowthString(p?.changeStatus);
      }
   },[weeks]);

    const fetchTopUsers = async (params = {}) => {
      setLoading(true);
      try {
        const response = await get('creator/topFans', {
        _page: params.pagination?.current || 1,
        _limit: params.pagination?.pageSize || 10,
        q: params?.searchTerm ||'',
        });
        setTopFansData(response.data);
        setTopPagination({
        ...params.pagination,
        total: response.pagination.total,
        });
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
      };

	  const handleTableChange = (pagination) => {
		fetchUsers({ pagination });
	  };

    const handleTopTableChange = (pagination) => {
      fetchTopUsers({ pagination });
      };

	 useEffect(()=>{
		fetchUsers();
    fetchTopUsers();
    fetchChartData();
	 },[]);
   useEffect(()=>{
    const p=(pagination?.total>0)?pagination?.total:0;
    setTotalUsers(p);
   },[pagination]);

  const fetchCustomData =async (key) => {
    await fetchChartData(key);
  };

  const handleSearch = (value) => {
    fetchUsers({searchTerm:value});
  };
  const searchChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleTopSearch = (value) => {
    fetchTopUsers({searchTerm:value});
  };
  const searchTopChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchTopUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const handleDateChange = (date) => {
    // Update graph data based on the selected date
  };

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, []);


  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <Layout style={{ minHeight: '100vh', padding: isMobile ? '20px 15px' : 30 }}>
      <Spin spinning={loading}>

      {isMobile ?<div>
          <Button
            type="text"
            icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
            onClick={handleClose}
            style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
          >Back</Button>
        </div> 
        : <>

       {user?.role==="Team"?
        <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
          <Breadcrumb.Item href="/team/insights">
            <BarChartOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/team/insights">
            <span>Audience Insights</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Total Fans</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        :<Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/insights">
          <span>Audience Insights</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Total Fans</span>
        </Breadcrumb.Item>
      </Breadcrumb>}
    </>}
      
      <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Total Fans</AntTitle>
      <Text>View and see all your current members</Text>

      <Row className="button-date-group" gutter={16} style={{ margin: '20px 0 30px 0' }}>
        <Col>
          <DropdownSelector fetchCustomData={fetchCustomData} />
        </Col>
        {/* <Col style={{ marginLeft: 'auto' }}>
          <DatePicker placeholder="Select date" onChange={handleDateChange} />
        </Col> */}
      </Row>

      <Flex horizontal="true" flex={1} style={ isMobile && { flexDirection: 'column' }}>
        <Flex vertical style={{ minWidth: 250, marginBottom: isMobile ? 20 : '' }}>
          <Text style={{ marginTop: 0, marginBottom: 15 }}>Total Fans</Text>
          <AntTitle level={2} style={{ marginTop: 0, marginBottom: 10, fontSize: '36px' }}>{formatNumber(parseFloat(totalUsers) || 0)}</AntTitle>
          <Text>
          {growthString=="increased"?<span style={{ color: '#079455' }}><ArrowUpOutlined />{Math.abs(growth).toFixed(2)}%</span> :<span style={{ color: 'red' }}><ArrowDownOutlined/>{Math.abs(growth).toFixed(2)}%</span> }
           vs last week</Text>
        </Flex>
        <Flex vertical flex={1}>
          {/*<Line ref={chartRef} data={graphData} options={chartOptions} />*/}
          <div className="analytics-graph">
            <Analytics data={data} />
          </div>
        </Flex>
      </Flex>

      <Divider />

      <Tabs className="insight-tabs" defaultActiveKey="1" style={{ marginTop: '20px' }}>
        <TabPane tab="All Fans" key="1">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item 
                label="Search fan"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                 <Search
                    placeholder="Search by name"
                    allowClear
                    onSearch={handleSearch}
                    enterButton
                    onChange={searchChange}
                  />
              </Form.Item>
            </Col>
          </Row>
          <div className="insight-table">
            <Table 
              columns={columnsAllFan} 
              dataSource={allFansData} 
              pagination={pagination}
              rowKey={(record) => record.id}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </TabPane>
        <TabPane tab="Top Fans" key="2">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item 
                label="Search fan"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Search
                  placeholder="Search by name"
                  allowClear
                  onSearch={handleTopSearch}
                  enterButton
                  onChange={searchTopChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="insight-table">
            <Table 
              columns={columnsTopFan} 
              dataSource={topFansData} 
              pagination={topPagination} 
              rowKey={(record) => record.id}
              loading={loading}
              onChange={handleTopTableChange}
            />
          </div>
        </TabPane>
      </Tabs>
      </Spin>
    </Layout>
  );
};

export default TotalFansDetails;
