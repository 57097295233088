import React,{useEffect,useState} from 'react';

import { Link,useNavigate,useParams,Navigate } from 'react-router-dom';
import { Layout, Col, Row, Flex, Image, Button,Spin } from 'antd';

import PlaceholderImg from '../../../images/placeholder-img2.jpg';
import Payment from '../../../components/Audience/Payment';
import './FanSignup.css';
import { get } from '../../../utils/api';

const { Content } = Layout;

const FanSignup = () => {
    const [data,setData]=useState(null);
    const [creatorData,setCreatorData]=useState(null);
    const [unLockHandler,SetUnLockHandler]=useState(null);
    const [token,setToken]=useState(null);
    const { smsId,phone } = useParams();
    const Navigate = useNavigate();

    useEffect(()=>{
        const init=async()=>{
            const response = await get(`user/unlockRequest/${smsId}/${phone}`);
            if (response && response.status) {
                console.log("response?.token",response);
                setToken(response?.data?.token);
                setData(response?.data.postData);
                if(response && response?.data?.creatorData){
                    setCreatorData(response?.data?.creatorData);
                }
            }
        }
        init();
    
    },[smsId]);

    useEffect(()=>{
        if(data?.message && token){
            localStorage.setItem('token',token);
            Navigate(`/audience/fan-signup-unlock/${smsId}`);  
        }
    },[data,token]);
  
    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#000000', justifyContent: 'center' }}>
            {data ? 
            <Content style={{ padding: '16px 20px', flex: '0' }}>
                {!unLockHandler?<Flex vertical className="unclock-content" style={{ maxWidth: '416px', margin: '0 auto', borderRadius: 10 }}>
                    <Image
                        src={PlaceholderImg}
                        style={{ borderRadius: 8, maxWidth: '100%' }}
                        preview={false}
                    />
                    <Flex vertical className="button-group">
                        <Button onClick={() => SetUnLockHandler(true)} size="large" style={{ width: '100%', fontWeight: 600 }}>Unlock ${data.price}</Button>
                    </Flex>
                </Flex>:
                <Flex vertical style={{ maxWidth: '416px', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                    <Payment smsId={smsId} phone={phone} creatorData={creatorData} data={data} SetUnLockHandler={SetUnLockHandler} />
                </Flex>}
            </Content>: 
             <Spin spinning={true}>
                <Content style={{ height:"100Vh", flex: '0' }}>
                </Content>
            </Spin>}
        </Layout>
    );
}

export default FanSignup;