import React, { useState,useContext } from 'react';
import { Card, Button, Progress, Flex, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import useScreenSize from '../../../utils/useScreenSize';

const { Title, Text } = Typography;

const Age = ({data}) => {
    const [position, setPosition] = useState('end');
    const navigate = useNavigate();
    const {user}  = useContext(UserContext);
    const { isMobile } = useScreenSize();

    const navigateToReport = () => {
      const redirectLink=user?.role==="Team"?'/team/age-report':'/age-report';
      navigate(redirectLink);
    };
    
    return (
      <Card className="insight-card">
        <Flex className="top-content" vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
          <Title level={4} style={{ marginTop: 0, marginBottom: '30px', fontSize: '18px' }}>Age</Title>
          {data?.ageDistribution?.map((age, index) => (
            age?.count>0 ? (
              <div key={index} style={{ marginBottom: '15px', width: 'calc(100% - 18px)' }}>
                <Text>{age?.ageRange}</Text>
                <Progress className="progress-bar" percent={parseFloat(age.percentage)} />
              </div>
            ) : null
          ))}

        </Flex>
      <Flex vertical align="end" style={ isMobile ? { padding: '10px 20px', borderTop: '1px solid #EAECF0' } : { padding: '15px 30px', borderTop: '1px solid #EAECF0' }}>
          <Button type="text" icon={<RightOutlined />} iconPosition={position} style={{ color: '#6941C6', fontWeight: 600, padding: 0, background: 'transparent' }} onClick={navigateToReport}>View report</Button>
        </Flex>
      </Card>
    );
}

export default Age;
