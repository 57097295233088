import React, { useState, useEffect } from 'react';
import { Layout, Table, Input, Popconfirm ,Button} from 'antd';
import { get,del } from '../../utils/api';
import dayjs from 'dayjs';
import { getStatusText } from '../../utils/utils.js';
import { Link } from 'react-router-dom';

const { Search } = Input;

const UserList = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [loading, setLoading] = useState(false);
  
  const fetchUsers = async (params = {}) => {
    setLoading(true);
    try {
      const response = await get('admin/users', {
        _page: params.pagination?.current || 1,
        _limit: params.pagination?.pageSize || 10,
        q: params?.searchTerm ||'',
      });
      setData(response.data);
      setPagination({
        ...params.pagination,
        total: response.pagination.total,
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    fetchUsers({ pagination });
  };

  const handleSearch = (value) => {
    fetchUsers({searchTerm:value});
  };

  const handleDelete = async (user) => {
    const {id}=user;
    setLoading(true);
    try {
      await del(`admin/users/delete/${id}`);
      fetchUsers();
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Username',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const statusText = getStatusText(status);
        return <span>{statusText}</span>;
      },
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'x',
      render: (_, record) => (
        <>
          <Link to={`/admin/${record.id}/edit`}>Edit</Link>
          <span>|</span>
         <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
            <Button>Delete</Button>
        </Popconfirm>
        </>
       
      ),
    },
  ];

  return (
    <Layout style={{ padding: 30 }}>
      <div style={{ maxWidth: '340px', marginBottom: 30 }}>
        <Search
          placeholder="Search by name"
          allowClear
          onSearch={handleSearch}
          enterButton
        />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Layout>
  );
};

export default UserList;
