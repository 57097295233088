// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.criteria-item {
    margin-bottom: 5px;
}
.criteria-item .criteria-icon {
    margin-right: 7px;
}
.welcome-form .ant-upload-wrapper {
    margin-bottom: 30px !important;
    display: block;
    margin-top: 20px;
}
.welcome-form .ant-upload-wrapper .ant-upload-drag {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
}

.ant-form span.ant-upload-wrapper {
    display: block;
    margin-bottom: 30px;
    margin-top: 30px;
}
.avatar-container {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
}
.avatar-container span.ant-avatar {
    background: #98A2B3;
    font-weight: bold;
    font-size: 36px !important;
    border: 4px solid #ffffff;
    box-shadow: 0px 8px 7px rgb(0 0 0 / 11%);
}
.share-input span.ant-input-group-addon {
    padding: 0 4px;
    background: transparent;
}
.share-input button.ant-btn {
    padding-left: 9px;
    padding-right: 9px;
}`, "",{"version":3,"sources":["webpack://./src/components/Welcome/welcome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,8BAA8B;IAC9B,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,0BAA0B;IAC1B,yBAAyB;IACzB,wCAAwC;AAC5C;AACA;IACI,cAAc;IACd,uBAAuB;AAC3B;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".criteria-item {\n    margin-bottom: 5px;\n}\n.criteria-item .criteria-icon {\n    margin-right: 7px;\n}\n.welcome-form .ant-upload-wrapper {\n    margin-bottom: 30px !important;\n    display: block;\n    margin-top: 20px;\n}\n.welcome-form .ant-upload-wrapper .ant-upload-drag {\n    background: #ffffff;\n    border-radius: 10px;\n    border: 1px solid #d9d9d9;\n}\n\n.ant-form span.ant-upload-wrapper {\n    display: block;\n    margin-bottom: 30px;\n    margin-top: 30px;\n}\n.avatar-container {\n    text-align: center;\n    margin-bottom: 20px;\n    margin-top: 30px;\n}\n.avatar-container span.ant-avatar {\n    background: #98A2B3;\n    font-weight: bold;\n    font-size: 36px !important;\n    border: 4px solid #ffffff;\n    box-shadow: 0px 8px 7px rgb(0 0 0 / 11%);\n}\n.share-input span.ant-input-group-addon {\n    padding: 0 4px;\n    background: transparent;\n}\n.share-input button.ant-btn {\n    padding-left: 9px;\n    padding-right: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
