import React, { useState, useEffect, useMemo,useContext } from 'react';
import { Layout,  } from 'antd';
import {  MessageOutlined, CalendarOutlined, UsergroupAddOutlined, LockOutlined, UserAddOutlined, BarChartOutlined, BellOutlined, SettingOutlined, UserOutlined  } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import { useLocation } from 'react-router-dom';
import useScreenSize from '../utils/useScreenSize';
import { UserContext } from '../contexts/UserContext';
import { get } from '../utils/api';
const { Content } = Layout;


const TeamLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(null); // Set to null initially
  const { isDesktop } = useScreenSize();
  const location = useLocation();
  const currentPath = location.pathname;
  const {user}  = useContext(UserContext);
  const [teamList, setTeamList] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  
  const [selectedCreator, setSelectedCreator] = useState(null);
  useEffect(()=>{
    if(selectedCreator){
      const MenuAcess=[];
      const {permissions}=selectedCreator;
        if(permissions?.message){
          MenuAcess.push({
                key: '1',
                icon: <MessageOutlined />,
                label: 'Messages',
                path: '/team/message',
              });
        }

        if(permissions?.myNetwork){
          MenuAcess.push({ key: '3', icon: <UserAddOutlined />, label: 'My Network', path: '/team/my-network' },);
        }
        if(permissions?.scheduledMessages){
          MenuAcess.push({ key: '42', icon: <CalendarOutlined />, label: 'Scheduled Messages', path: '/team/schedule-message' });
        }
        if(permissions?.community){
          MenuAcess.push({ key: '4', icon: <UsergroupAddOutlined />, label: 'Community', path: '/team/communities' });
        }
        if(permissions?.myGallery){
          MenuAcess.push( { key: '5', icon: <LockOutlined />, label: 'My Gallery', path: '/team/content-vault' });
        }
        if(permissions?.insights){
          MenuAcess.push({ key: '7', icon: <BarChartOutlined />, label: 'Insights', path: '/team/insights' });
        }
        if(permissions?.notifications){
          MenuAcess.push({ key: '8', icon: <BellOutlined />, label: 'Notifications', path: '/team/notification' });
        }
        if(permissions?.settings){
          MenuAcess.push({ key: '9', icon: <SettingOutlined />, label: 'Settings', path: '/team/settings' });
        }
        setMenuItems(MenuAcess);
        if(selectedCreator?.teamToken){
          localStorage.setItem('teamToken',selectedCreator?.teamToken);
        }
       
    }

   
  },[selectedCreator]);

  useEffect(() => {
    const savedCollapsed = localStorage.getItem('sidebarCollapsed') === 'true'; // Fetch and parse boolean
    setCollapsed(savedCollapsed); // Set the state after fetching
  }, []);

  const loadTeamData=async()=>{
    try {
      const response = await get('teams/teamList');
      if(response && response.status){
        const collectedUsers = response.data;
        setTeamList(collectedUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(()=>{
     loadTeamData();
  },[user]);

  const toggleCollapsed = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem('sidebarCollapsed', newCollapsed);
  };

  const layoutStyles = useMemo(() => ({
    paddingLeft: isDesktop ? (collapsed ? '80px' : '312px') : '0',
  }), [isDesktop, collapsed]);

  if (collapsed === null) {
    return null; 
  }




  // const menuItems = [
  //   {
  //     key: '1',
  //     icon: <MessageOutlined />,
  //     label: 'Messages',
  //     path: '',
  //   },
  //   {
  //     key: '2',
  //     icon: <UserAddOutlined />,
  //     label: 'My Network',
  //     path: '/team/network',
  //   },
  //   {
  //     key: '3',
  //     icon: <BellOutlined />,
  //     label: 'Notifications',
  //     path: '/team/notification',
  //   },
  //   {
  //     key: '4',
  //     icon: <SettingOutlined />,
  //     label: 'Settings',
  //     path: '/team/settings',
  //   },
  // ];

  const menuItemsBottom = [
    {
      key: '5',
      icon: <UserOutlined />,
      label: 'My profile',
      path: '/team/profile',
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        menuItems={menuItems}
        currentPath={currentPath}
        menuItemsBottom={menuItemsBottom}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        toggleCollapsed={toggleCollapsed}
        teamList={teamList}
        setSelectedCreator={setSelectedCreator}
      />      
      <Layout className="site-layout" style={layoutStyles}>
        <Content>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default TeamLayout;
