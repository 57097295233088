import React, { useState } from 'react';
import { Layout, Image } from 'antd';
import { CreditCardOutlined, PictureOutlined, SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import SmirkingIcon from '../images/smirking-face.png';
import AudienceHeader from '../components/Sidebar/AudienceHeader';
import FooterComponent from '../components/Footer';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const AudienceNavLayout = ({ children }) => {

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  const menuItems = [
    {
      key: '1',
      icon: <CreditCardOutlined />,
      label: 'Billing',
      path: '/audience/billing',
    },
    // {
    //   key: '2',
    //   icon: <Image width={22} src={SmirkingIcon} preview={false} />,
    //   label: 'My Requests',
    //   path: '/audience/myrequests',
    // },
    {
      key: '3',
      icon: <PictureOutlined />,
      label: 'My Collections',
      path: '/audience/my-collection',
    },
    {
      key: '4',
      icon: <QuestionCircleOutlined />,
      label: 'FAQ',
      path: '/audience/faq',
    },
    {
      key: '5',
      icon: <SettingOutlined />,
      label: 'Settings',
      path: '/audience/settings',
    }
  ];

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AudienceHeader menuItems={menuItems} currentPath={currentPath} collapsed={collapsed} setCollapsed={setCollapsed} toggleCollapsed={toggleCollapsed} />
      <Layout className="site-layout">
        <Content>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
          <FooterComponent />
        </div>
      </Layout>
    </Layout>
  );
};

export default AudienceNavLayout;
