// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-table table {
    border: 1px solid #EAECF0;
    border-radius: 12px;
}
.network-table table th.ant-table-cell:first-child {
    border-start-start-radius: 12px !important;
    padding-left: 30px;
}
.network-table table th.ant-table-cell:last-child {
    border-start-end-radius: 12px !important;
    width: 100px;
}
.network-table table th.ant-table-cell {
    background: #FCFCFD;
    font-size: 12px;
    color: #475467;
}
.network-table table th.ant-table-cell:nth-child(2) {
    width: 200px;
}
.network-table table th.ant-table-cell:nth-child(3) {
    width: 180px;
}
.network-table table th.ant-table-cell:nth-child(4) {
    width: 130px;
}
.network-table table td.ant-table-cell:first-child {
    padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Team/Network/network.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,0CAA0C;IAC1C,kBAAkB;AACtB;AACA;IACI,wCAAwC;IACxC,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".network-table table {\n    border: 1px solid #EAECF0;\n    border-radius: 12px;\n}\n.network-table table th.ant-table-cell:first-child {\n    border-start-start-radius: 12px !important;\n    padding-left: 30px;\n}\n.network-table table th.ant-table-cell:last-child {\n    border-start-end-radius: 12px !important;\n    width: 100px;\n}\n.network-table table th.ant-table-cell {\n    background: #FCFCFD;\n    font-size: 12px;\n    color: #475467;\n}\n.network-table table th.ant-table-cell:nth-child(2) {\n    width: 200px;\n}\n.network-table table th.ant-table-cell:nth-child(3) {\n    width: 180px;\n}\n.network-table table th.ant-table-cell:nth-child(4) {\n    width: 130px;\n}\n.network-table table td.ant-table-cell:first-child {\n    padding-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
