import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static blotName = 'audio';
  static tagName = 'audio';

  static create(value) {
    let node = super.create();
    node.setAttribute('controls', '');
    node.setAttribute('src', value);
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}

Quill.register(AudioBlot);
